import React from "react";

import { Text, Image, AnimateIn, ButtonAlt } from "@atoms";
import { AppLink } from "@defaults";

const CardProject = ({ heading, subtitle, descriptor, image, links }) => {
  return (
    <AppLink
      to={links?.length < 2 ? links[0].url : ""}
      className="block bg-white p-3 text-left text-sm text-black shadow-lg lg:p-6"
    >
      <AnimateIn>
        <div className="w-full">
          {image && (
            <div className="-mx-3 -mt-3 mb-3 lg:-mx-6 lg:-mt-6">
              <div className="w-full">
                <Image {...image} aspectratio="4:3" />
              </div>
            </div>
          )}
          {heading && (
            <Text className="mb-3 font-sans leading-heading">{heading}</Text>
          )}
          {subtitle && (
            <Text variant="label" className="my-3 text-orange">
              {subtitle}
            </Text>
          )}
          {descriptor && (
            <Text variant="body--small" className="font-serif leading-heading">
              {descriptor}
            </Text>
          )}
          <div className="mt-3 flex flex-wrap gap-3">
            {links?.map((link, i) => (
              <ButtonAlt
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                size="sm"
                to={links?.length > 1 ? link.url : ""}
              >
                {link.text}
              </ButtonAlt>
            ))}
          </div>
        </div>
      </AnimateIn>
    </AppLink>
  );
};

CardProject.defaultProps = {};

export default CardProject;
