import React, { useState } from "react";

import { AppLink } from "@defaults";
import { SiteNav, PrimaryNav } from "@molecules";
import { Icon, Container, Button } from "@atoms";
import { useAppState } from "@state";
import { useHeaderAcData, useBrandData } from "@staticQueries";
import clsx from "clsx";

const SiteHeader = () => {
  const data = useHeaderAcData();
  const { logoDark, logoLight } = useBrandData();
  const { button } = data;
  const [navOpen, setNavOpen] = useState(false);
  const [{ layout }] = useAppState();
  const {
    theme,
    // hideNav
  } = layout;
  const hideNav = false;
  const colors = {
    dark: {
      text: "text-white",
    },
    light: {
      text: "text-black",
    },
  };
  return (
    <>
      <SiteNav data={data} open={navOpen} openState={[navOpen, setNavOpen]} />
      <header
        className={`w-full ${colors[theme].text} absolute left-0 right-0 top-0 z-40 text-sm tracking-button`}
      >
        <Container>
          <div className="-mx-3 flex h-24 items-center sm:-mx-6">
            <div className="mr-auto px-3 sm:px-6">
              <AppLink
                className="relative flex h-20 w-40 items-center py-2 font-medium"
                to={
                  process.env.GATSBY_PRIMARY_URL ||
                  "https://santacruztrails.org/"
                }
              >
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: logoDark }}
                  className={clsx(
                    "scmts-icon absolute inset-0 py-2 text-black",
                    {
                      "opacity-100": theme === "light",
                      "opacity-0": theme === "dark",
                    }
                  )}
                />
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: logoLight }}
                  className={clsx(
                    "scmts-icon absolute inset-0 py-2 text-white",
                    {
                      "opacity-0": theme === "light",
                      "opacity-100": theme === "dark",
                    }
                  )}
                />
              </AppLink>
            </div>

            <div className="ml-auto hidden flex-col md:flex">
              <PrimaryNav links={data.primaryNav} inverse={theme === "dark"} />
            </div>

            <div
              className={`flex items-center px-3 uppercase transition duration-200 sm:px-6 ${
                hideNav ? "pointer-events-none opacity-0" : "opacity-100"
              }`}
            >
              {button && (
                <div className="mx-4 hidden sm:block">
                  <Button to={button?.link}>{button?.text}</Button>
                </div>
              )}
              <div className="ml-4">
                <button
                  type="button"
                  className="inline-flex items-center font-sans font-medium uppercase"
                  onClick={() => {
                    setNavOpen(!navOpen);
                  }}
                >
                  <span className="hidden sm:block">Menu</span>{" "}
                  <Icon name="bars" className="ml-2 h-8 w-8" />
                </button>
              </div>
            </div>
            {/* <div className="px-3 sm:px-6 hidden">mobile nav button</div> */}
          </div>
        </Container>
      </header>
    </>
  );
};

SiteHeader.defaultProps = {};

export default SiteHeader;
