import React from "react";

import clsx from "clsx";
import { ButtonAlt, Text, Image, Icon } from "@atoms";

const CardTrail = ({ image, heading, closed, icon, info, descriptor, url }) => {
  return (
    <div className="bg-white-secondary text-black">
      {/* the guts */}
      <div className="overflow-hidden">
        <div className="-m-px">
          {image && <Image {...image} aspectratio="4:3" />}
        </div>
      </div>
      <div className="p-4">
        <div className="mb-2 flex items-start gap-1">
          <span
            className={clsx("-mt-px p-1 text-white", {
              "bg-red": closed,
              "bg-green": !closed,
            })}
          >
            <Text variant="label--small">{closed ? "closed" : "open"}</Text>
          </span>
          <Icon name={icon} className="h-3 w-3 flex-shrink-0" />
          <Text variant="label--small" className={clsx("mt-px pt-px", {})}>
            {info}
          </Text>
        </div>
        <Text variant="h7" className="mb-2 block">
          {heading}
        </Text>
        <Text variant="body--small" className="mb-2">
          {descriptor}
        </Text>
        <div>
          <ButtonAlt to={url} size="sm">
            Read More
          </ButtonAlt>
        </div>
      </div>
    </div>
  );
};

CardTrail.defaultProps = {};

export default CardTrail;
