import React from "react";
import { Container } from "@atoms";
import Cta from "@organisms/Cta";

const PbCta = props => {
  return (
    <Container className="relative z-30">
      <Cta {...props} />
    </Container>
  );
};

PbCta.defaultProps = {};

export default PbCta;
