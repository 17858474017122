// TODO: Break this up more

import React, { useState, useEffect, useRef } from "react";

import {
  useAllActionsData,
  useDefaultsData,
  useFooterData,
} from "@staticQueries";
import {
  Container,
  Image,
  AnimateIn,
  Text,
  AtForm,
  Icon,
  Button,
  Sticky,
} from "@atoms";
import { CardShare, HeadingWCopy, Share, Carousel } from "@molecules";
import { AppLink } from "@defaults";
import {
  // filterActions,
  shuffle,
} from "@utils";
import gsap from "gsap";
import clsx from "clsx";
import Cta from "./Cta";
import ContentBuilder from "./ContentBuilder";

const Action =
  // way too many params
  ({
    heading,
    copy,
    formid,
    // activistCodes,
    nextActions,
    // hasCodes,
    actionState,
    setActionState,
    shareUrlOverride,
    url,
    completedHeading,
    disableShare,
    thankYouHeading,
    completedCopy,
    thankYouCopy,
    actionType,
    shareCta,
    cta,
    raffle,
    date,
    levels,
    layout,
    dispatch,
    amounts,
    inverse,
  }) => {
    // refs
    const actionContainer = useRef();
    const actionStep = useRef();
    const thankYouStep = useRef();
    const thankYouImage = useRef();
    const form = useRef();

    const { prefooter } = useFooterData();

    const { action: actionDefaults } = useDefaultsData();
    const {
      upNextHeading: _upNextHeading,
      upNextDescriptor: _upNextDescriptor,
      thankYouHeading: thankYouHeadingDefault,
      thankYouCopy: thankYouCopyDefault,
      thankYouContentImage: _thankYouContentImage,
    } = actionDefaults;

    /*

    Misc Functions

    */

    /*

    Upcoming Actions

    */

    // upcoming actions

    const actions = shuffle(
      useAllActionsData().filter(action => action.includeInNextActions)
    );

    // actions to show after this action is completed unless there are levels
    // const upNext = filterActions(
    //   [
    //     ...nextActions,
    //     ...actions.filter(
    //       action =>
    //         !nextActions
    //           .map(nextAction => nextAction.slug)
    //           .includes(action.slug)
    //     ),
    //   ],
    //   ea.activistCodes || []
    // ).slice(0, 3);

    const upNext = [...nextActions, ...actions].slice(0, 6);

    /*

    Levels

    */

    // set the active level if there are levels
    const [activeLevel, setActiveLevel] = useState(levels?.length ? 1 : false);

    // set up the the visible amounts to match activeLevel
    const [visibleAmounts, setVisibleAmounts] = useState(
      levels?.length ? levels?.[1]?.monthlyAmounts : false
    );

    // function to get level from selected amount
    const selectLevelFromAmount = (amount, frequency) => {
      if (amount && frequency && levels) {
        let newLevel = 0;
        levels
          // get just the lowest number from each level
          .map(level => {
            return level[`${frequency}Amounts`][0];
          })
          // reverse to we can work down from largest numbers
          .sort((a, b) => a - b)
          // eslint-disable-next-line consistent-return
          .forEach((threshold, i) => {
            // return level if amount is above threshold
            if (parseFloat(amount) >= parseFloat(threshold)) {
              newLevel = i;
            }
          });
        return newLevel;
      }
      return 1;
    };

    useEffect(() => {
      setActionState({ ...actionState, activeLevel });
    }, [activeLevel]);

    // set initial level based on amount in url params
    useEffect(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const paramAmount = urlParams.get("amount");
      if (paramAmount) {
        const newActiveLevel = selectLevelFromAmount(
          paramAmount,
          actionState.frequency
        );
        setActiveLevel(newActiveLevel);
      }
    }, []);

    // when action state changes, update active level
    useEffect(() => {
      if (levels?.length) {
        const newActiveLevel = selectLevelFromAmount(
          actionState.amount,
          actionState.frequency
        );
        setActiveLevel(newActiveLevel);
      }
    }, [actionState.amount]);

    // change visible amounts when active level or frequency changes
    useEffect(() => {
      if (
        (actionState.frequency === "monthly" ||
          actionState.frequency === "annual") &&
        levels
      ) {
        setVisibleAmounts(
          levels[activeLevel][`${actionState.frequency}Amounts`]
        );
      }
    }, [activeLevel, actionState.frequency]);

    // show action submission status
    useEffect(() => {
      if (actionState.status === "submitted") {
        gsap.to(actionContainer.current, { x: "-50%" });
        gsap.to(thankYouImage.current, { opacity: 1 });
        gsap.to(actionStep.current, { height: "0" });
        gsap.to(thankYouStep.current, {
          height: "auto",
          onComplete: document
            .getElementById("___gatsby")
            .scrollIntoView({ behavior: "smooth" }),
        });
        dispatch({ type: "showNav" });
      } else if (actionState.status === "reset") {
        gsap.to(actionContainer.current, { x: 0 });
        gsap.to(thankYouImage.current, { opacity: 0 });
        gsap.to(actionStep.current, {
          height: "auto",
          onComplete: document
            .getElementById("___gatsby")
            .scrollIntoView({ behavior: "smooth" }),
        });
        gsap.to(thankYouStep.current, { height: "0" });
        dispatch({ type: "hideNav" });
      } else if (actionState.status === "completed") {
        gsap.set(actionContainer.current, { x: "-50%" });
        gsap.set(thankYouImage.current, { opacity: 1 });
        gsap.set(actionStep.current, { height: "0" });
        gsap.set(thankYouStep.current, { height: "auto" });
        dispatch({ type: "showNav" });
      } else {
        gsap.to(thankYouStep.current, { height: "0" });
        dispatch({ type: "hideNav" });
      }
    }, [actionState]);

    return (
      // container
      <div className="relative w-full">
        {/* container */}

        <div
          ref={actionContainer}
          className="relative z-10 flex"
          style={{
            width: "200%",
          }}
        >
          {/*
          
          Action 
          
          */}

          <div ref={actionStep} className="w-1/2">
            <Container>
              <div className="-mx-6 flex flex-wrap justify-start pb-12 lg:-mx-8">
                <div
                  className={`w-full px-4 md:w-1/2 lg:px-6 xl:w-3/5
                ${layout === "left" ? "md:order-2" : "md:order-1"} 
                `}
                >
                  <AnimateIn>
                    <div className="sm:-mx-4 md:-mx-6">
                      <div>
                        <Text variant="h3">{heading}</Text>
                        {date && (
                          <div className="my-4">
                            <Text variant="h7">{date}</Text>
                          </div>
                        )}
                        <div className="my-4">
                          <Text variant="body" className="max-w-2xl">
                            {copy}
                          </Text>
                        </div>
                        {!disableShare && (
                          <div className="my-4">
                            <Share url={url} small />
                          </div>
                        )}
                        <div className="mt-6">
                          <ContentBuilder blocks={raffle.contentBuilder} />
                        </div>
                      </div>

                      <div className="sticky bottom-0 left-0 right-0 mb-3 mt-4 flex flex-col items-center justify-center py-3 md:hidden">
                        <Button
                          size="sm"
                          onClick={() => {
                            if (form.current) {
                              form.current.scrollIntoView({
                                behavior: "smooth",
                              });
                            }
                          }}
                        >
                          {cta || "Take Action"}
                        </Button>
                        <Icon
                          name="arrow"
                          className="mt-3 h-4 w-4 rotate-90 transform text-orange"
                        />
                      </div>
                      {/* )} */}
                    </div>
                  </AnimateIn>
                </div>
                <div
                  ref={form}
                  className={`mx-auto w-full max-w-md md:mx-0 md:w-1/2 md:max-w-none xl:w-2/5
                ${layout === "left" ? "md:order-1" : "md:order-2"} 
                `}
                >
                  <Sticky
                    className={`w-full text-black sm:px-8 ${
                      actionState.status === "submitted"
                        ? "opacity-0"
                        : "opacity-100"
                    }`}
                  >
                    <AtForm
                      formId={formid}
                      // activistCodes={activistCodes}
                      actionState={[actionState, setActionState]}
                      actionType={actionType}
                      amounts={amounts}
                      visibleAmounts={visibleAmounts}
                      hasLevels={levels?.length}
                    />
                  </Sticky>
                </div>
              </div>
              {/* <div className="mb-4 flex items-center justify-center w-full">
                {!issue && (
                  <ButtonAlt to={process.env.GATSBY_PRIMARY_URL || "https://santacruztrails.org/"} reverse>
                    Go to Campaigns
                  </ButtonAlt>
                )}
                {issue && issue.url && (
                  <ButtonAlt to={issue.url} reverse>
                    Go to {issue.title} Campaign
                  </ButtonAlt>
                )}
              </div> */}
            </Container>
            {/* <div className="h-12" /> */}
          </div>

          {/*
          
          Thank You State
          
          */}

          <div
            ref={thankYouStep}
            className={clsx("w-1/2 overflow-hidden", {
              "text-white": inverse,
              "text-black": !inverse,
            })}
          >
            <Container>
              <CardShare
                heading={
                  (actionState.status === "completed"
                    ? completedHeading
                    : thankYouHeading) || thankYouHeadingDefault
                }
                copy={
                  (actionState.status === "completed"
                    ? completedCopy
                    : thankYouCopy) || thankYouCopyDefault
                }
                shareCta={shareCta}
                shareUrlOverride={shareUrlOverride}
                formid={formid}
                trackingId={actionState.trackingId}
                play={
                  actionState.status === "submitted" ||
                  actionState.status === "completed"
                }
                image={_thankYouContentImage}
                url={url}
                disableShare={disableShare}
              />
            </Container>

            {/* {actionState.status === "completed" && (
                <div>
                  <div className="mt-12 w-full flex items-center text-center justify-center">
                    <Button
                      className="mx-auto"
                      onClick={() =>
                        setActionState(s => {
                          return { ...s, status: "reset" };
                        })
                      }
                    >
                      Complete this action again
                    </Button>
                  </div>
                </div>
              )} */}
            <div className="mt-12 w-full">
              <Container>
                <div className="mx-auto max-w-md">
                  <HeadingWCopy
                    heading={_upNextHeading}
                    copy={_upNextDescriptor}
                    small
                    centered
                    green
                  />
                </div>
                <div className="-mx-3 mt-12">
                  {/* <Container> */}
                  <Carousel showMultiple>
                    {upNext &&
                      upNext.map((item, i) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <div key={i} className="h-full px-3">
                            <AppLink
                              to={item.url}
                              className=" group relative z-0 -m-px flex h-full flex-col items-center bg-white text-center text-black"
                            >
                              <div className="w-full">
                                <Image
                                  {...(item.image || item.metaImage)}
                                  aspectratio="8:5"
                                />
                              </div>
                              <div className="w-full flex-grow border-2 border-gray-light p-3 transition duration-200 group-hover:border-orange">
                                <div className="my-2">
                                  <Text variant="h7" className="text-green">
                                    {item.heading || item.title}
                                  </Text>
                                </div>
                                <div>
                                  <Text variant="body--small">
                                    {(item.descriptor || item.metaDescription)
                                      .length > 90
                                      ? `${(
                                          item.descriptor ||
                                          item.metaDescription
                                        ).substring(0, 90)}...`
                                      : item.descriptor || item.metaDescription}
                                  </Text>
                                </div>
                                {/* <div className="mt-2">
                                    <ButtonAlt size="sm" color="green">
                                      Read More
                                    </ButtonAlt>
                                  </div> */}
                              </div>
                            </AppLink>
                          </div>
                        );
                      })}
                  </Carousel>
                  {/* </Container> */}
                </div>
              </Container>
              {prefooter.action && (
                <div className="relative z-20 my-12 lg:mb-16">
                  <AnimateIn preset="fadeInFast">
                    <Container>
                      <div className="shadow-xl">
                        <Cta {...prefooter} />
                      </div>
                    </Container>
                  </AnimateIn>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

Action.defaultProps = {};

export default Action;
