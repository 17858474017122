import React, { useState } from "react";
import { HeadingWCopy, CardProject } from "@molecules";
import { Container, Button } from "@atoms";

const PastProjects = ({ heading, descriptor, projects }) => {
  const [visible, setVisible] = useState(3);
  return (
    <>
      <Container variant="xs">
        <HeadingWCopy
          heading={heading}
          copy={descriptor}
          inverse
          small
          centered
        />
      </Container>
      <Container>
        <div className="my-6 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
          {projects.slice(0, visible).map((p, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <CardProject key={i} {...p} />
          ))}
        </div>
      </Container>
      {visible < projects?.length && (
        <div className="mb-6 flex items-center justify-center">
          <Button
            onClick={() => {
              setVisible(visible + 3);
            }}
          >
            Load More
          </Button>
        </div>
      )}
    </>
  );
};

PastProjects.defaultProps = {};

export default PastProjects;
