import React from "react";

import { LevelCardSmall, LevelCardLarge, HeadingWCopy } from "@molecules";
import { Container, BrushBorder, Button } from "@atoms";

const MembershipLevels = ({ cards, otherHeading, otherCopy, otherButton }) => {
  return (
    <div className="bg-black pb-16 pt-px">
      <BrushBorder color="black" position="top" />
      <div className="relative z-10">
        <Container>
          <div className="-mx-2 my-8 -mt-12 flex flex-wrap sm:-ms-4 md:-mx-6">
            {cards?.map((card, i) => {
              if (card.type === "swatch") {
                return (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    className="my-8 w-full px-2 sm:px-4 md:mb-12 md:px-6"
                  >
                    <LevelCardLarge {...card} />
                  </div>
                );
              }
              return (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  className="my-4 w-full px-2 sm:w-1/3 sm:px-4 md:mb-12 md:px-6"
                >
                  <LevelCardSmall {...card} />
                </div>
              );
            })}
          </div>
        </Container>
        <Container>
          <div className="mx-auto max-w-xl">
            <HeadingWCopy
              heading={otherHeading}
              copy={otherCopy}
              centered
              inverse
              green
              small
            />
            {otherButton && (
              <div className="mt-6 flex items-center justify-center">
                <Button to={otherButton.link}>{otherButton.text}</Button>
              </div>
            )}
          </div>
        </Container>
      </div>
      <BrushBorder color="black" position="bottom" />
    </div>
  );
};

MembershipLevels.defaultProps = {};

export default MembershipLevels;
