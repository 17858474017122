import React from "react";

import { Text, CbWrapper } from "@atoms";

const CbBlockQuote = ({ quote, citation, indent, type }) => {
  return (
    <CbWrapper type={type} indent={indent}>
      <div className="max-w-lg lg:pb-2 lg:pt-4">
        <Text variant="blockquote" className="mb-3 text-green" quotes>
          {quote}
        </Text>
        {citation && (
          <Text
            variant="label"
            className="citation mt-4 text-right uppercase text-green"
          >
            {citation}
          </Text>
        )}
      </div>
    </CbWrapper>
  );
};

CbBlockQuote.defaultProps = {};

export default CbBlockQuote;
