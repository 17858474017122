import resolveImage from "./resolveImage";

const resolveProject = project => {
  const {
    slug,
    title,
    url,
    metaTitle,
    metaDescription,
    metaImage,
    copy0,
    heading0,
    backgroundImage0,
  } = { ...project };
  return {
    url,
    slug,
    meta: {
      title,
      metaDescription,
      metaImage: resolveImage(metaImage),
      metaTitle,
      slug,
      url,
    },
    hero: {
      heading: title,
      subtitle: heading0,
      copy: copy0,
      backgroundImage: resolveImage(backgroundImage0),
    },
  };
};

export default resolveProject;
