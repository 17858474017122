import React from "react";

import { AppLink } from "@defaults";
import { Text, Image } from "@atoms";

const BlogTease = ({ uri, image, url, categories, title, date }) => {
  return (
    <AppLink to={uri || url} className="-mx-2 flex flex-wrap sm:-mx-3">
      <div className="mb-4 w-full px-2 sm:w-1/3 sm:px-3">
        <div
          className="relative"
          style={!image ? { paddingBottom: "66%" } : null}
        >
          {image && image.url && <Image {...image} aspectratio="4:3" />}
        </div>
      </div>
      <div className="w-full px-2 sm:w-2/3 sm:px-3">
        <Text
          variant="body--small-tight"
          className="flex flex-wrap font-medium uppercase text-red"
        >
          {categories &&
            // categories.length &&
            categories.map(c => c.title).join(", ")}
          {(!categories || !categories.length) && "Article"}
        </Text>
        <Text variant="body--2xl-tight" className="my-2 font-medium">
          {title}
        </Text>
        <Text className="text-xs text-gray">{`Date: ${date}`}</Text>
      </div>
    </AppLink>
  );
};

BlogTease.defaultProps = {};

export default BlogTease;
