import React from "react";
import { Image, Text, ButtonAlt } from "@atoms";
import { AppLink } from "@defaults";

const FeaturedArticle = ({
  heading,
  subheading,
  copy,
  metaDescription,
  cta,
  url,
  image,
  small,
}) => {
  return (
    <AppLink to={url} className="group relative block text-left">
      <div className="shadow-xl">
        <Image {...image} aspectratio={small ? "4:3" : "16:9"} />
      </div>
      <div className="flex flex-col p-3">
        <div>
          <h3 className="font-sans text-2xl">{heading}</h3>
        </div>
        {subheading && !small && (
          <div className="my-4">
            <Text variant="label--large">{subheading}</Text>
          </div>
        )}
        <div className="my-2">
          <Text variant="body--small">{metaDescription || copy}</Text>
        </div>
        <div className="mt-2">
          <ButtonAlt size="sm" color="orange">
            {cta}
          </ButtonAlt>
        </div>
      </div>
    </AppLink>
  );
};

FeaturedArticle.defaultProps = {
  tag: "Featured Action",
};

export default FeaturedArticle;
