import React from "react";

import { Button, Icon } from "@atoms";
import { useFooterData } from "@staticQueries";

const SocialLinks = ({ large }) => {
  const { social } = useFooterData();

  const className = large ? "h-4 w-4 -mr-px" : "h-3 w-3 -mr-px";
  const size = large ? "sm-social" : "xs-social";
  const padding = large ? "-3" : "-2";
  return (
    <div className={`flex -mx${padding}`}>
      {social.map(s => {
        return (
          <div key={s.uid} className={`px${padding}`}>
            <Button to={s.url} size={size}>
              <Icon name={s.platform} className={className} fitHeight />
            </Button>
          </div>
        );
      })}
    </div>
  );
};

SocialLinks.defaultProps = {
  large: false,
};

export default SocialLinks;
