import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview } from "@dataResolvers";

const query = graphql`
  query HeaderNavQuery {
    craft {
      globalSet(handle: "header") {
        ... on Craft_header_GlobalSet {
          name
          navHeading
          navCopy
          headerButton {
            url
          }
          headerButtonLabel
          linkList {
            ... on Craft_linkList_action_BlockType {
              uid
              enabled
              action {
                url
              }
              linkText
            }
            ... on Craft_linkList_listLink_BlockType {
              uid
              enabled
              linkText
              listLink {
                url
              }
            }
          }
          secondaryNav {
            ... on Craft_secondaryNav_navLink_BlockType {
              uid
              enabled
              navLink {
                url
              }
              linkText
            }
          }
        }
      }
    }
  }
`;

const previewQuery = `
query HeaderNavQuery {
  globalSet(handle: "header", site: $site) {
    ... on header_GlobalSet {
      name
      navHeading
      navCopy
      navCampaigns {
        ... on navCampaigns_campaign_BlockType {
          enabled
          campaign {
            ... on issue_issue_Entry {
              enabled
              heading0
              slug
            }
          }
        }
      }
      secondaryNav {
        ... on secondaryNav_navLink_BlockType {
          enabled
          navLink
          linkText 
        }
      }
    }
  }
}
`;

const dataResolver = ({ craft }) => {
  const {
    navHeading,
    navCopy,
    // navCampaigns,
    secondaryNav,
    headerButton,
    headerButtonLabel,
    linkList,
  } = craft.globalSet;
  return {
    heading: navHeading,
    copy: navCopy,
    button: {
      link: headerButton.url,
      text: headerButtonLabel,
    },
    primaryNav: linkList.map(item => {
      return {
        uid: item.uid,
        link:
          item.action && item.action.length
            ? item.action[0].url
            : item.listLink?.url,
        text: item.linkText,
      };
    }),
    // campaigns: navCampaigns
    //   .filter(c => c.campaign[0])
    //   .map(c => {
    //     return {
    //       uid: c.uid,
    //       path: `/${c.campaign[0].slug}`,
    //       heading: c.campaign[0].heading0,
    //     };
    //   }),
    secondaryNav: secondaryNav.map(link => {
      return {
        uid: link.uid,
        link: link.navLink?.url,
        text: link.linkText,
      };
    }),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
