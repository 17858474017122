import React from "react";
import "@vidstack/react/player/styles/base.css";
import { MediaPlayer, MediaProvider } from "@vidstack/react";

const Video = ({
  url: _url,
  // muted, autoplay, placeholder, play
}) => {
  let url;
  const provider = _url.match("http(s)?://(www.)?youtube|youtu.be")
    ? "youtube"
    : "vimeo";
  if (provider === "vimeo " && !url.match(/vimeo\.com\/(\d+)/)) {
    return null;
  }

  if (provider === "youtube") {
    if (_url.match("embed")) {
      [url] = _url.split(/embed\//)[1].split('"');
    } else {
      [url] = _url.split(/v\/|v=|youtu\.be\//)[1].split(/[?&]/);
    }
  }

  if (provider === "vimeo") {
    url = _url.split("/").pop();
  }

  const id = url;

  // set up the player
  return (
    <MediaPlayer key={`${provider}/${id}`} src={`${provider}/${id}`}>
      <MediaProvider />
    </MediaPlayer>
  );
};

Video.defaultProps = {
  play: true,
};

export default Video;
