import React from "react";
import { BrushBorder, Container, Button } from "@atoms";
import { useLatestBlogsData } from "@staticQueries";
import { CardProject } from "@molecules";
import HeadingWCopy from "./HeadingWCopy";

const PbFeaturedArticles = ({ heading, descriptor, button }) => {
  const blogs = useLatestBlogsData();
  const cards = blogs?.map(p => {
    return {
      uid: p.uid,
      image: p.meta.image || p.hero.backgroundImage,
      heading: p.title,
      subtitle: p.date,
      descriptor: p.meta.description,
      url: p.url,
      links: [{ text: "Read More", url: p.url }],
    };
  });
  return (
    <div className="relative z-10 bg-black py-12 md:py-20">
      <BrushBorder position="top" color="black" />
      <Container variant="xs">
        <HeadingWCopy
          heading={heading}
          copy={descriptor}
          small
          inverse
          green
          centered
        />
      </Container>
      <Container>
        <ul className="my-10 grid grid-cols-1 gap-6 sm:grid-cols-3">
          {cards?.map((card, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i}>
              <CardProject small {...card} />
            </li>
          ))}
        </ul>
        {button?.url && (
          <div className="mt-6 flex items-center justify-center">
            <Button to={button.url}>{button.text}</Button>
          </div>
        )}
      </Container>
      <BrushBorder position="bottom" color="black" />
    </div>
  );
};

PbFeaturedArticles.defaultProps = {};

export default PbFeaturedArticles;
