import React from "react";

import { Container, Text, Button, BrushBorder } from "@atoms";
import { Testimonial, Carousel } from "@molecules";

const CarouselTestimonial = ({ heading, testimonials, link, inverse }) => {
  return (
    <div className="textured relative -my-6 bg-green">
      <BrushBorder color="green" position="top" />
      <Container>
        <div className="mb-12">
          <Text variant="h3" className="text-center">
            {heading}
          </Text>
        </div>
        <Carousel
          maxVisible={1}
          centerItems
          buttonPosition="33%"
          className="-mx-3 px-3 md:mx-0 md:px-0"
          showIndicators
        >
          {testimonials.map((t, i) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i} className="relative w-full px-3">
                <Testimonial {...t} inverse={inverse} />
              </div>
            );
          })}
        </Carousel>
        {link && link.link && (
          <div className="mt-12 flex items-center justify-center">
            <Button size="sm" to={link.link}>
              {link.text}
            </Button>
          </div>
        )}
      </Container>
      <BrushBorder color="green" position="bottom" />
    </div>
  );
};

CarouselTestimonial.defaultProps = {
  inverse: false,
};

export default CarouselTestimonial;
