import resolveImage from "./resolveImage";
// these resolutions need to be reflexive and recursive
// ! IMPORTANT: if something's broken here, look here
// eslint-disable-next-line import/no-cycle
import resolveAction from "./resolveAction";

const ResolveIssue = _issue => {
  const issue = { ..._issue };
  if (issue) {
    const {
      title,
      slug,
      url,
      metaTitle,
      metaDescription,
      metaImage,
      heading0,
      copy0,
      backgroundImage0,
      featuredAction,
      heading1,
      cardCarousel,
      heading2,
      heading3,
      copy2,
      heading4,
      relatedIssues,
      socialFeed,
      showSocialFeed,
      showImpactGrid,
      impactGrid,
      actions,
    } = issue;

    return {
      url,
      meta: {
        title,
        metaDescription,
        metaImage: resolveImage(metaImage),
        metaTitle,
        slug,
        url,
      },
      slug,
      title,
      // page resolvers
      hero: {
        heading: heading0,
        copy: copy0,
        backgroundImage: resolveImage(backgroundImage0),
        featuredAction: resolveAction(featuredAction?.[0]?.action?.[0]),
      },
      actions: actions?.map(a => resolveAction(a)),
      carousel: {
        heading: heading1,
        cards: cardCarousel?.map(card => {
          if (card.__typename === "Craft_cardCarousel_action_BlockType") {
            const action = resolveAction(card?.action?.[0]);
            return {
              heading: card.linkText || action.title,
              copy: action.metaDescription,
              url: `/action/${action.slug}`,
              image: action.metaImage,
              activistCodesExclusive: action.activistCodesExclusive,
            };
          }
          return {
            heading: card.linkText,
            copy: card.description,
            url: card.listLInk?.url,
            image: resolveImage(card.image),
          };
        }),
      },
      social: {
        enabled: showSocialFeed,
        heading: heading3,
        embedCodes: socialFeed
          ?.filter(code => code.enabled)
          .map(code => {
            return code.code;
          }),
      },
      impact: {
        enabled: showImpactGrid,
        heading: heading2,
        copy: copy2 || null,
        impact: impactGrid
          ?.filter(card => card.enabled)
          .map(card => {
            return {
              ...card,
              image: resolveImage(card.image),
              logo: resolveImage(card.logo),
            };
          }),
      },
      relatedContent: {
        enabled: showImpactGrid,
        heading: heading4 || "Dig Into Related Issues",
        cards: relatedIssues
          ?.filter(card => card.enabled)
          .map(card => {
            switch (card.__typename) {
              case "Craft_relatedIssues_action_BlockType":
                // eslint-disable-next-line no-case-declarations
                const action = resolveAction(card?.action?.[0]);
                return {
                  image: action.metaImage,
                  url: `/action/${action.slug}`,
                  cta: action.linkText || "Take Action",
                  heading: action.heading,
                  label: "action",
                };
              case "entry":
                return card;
              default:
                return {
                  image: resolveImage(card.image),
                  program: card.organization,
                  url: card.linkLink?.url,
                  cta: card.cta,
                  heading: card.heading,
                  label: card.contentType,
                };
            }
          }),
      },
      // other resolvers
      heading: heading0,
      metaImage: resolveImage(metaImage),
    };
  }
  return issue;
};

export default ResolveIssue;
