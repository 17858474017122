import React from "react";

import { CardListSimple } from "@molecules";
import { Text } from "@atoms";

const ContentWCardList = ({ heading, copy, cards }) => {
  return (
    <div className="-mx-6 my-12 flex flex-wrap">
      <div className="mb-6 w-full px-6">
        <Text variant="h3">{heading}</Text>
      </div>
      <div className="mb-6 w-full px-6 md:w-1/2 lg:w-3/5">
        <div className="relative z-10">
          {copy && (
            <Text variant="body--large" className="mt-3">
              {copy}
            </Text>
          )}
        </div>
      </div>
      <div className="mb-6 w-full px-6 pt-4 md:w-1/2 lg:w-2/5">
        <CardListSimple cards={cards} />
      </div>
    </div>
  );
};

ContentWCardList.defaultProps = {};

export default ContentWCardList;
