const setVisible = amounts => {
  if (amounts) {
    Array.from(document.querySelectorAll(".SelectAmount .label-amount"))
      .filter(el => !el.classList.contains("label-otheramount"))
      .forEach(el => {
        if (
          amounts
            .map(a => a.toFixed(2))
            .includes(el.querySelector("input").getAttribute("value"))
        ) {
          el.classList.remove("!hidden");
        } else {
          el.classList.add("!hidden");
        }
      });
    // const amountEls = Array.from(
    //   document.querySelectorAll(".SelectAmount .label-amount")
    // );
    // if (
    //   amounts &&
    //   (allAmounts.includes(currentAmount) ||
    //     allAmounts.includes(parseInt(currentAmount, 10))) &&
    //   !amounts.includes(currentAmount) &&
    //   amountEls.length
    // ) {
    //   try {
    //     amountEls
    //       .find(el => {
    //         return (
    //           String(el.getAttribute("data-amount")) === String(amounts[0])
    //         );
    //       })
    //       .click();
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }
  }
};

export default setVisible;
