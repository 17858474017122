import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview, resolveArticle } from "@dataResolvers";

const query = graphql`
  query LatestBlogsQuery {
    craft {
      entries(section: "articles", site: "scmts", limit: 3) {
        ... on Craft_articles_article_Entry {
          title
          postDate
          title
          slug
          url
          metaTitle
          metaDescription
          blogCategory {
            ... on Craft_blogCategories_Category {
              title
              uid
              color0
            }
          }
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          backgroundImage0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

// TODO: Preview query
const previewQuery = `
query LatestBlogsQuery {
  craft {
    ... on Craft_articles_article_Entry {
        title
        postDate
        title
        slug
        url
        metaTitle
        metaDescription
        blogCategory {
          ... on Craft_blogCategories_Category {
            title
            uid
            color0
          }
        }
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        backgroundImage0 {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
      }
    }
  }
}`;

const dataResolver = ({ craft }) => {
  const { entries } = craft;
  return entries.map(entry => resolveArticle(entry));
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
