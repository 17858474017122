import React, { useState, useEffect } from "react";

import { BrushBorder, Container, Image, Text } from "@atoms";
import { Carousel } from "@molecules";
import { AppLink } from "@defaults";

const CarouselNav = ({ items }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {}, [currentSlide]);
  return (
    <div className="textured relative -my-6 bg-green py-12 text-center text-black">
      <BrushBorder color="green" position="top" />
      <Container>
        <div className="hidden px-6 lg:block">
          <Carousel
            showIndicators
            adjustIndicators
            currentSlideState={[currentSlide, setCurrentSlide]}
            maxVisible={1}
          >
            {items &&
              items.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i}>
                  <Image {...item.image} aspectratio="8:5" />
                </div>
              ))}
          </Carousel>
          <div className="px-6">
            <div className="relative z-10 -mt-6 hidden flex-wrap shadow-xl lg:flex">
              {items &&
                items.map((item, i) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    onMouseEnter={() => {
                      setCurrentSlide(i);
                    }}
                    style={{ width: `${(1 / items.length) * 100}%` }}
                  >
                    <AppLink
                      to={item.url}
                      className={` ${
                        currentSlide === i
                          ? "z-10 border-orange"
                          : "border-gray-light "
                      } relative z-0 -m-px flex h-full flex-col items-center border-2 bg-white p-3 text-center`}
                    >
                      <div className="relative flex h-20 items-center justify-center">
                        <div
                          className="scmts-icon--fit-height h-12 fill-black"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{ __html: item.icon }}
                        />
                      </div>
                      <div className="my-2">
                        <Text variant="h7" className="text-green">
                          {item.heading}
                        </Text>
                      </div>
                      <div>
                        <Text variant="body--small">{item.descriptor}</Text>
                      </div>
                    </AppLink>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div>
          <div className="relative z-10 -mx-2 -mb-8 -mt-6 flex flex-wrap justify-center px-6 lg:hidden">
            {items &&
              items.map((item, i) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  className="mb-4 w-full px-2 md:w-1/2"
                >
                  <AppLink
                    key={item.uid}
                    to={item.url}
                    className="group relative -mx-px flex h-full items-center border-2 border-gray-light bg-white p-3 text-left transition duration-200 hover:z-10 hover:border-orange"
                  >
                    <div className="flex-shrink-0 p-3 md:p-4">
                      <div className="relative flex h-20 items-center justify-center">
                        <div
                          className="scmts-icon--fit-height h-12 fill-black"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{ __html: item.icon }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="my-2">
                        <Text variant="h7" className="text-green">
                          {item.heading}
                        </Text>
                      </div>
                      <div>
                        <Text variant="body--small">{item.descriptor}</Text>
                      </div>
                    </div>
                  </AppLink>
                </div>
              ))}
          </div>
        </div>
      </Container>
      <BrushBorder color="green" position="bottom" />
    </div>
  );
};

CarouselNav.defaultProps = {};

export default CarouselNav;
