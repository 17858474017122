import { ea } from "@utils";

const { getRacCookie, setRacCookie, getRacParam, getAsCookie, setAsCookie } =
  ea;

const reducer = (state, action) => {
  let codes;
  let newState;
  let formid;
  let data;
  switch (action.type) {
    case "addActivistCodes":
      codes = action.codes.filter(
        c => c !== "" && !state.activistCodes.includes(c)
      );
      newState = {
        ...state,
        // merge codes and remove duplicates
        activistCodes: [
          ...new Set(
            state.activistCodes
              ? [...state.activistCodes, ...codes]
              : [...codes]
          ),
        ],
      };
      setRacCookie({ activistCodes: newState.activistCodes });
      return newState;
    case "addShareData":
      formid = action.formid;
      data = action.data;
      newState = {
        ...state,
        shareData: state.shareData
          ? { ...state.shareData, [formid]: data }
          : { [formid]: data },
      };
      setAsCookie({ shareData: newState.shareData });
      return newState;
    default:
      return state;
  }
};

/* set activist codes */
// get cookie
const racCookie = getRacCookie() || { activistCodes: [] };
// get params
const urlParams = getRacParam();
// spread cookie and params and remove duplicates
const activistCodes = [...new Set([...racCookie.activistCodes, ...urlParams])];
// push url params to cookie
setRacCookie({
  activistCodes,
});

/* set share codes */
// just get the cookie (this will not be populated from params for now)
const shareData = getAsCookie() || {};

const initialState = {
  activistCodes,
  shareData,
};

export default {
  reducer,
  initialState,
};
