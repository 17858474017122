import React from "react";

import { Icon, Text, Square } from "@atoms";

const ButtonIcon = ({ cta, icon }) => {
  return (
    <Square className="hover:text-cream group bg-white text-center text-red shadow-xl transition duration-200 hover:bg-red">
      <div className="flex h-full flex-col items-center justify-center p-6 lg:p-12">
        <Text variant="h2" className="hidden md:block">
          {cta}
        </Text>
        <Text variant="h3" className="md:hidden">
          {cta}
        </Text>
        <div className="text-gray group-hover:text-white">
          <Icon name={icon} fitHeight className="mt-6 h-10 w-auto lg:h-12" />
        </div>
      </div>
    </Square>
  );
};

ButtonIcon.defaultProps = {};

export default ButtonIcon;
