import React from "react";

import { Image, Container, Button, ButtonAlt, Text } from "@atoms";
import { HeadingWCopy } from "@molecules";

const HeroHome = ({
  backgroundImage,
  heading,
  copy,
  button0,
  button1,
  button2,
  quote,
  citation,
}) => {
  return (
    <div className="relative z-0 flex flex-col bg-white pb-12 pt-40">
      <div className="absolute inset-0 z-0 bg-black-true">
        <div className="fade-to-black absolute left-0 right-0 top-0 z-10 h-48 rotate-180 transform opacity-25" />
        <div className="fade-to-black-horz absolute inset-0 bottom-0 z-10 w-4/5 rotate-180 transform opacity-50" />
        <div className="absolute inset-0 z-0 opacity-50">
          {backgroundImage && (
            <Image
              {...backgroundImage}
              params={{ exp: "-5", bri: "-2", con: "3", fit: "max" }}
              fill
            />
          )}
        </div>
      </div>
      <div className="relative z-10 mt-auto">
        <Container>
          <div className="item-start -mx-3 flex flex-wrap">
            <div className="mb-6 w-full max-w-sm px-3 md:w-3/5">
              <HeadingWCopy heading={heading} copy={copy} inverse />
              {(button0?.link || button1?.link) && (
                <div className="mt-8 flex flex-wrap items-center">
                  {button0?.link && (
                    <div className="mr-6">
                      <Button to={button0.link}>{button0.text}</Button>
                    </div>
                  )}
                  {button1?.link && (
                    <div>
                      <Button color="green" to={button1.link}>
                        {button1.text}
                      </Button>
                    </div>
                  )}
                  <div className="mt-4 flex w-full flex-wrap items-center">
                    {button2?.link && (
                      <div>
                        <ButtonAlt to={button2.link}>{button2.text}</ButtonAlt>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="mx-auto w-full px-3 py-4 md:mr-0 md:w-2/5 xl:pl-20">
              <Text variant="blockquote" className="quotes mb-3">
                {quote}
              </Text>
              {citation && (
                <Text
                  variant="label"
                  className="citation mt-4 text-right uppercase"
                >
                  {citation}
                </Text>
              )}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

HeroHome.defaultProps = {};

export default HeroHome;
