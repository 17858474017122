import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview, resolveAction, resolveImage } from "@dataResolvers";

const query = graphql`
  query FooterQuery {
    craft {
      globalSet(handle: "footer", site: "scmts") {
        ... on Craft_footer_GlobalSet {
          backgroundImage0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          social {
            ... on Craft_social_socialLink_BlockType {
              uid
              enabled
              socialUrl {
                url
              }
              platform
            }
          }
          footerNav {
            ... on Craft_footerNav_smallLink_BlockType {
              uid
              enabled
              id
              linkLink {
                url
              }
              linkText
              typeHandle
            }
            ... on Craft_footerNav_largeLink_BlockType {
              uid
              enabled
              id
              typeHandle
              linkLink {
                url
              }
              linkText
            }
            ... on Craft_footerNav_button_BlockType {
              uid
              enabled
              id
              linkText
              linkLink {
                url
              }
              typeHandle
            }
          }
          actionProgression(site: "*") {
            uid
            url
            ... on Craft_action_action_Entry {
              siteId
              slug
              formid
              actionType
              backgroundImage0 {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              backgroundImage1 {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              # activistCodes {
              #   code
              # }
              metaImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              # activistCodesExclusive {
              #   code
              # }
              heading0
              copy0
              heading3
              copy1
              # heading4
              # copy2
              heading5
              backgroundImage1 {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          # pre-footer
          link0 {
            ... on Craft_link0_linkObject_BlockType {
              enabled
              linkLink {
                url
              }
              linkText
            }
          }
          featuredAction {
            ... on Craft_featuredAction_featuredAction_BlockType {
              enabled
              cta
              action(site: "*") {
                siteId
                slug
                url
                ... on Craft_action_action_Entry {
                  metaDescription
                  actionType
                  # activistCodes {
                  #   code
                  # }
                  metaImage {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                  # activistCodesExclusive {
                  #   code
                  # }
                  heading0
                  copy0
                }
                ... on Craft_action_becomeMember_Entry {
                  metaDescription
                  # actionType
                  # activistCodes {
                  #   code
                  # }
                  metaImage {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                  # activistCodesExclusive {
                  #   code
                  # }
                  heading0
                  copy0
                }
              }
            }
          }
          backgroundImage1 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            ... on Craft_awsS3_Asset {
              url
            }
          }
        }
      }
    }
  }
`;

const previewQuery = ``;

const dataResolver = ({ craft }) => {
  const {
    actionProgression,
    footerNav,
    social,
    backgroundImage0,
    backgroundImage1,
    featuredAction,
    link0,
  } = craft.globalSet;
  return {
    footerNav: footerNav.map(item => {
      const { uid, id, typeHandle, linkLink, linkText } = item;
      return {
        id,
        uid,
        type: typeHandle,
        link: {
          link: linkLink?.url,
          text: linkText,
        },
      };
    }),
    backgroundImage: resolveImage(backgroundImage0),
    actions: actionProgression.map(action => resolveAction(action)),
    social: social.map(s => {
      return {
        uid: s.uid,
        url: s.socialUrl?.url,
        platform: s.platform,
        enabled: s.enabled,
      };
    }),
    prefooter: {
      backgroundImage: resolveImage(backgroundImage1),
      action: {
        ...resolveAction(featuredAction?.[0].action?.[0]),
        cta: featuredAction?.[0]?.cta,
      },
      secondaryLink: {
        link: link0?.[0]?.linkLink?.url,
        text: link0?.[0]?.linkText,
      },
    },
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
