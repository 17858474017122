import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview, resolveAction } from "@dataResolvers";
import moment from "moment";

const query = graphql`
  query AllActionsQuery {
    craft {
      entries(type: "action") {
        uid
        ... on Craft_action_action_Entry {
          includeInNextActions
          siteId
          metaDescription
          title
          slug
          startDatetime
          endDatetime
          url
          backgroundImage0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            ... on Craft_awsS3_Asset {
              url
            }
          }
          backgroundImage1 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            ... on Craft_awsS3_Asset {
              url
            }
          }
          # activistCodes {
          #   code
          # }
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            ... on Craft_awsS3_Asset {
              url
            }
          }
          # activistCodesExclusive {
          #   code
          # }
          heading0
          copy0
        }
      }
    }
  }
`;

// TODO: Preview query
const previewQuery = `
query AllActionsQuery {
    entries(type: "action", site: $site) {
      ... on action_action_Entry {
        includeInNextActions
        siteId
        slug
        startDatetime
endDatetime
        backgroundImage0 {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        backgroundImage1 {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        activistCodes {
          col1
          code
        }
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        activistCodesExclusive {
          code
          col1
        }
        heading0
        copy0
      }
  }
}`;

const dataResolver = ({ craft }) => {
  const { entries } = craft;
  return entries
    .sort(
      (a, b) =>
        (a.startDatetime
          ? parseInt(moment(a.startDatetime).format("YYYYMMDD"), 10)
          : 0) >
        (b.startDatetime
          ? parseInt(moment(b.startDatetime).format("YYYYMMDD"), 10)
          : 0)
    )
    .map(entry => resolveAction(entry));
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
