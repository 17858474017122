import React from "react";

import { Text } from "@atoms";

const Stat = ({ stat, heading, descriptor }) => {
  return (
    <div className="flex flex-col items-center justify-center text-center">
      <div>
        <Text variant="stat" className="text-orange">
          {stat}
        </Text>
      </div>
      <div className="mb-4 mt-2">
        <Text variant="h7" className="text-green">
          {heading}
        </Text>
      </div>
      <div className="max-w-xs">
        <Text variant="body--small">{descriptor}</Text>
      </div>
    </div>
  );
};

Stat.defaultProps = {};

export default Stat;
