import React from "react";

import { HeadingWCopy, PersonModal } from "@molecules";

const StaffSection = ({ heading, copy, people }) => {
  return (
    <div className="my-12 sm:my-24">
      <HeadingWCopy heading={heading} copy={copy} small />
      <ul className="mt-6 grid grid-cols-2 gap-6 sm:mt-12 sm:grid-cols-3 lg:gap-12">
        {people &&
          people.length &&
          people.map(p => (
            <li key={p.uid}>
              <PersonModal {...p} />
            </li>
          ))}
      </ul>
    </div>
  );
};

StaffSection.defaultProps = {};

export default StaffSection;
