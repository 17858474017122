import React from "react";

import { Text, Button, BrushBorder } from "@atoms";

const LevelCardLarge = ({
  heading,
  subheading,
  copy,
  link,
  benefitsTitle,
  benefits,
}) => {
  return (
    <div className="relative mx-auto max-w-sm bg-green text-white">
      <BrushBorder color="green" position="top" box />
      <BrushBorder color="green" position="left" box />
      <div className="relative z-10 flex flex-col items-center justify-center p-3 text-center md:p-4">
        <div className="mb-1">
          <Text variant="h7">{heading}</Text>
        </div>
        <div className="my-1 opacity-75">
          <Text variant="label" className="text-white">
            {subheading}
          </Text>
        </div>
        <div className="my-1">
          <Text variant="body--small" className="rich-text max-w-lg">
            {copy}
          </Text>
        </div>
        <Text
          variant="body--small"
          className="mb-1 mt-1 w-full text-left font-medium text-white"
        >
          {benefitsTitle}
        </Text>
        <ul className="mb-3 w-full">
          {benefits.map((b, i) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <li key={i} className="mb-1 flex w-full">
                <span className="flex h-3 w-3 items-center justify-center">
                  <span className="h-1 w-1 rounded-full bg-white" />
                </span>
                <Text
                  variant="body--small"
                  className="rich-text w-full text-left"
                >
                  {b}
                </Text>
              </li>
            );
          })}
        </ul>
        {link && link.link && (
          <div className="mt-3">
            <Button to={link.link}>{link.text}</Button>
          </div>
        )}
      </div>
      <BrushBorder color="green" position="bottom" box />
      <BrushBorder color="green" position="right" box />
    </div>
  );
};

LevelCardLarge.defaultProps = {};

export default LevelCardLarge;
