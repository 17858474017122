import React, { useState } from "react";
import clsx from "clsx";
import { AppLink } from "@defaults";
import { Button, ButtonAlt, Icon } from "@atoms";
import { motion } from "framer-motion";

const PrimaryNav = ({ links, inverse, activeNavItemState }) => {
  const [activeNavItem, setActiveNavItem] =
    activeNavItemState || useState(null);
  return (
    <motion.div>
      <ul className="-mx-2 flex flex-grow items-start justify-end text-left text-label-lg font-medium">
        {links &&
          links.map(link => {
            if (link?.type === "button") {
              return (
                <motion.li
                  onHoverStart={() =>
                    link.sublinks?.length
                      ? setActiveNavItem(link.uid)
                      : setActiveNavItem(null)
                  }
                  key={link.uid}
                  className="w-full px-3"
                >
                  <Button fullWidth to={link.link}>
                    {link.text}
                  </Button>
                </motion.li>
              );
            }
            if (link?.type === "buttonAlt") {
              return (
                <motion.li
                  onHoverStart={() =>
                    link.sublinks?.length
                      ? setActiveNavItem(link.uid)
                      : setActiveNavItem(null)
                  }
                  key={link.uid}
                  className="w-full px-3 pt-1 text-base"
                >
                  <ButtonAlt size="lg" to={link.link}>
                    <span className="whitespace-nowrap">{link.text}</span>
                  </ButtonAlt>
                </motion.li>
              );
            }
            return (
              <motion.li
                onHoverStart={() =>
                  link.sublinks?.length
                    ? setActiveNavItem(link.uid)
                    : setActiveNavItem(null)
                }
                key={link.uid}
                className="flex w-full items-center px-3 pt-1"
              >
                <AppLink
                  to={link.link}
                  className={clsx(
                    "leading-normal py-1 font-sans uppercase leading-metric tracking-button transition duration-200 hover:text-orange",
                    {
                      "text-black": !inverse && link.uid !== activeNavItem,
                      "text-white": inverse && link.uid !== activeNavItem,
                      "underline decoration-transparent decoration-2 hover:decoration-current":
                        process.env.GATSBY_AC,
                      "text-orange": link.uid === activeNavItem,
                    }
                  )}
                  activeClassName="underline decoration-2 decoration-orange"
                >
                  {link.text}
                </AppLink>
                {!!link.sublinks?.length && (
                  <Icon
                    name="chevron"
                    className={clsx("ml-1 h-6 w-6 text-orange transition", {
                      "rotate-180": link.uid === activeNavItem,
                    })}
                  />
                )}
              </motion.li>
            );
          })}
      </ul>
    </motion.div>
  );
};

PrimaryNav.defaultProps = {};

export default PrimaryNav;
