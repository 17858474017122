import React from "react";

import { Text, Image, AnimateIn } from "@atoms";

const CardQuote = ({ quote, citation, image }) => {
  return (
    <div className="bg-white p-3 shadow-lg lg:p-6">
      <AnimateIn>
        {image && (
          <div className="mx-auto mb-3 h-32 w-32 overflow-hidden rounded-full">
            <Image {...image} params={{ fit: "facearea", facepad: 10 }} />
          </div>
        )}
        {quote && (
          <Text quotes variant="h4" className="mb-3 font-serif font-medium">
            {quote}
          </Text>
        )}
        {citation && (
          <Text variant="body--large-tight" className="citation mt-4">
            {citation}
          </Text>
        )}
      </AnimateIn>
    </div>
  );
};

CardQuote.defaultProps = {};

export default CardQuote;
