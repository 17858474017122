import React from "react";

import { Image, Container, Button, ButtonAlt } from "@atoms";
import { HeadingWCopy, CardFeaturedAction, Carousel } from "@molecules";

const HeroHome = ({
  backgroundImage,
  heading,
  copy,
  button,
  buttonAlt,
  featuredAction,
  actions: _actions,
}) => {
  let actions = [];
  if (featuredAction) {
    actions = _actions ? [featuredAction, ..._actions] : [featuredAction];
  } else {
    actions = _actions;
  }
  return (
    <div className="relative z-0 flex min-h-1/3-screen flex-col bg-white pb-20 pt-24 sm:pb-32 sm:pt-40">
      <div className="absolute inset-0 z-0 bg-black">
        <div className="fade-to-black absolute left-0 right-0 top-0 z-10 h-48 rotate-180 transform opacity-25" />
        <div className="fade-to-black-horz absolute inset-0 bottom-0 z-10 w-4/5 rotate-180 transform opacity-50" />
        <div className="absolute inset-0 z-0">
          {backgroundImage && (
            <Image
              {...backgroundImage}
              params={{ exp: "-5", bri: "-2", con: "3", fit: "max" }}
              fill
            />
          )}
        </div>
      </div>
      <div className="relative z-10 mt-auto">
        <Container>
          <div className="-mx-3 flex flex-wrap">
            <div className="mb-6 w-full max-w-sm px-3 md:w-3/5">
              <HeadingWCopy heading={heading} copy={copy} inverse />
              {(button?.link || buttonAlt?.link) && (
                <div className="mt-8 flex flex-wrap items-center">
                  {button?.link && (
                    <div className="mr-6">
                      <Button to={button.link}>{button.text}</Button>
                    </div>
                  )}
                  {buttonAlt?.link && (
                    <div>
                      <ButtonAlt to={buttonAlt.link}>
                        {buttonAlt.text}
                      </ButtonAlt>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="mx-auto w-full px-3 md:mr-0 md:w-2/5 xl:pl-20">
              <Carousel altLayout maxVisible={1} buttonPosition="100%">
                {actions &&
                  actions.length > 0 &&
                  actions.map((a, i) => (
                    <div key={a.uid} className="px-px pb-4 sm:pb-0">
                      <div className="shadow-xl">
                        <CardFeaturedAction
                          {...a}
                          tag={i === 0 ? "Featured Action" : ""}
                        />
                      </div>
                    </div>
                  ))}
              </Carousel>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

HeroHome.defaultProps = {};

export default HeroHome;
