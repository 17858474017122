/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React, { useEffect } from "react";

import { Container, EmbedCode, Text } from "@atoms";
import { SocialLinks, HeadingWCopy } from "@molecules";
import { chunkify } from "@utils";

const SocialFeed = ({ heading, embedCodes }) => {
  if (embedCodes && embedCodes.length > 0) {
    useEffect(() => {
      setTimeout(() => {
        if (
          typeof window !== "undefined" &&
          window.FB &&
          window.twttr &&
          window.instgrm
        ) {
          window.FB.XFBML.parse();
          window.twttr.widgets.load();
          window.instgrm.Embeds.process();
        }
      }, 1000);
    }, []);

    return (
      <div className="relative bg-black py-24">
        <div className="relative z-10">
          <Container>
            <div className="mb-6">
              <div className="mb-12">
                <HeadingWCopy heading={heading} inverse />
              </div>
              <div className="flex flex-wrap items-center">
                <Text variant="h4" className="mr-4 font-medium text-red">
                  Follow Us
                </Text>
                <SocialLinks large />
              </div>
            </div>
            <div className="-mx-2 flex flex-wrap">
              {embedCodes &&
                chunkify(embedCodes, 3).map((a, i) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    className="w-full px-2 sm:w-1/2 lg:mb-6 lg:w-1/3"
                  >
                    {a.map((code, ii) => {
                      return (
                        <div
                          // eslint-disable-next-line react/no-array-index-key
                          key={ii}
                          className="mb-4 overflow-hidden rounded-lg shadow-xl"
                        >
                          <EmbedCode code={code} />
                        </div>
                      );
                    })}
                  </div>
                ))}
            </div>
          </Container>
        </div>
      </div>
    );
  }
  return null;
};

SocialFeed.defaultProps = {};

export default SocialFeed;
