import React, { useRef, createRef, useEffect, useState } from "react";
import InView from "@base/InView";
import gsap from "gsap";
import { useAppState } from "@state";

/**
 *
 * `AnimateIn` animates child element  when they come into the viewport or when
 * `triggerAnimation` prop is true.
 *
 */

const presets = {
  fadeUpFast: {
    initialStyle: {
      opacity: 0,
      y: 50,
    },
    gsapTo: {
      opacity: 1,
      y: 0,
    },
    duration: 0.5,
  },
  fadeInFast: {
    initialStyle: {
      opacity: 0,
    },
    gsapTo: {
      opacity: 1,
    },
    duration: 0.5,
  },
};

const AnimateIn = ({
  preset,
  threshold,
  children,
  callback,
  triggerAnimation,
  className,
}) => {
  const { initialStyle, gsapTo, duration } = presets[preset] || {};
  const [entered, setEntered] = useState(false);
  const [{ layout }] = useAppState();
  const { isTransitioning } = layout;

  const staticWrap = useRef();
  const observer = createRef();

  const onEnter = _el => {
    if (!entered) {
      const el = _el || observer?.current?.getElement();
      setEntered(true);
      if (el && preset) {
        gsap.fromTo(el, initialStyle, {
          ...gsapTo,
          ease: "power1.inOut",
          duration,
          onComplete: () => {
            // make element have transform:none to avoid any issues that may
            // arise from children with fixed-position elements
            // https://stackoverflow.com/questions/2637058/positions-fixed-doesnt-work-when-using-webkit-transform
            el.style.transform = "none";
          },
        });
      }
    }

    if (callback) {
      callback();
    }
  };

  useEffect(() => {
    if (triggerAnimation) {
      onEnter(staticWrap.current);
    }
  }, [triggerAnimation]);

  // If we passed a triggerAnimation prop then use that to call onEnter
  // Otherwise, use InView to trigger when the element is in viewport
  if (typeof triggerAnimation !== "undefined") {
    return (
      <div
        ref={staticWrap}
        className={`AnimateIn ${className}`}
        style={initialStyle}
      >
        {children}
      </div>
    );
  }

  return (
    <InView
      onEnter={!isTransitioning ? onEnter : () => null}
      observerOptions={{
        threshold,
      }}
      unobserveAfterEntry={!isTransitioning}
      className={`AnimateIn ${className}`}
      ref={observer}
      style={initialStyle}
    >
      {children}
    </InView>
  );
};

AnimateIn.defaultProps = {
  preset: "fadeInFast",
  threshold: 0.01,
  callback: null,
  triggerAnimation: undefined,
  className: "",
};

export default AnimateIn;
