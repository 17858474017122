import React from "react";

import { Container, Image, Text, Icon } from "@atoms";
import { HeadingWCopy } from "@molecules";
import clsx from "clsx";

const HeroSimple = ({
  backgroundImage,
  heading,
  copy,
  icon: otherInfoIcon,
  extraPadding,
  background,
  middle,
  inverse,
  closed,
  info,
}) => {
  let icon;
  switch (otherInfoIcon) {
    case "x":
      icon = "close";
      break;
    case "c":
      icon = "checkMark";
      break;
    default:
      break;
  }
  return (
    <div
      className={clsx("relative  z-0 flex min-h-1/3-screen  flex-col", {
        "pb-20 sm:pb-32": extraPadding,
        "pb-6": !extraPadding && !middle,
        "bg-white": !inverse && !background?.length,
        "bg-black": inverse && !background?.length,
        [background]: background?.length,
        "pb-8 pt-32": middle,
        "pt-48": !middle,
      })}
    >
      <div className="absolute inset-0 z-0">
        {!inverse && backgroundImage && (
          <>
            <div className="fade-to-white absolute left-0 right-0 top-0 z-10 h-48 rotate-180 transform opacity-50" />
            <div className="fade-to-white-horz absolute inset-0 z-10 rotate-180 transform opacity-50" />
          </>
        )}
        {inverse && backgroundImage && (
          <>
            <div className="fade-to-black absolute left-0 right-0 top-0 z-10 h-48 rotate-180 transform opacity-50" />
            <div className="fade-to-black-horz absolute inset-0 z-10 rotate-180 transform opacity-50" />
          </>
        )}
        <div className="absolute inset-0 z-0">
          {backgroundImage && (
            <Image
              {...backgroundImage}
              params={{ exp: "1", con: 5, fit: "max" }}
              fill
              placeholder={false}
            />
          )}
        </div>
      </div>
      <div className="relative z-10 mt-auto">
        <Container>
          <div className="mb-6 max-w-sm">
            <HeadingWCopy heading={heading} inverse={inverse} />
            <div className="my-6 flex items-start gap-1">
              <span
                className={clsx("px-3 py-2 text-white", {
                  "bg-red": closed,
                  "bg-green": !closed,
                })}
              >
                <Text variant="label--large">{closed ? "closed" : "open"}</Text>
              </span>
              <Icon
                name={icon}
                className="h-6 w-6 flex-shrink-0 -translate-y-1 transform text-white"
              />
              <Text variant="label--large" class="block py-1">
                {info}
              </Text>
            </div>
            <div>
              <Text>{copy}</Text>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

HeroSimple.defaultProps = {
  inverse: true,
};

export default HeroSimple;
