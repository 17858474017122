import React from "react";

import { Image, Text, ButtonAlt } from "@atoms";
import { AppLink } from "@defaults";

const CardPreview = ({ tag, image, heading, copy, button }) => {
  return (
    <AppLink
      to={button.link}
      className="group relative block bg-white text-black"
    >
      <div>
        {tag && (
          <Text
            variant="label"
            className="absolute left-0 top-0 z-10 bg-orange p-1 text-white"
          >
            {tag}
          </Text>
        )}
        <Image {...image} />
      </div>
      <div className="flex flex-col p-3">
        <div className="mb-2 text-orange">
          <Text variant="h7">{heading}</Text>
        </div>
        <div className="my-2">
          <Text variant="body--small">{copy}</Text>
        </div>
        <div className="mt-2">
          <ButtonAlt size="sm" color="green">
            {button.text}
          </ButtonAlt>
        </div>
      </div>
    </AppLink>
  );
};

CardPreview.defaultProps = {};

export default CardPreview;
