import React from "react";

import { Image, Text, ButtonAlt } from "@atoms";
import { AppLink } from "@defaults";
import clsx from "clsx";

const CardExpanded = ({
  meta,
  metaImage,
  title,
  heading,
  url,
  tag,
  subtitle,
  image,
  tagColor,
  cta,
  category,
  date,
  metaDescription,
  descriptor,
  ctaColor,
  alt,
}) => {
  return (
    <AppLink
      to={url}
      className="group flex h-full w-full flex-col text-left transition duration-200"
    >
      <div className="-mx-3 flex flex-wrap">
        {(image || metaImage) && (
          <div
            className={clsx(
              "relative -mt-8 min-h-1/2-screen w-full px-3 sm:w-2/5 md:-my-10 lg:w-1/2",
              { "sm:order-2": alt }
            )}
          >
            <div className="relative h-full w-full">
              <div className="absolute inset-0">
                <Image {...(image || metaImage)} fill />
              </div>
            </div>
          </div>
        )}
        <div className="my-3 w-full px-3 sm:w-3/5 md:px-6 lg:w-1/2">
          <div
            className={`mb-3 inline-flex p-1 ${tagColor} ${
              category?.color || tagColor ? "" : "bg-orange"
            }`}
            style={{ background: category?.color || null }}
          >
            <Text variant="label--small" className="text-white">
              {tag || category?.title || "Action"}
            </Text>
          </div>
          <Text variant="h4" className="mb-3">
            {heading || title}
          </Text>
          {(date || subtitle) && (
            <Text variant="label" className="my-4 font-medium">
              {date || subtitle}
            </Text>
          )}
          <Text variant="body--small" className="my-4">
            {metaDescription || descriptor || meta?.description}
          </Text>
          <div className="mt-auto">
            <ButtonAlt
              color={
                category && category.color
                  ? category.color
                  : ctaColor || "orange"
              }
              size="sm"
            >
              {cta}
            </ButtonAlt>
          </div>
        </div>
      </div>
    </AppLink>
  );
};

CardExpanded.defaultProps = {};

export default CardExpanded;
