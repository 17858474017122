// template for dynamic amounts
const amountTemplate = (amount, currentAmount) => {
  const label = document.createElement("label");
  label.setAttribute("class", "label-amount text-red");
  label.setAttribute("title", String(amount.toFixed(2)));
  label.setAttribute("data-amount", amount);

  const input = document.createElement("input");
  input.setAttribute("value", String(amount.toFixed(2)));
  input.setAttribute("type", "radio");
  input.setAttribute("name", "SelectAmount");
  if (amount === currentAmount) {
    input.checked = true;
  }

  const a = document.createElement("a");
  label.append(input);
  label.append(
    `$${String(
      amount % 1 === 0
        ? Number(parseFloat(amount).toFixed(0)).toLocaleString()
        : Number(parseFloat(amount).toFixed(2)).toLocaleString()
    )}`
  );
  label.append(a);
  return label;
};

const setAmounts = (amounts, currenAmount) => {
  // remove old amounts
  Array.from(document.querySelectorAll(".SelectAmount .label-amount"))
    .filter(el => !el.classList.contains("label-otheramount"))
    .forEach(el => el.parentNode.removeChild(el));
  // add new amounts
  const amtCont = document.querySelector(".SelectAmount .at-radios");
  if (amtCont) {
    amounts
      .sort((a, b) => b - a)
      .forEach(amount => {
        amtCont.prepend(amountTemplate(amount, currenAmount));
      });
  }
};

export default setAmounts;
