import React from "react";

import { Container, Image, Button, ButtonAlt } from "@atoms";
import { HeadingWCopy } from "@molecules";
import clsx from "clsx";

const HeroSimple = ({
  backgroundImage,
  heading,
  copy,
  icon,
  extraPadding,
  button,
  background,
  buttonAlt,
  middle,
  inverse,
}) => {
  return (
    <div
      className={clsx("relative  z-0 flex min-h-1/3-screen  flex-col", {
        "pb-20 sm:pb-32": extraPadding,
        "pb-6": !extraPadding && !middle,
        "bg-white": !inverse && !background?.length,
        "bg-black": inverse && !background?.length,
        [background]: background?.length,
        "pb-8 pt-32": middle,
        "pt-48": !middle,
      })}
    >
      <div className="absolute inset-0 z-0">
        {!inverse && backgroundImage && (
          <>
            <div className="fade-to-white absolute left-0 right-0 top-0 z-10 h-48 rotate-180 transform opacity-75" />
            <div className="fade-to-white-horz absolute inset-0 z-10 rotate-180 transform opacity-90" />
          </>
        )}
        {inverse && backgroundImage && (
          <>
            <div className="fade-to-black absolute left-0 right-0 top-0 z-10 h-48 rotate-180 transform opacity-50" />
            <div className="fade-to-black-horz absolute inset-0 z-10 rotate-180 transform opacity-50" />
          </>
        )}
        <div className="absolute inset-0 z-0">
          {backgroundImage && (
            <Image
              {...backgroundImage}
              params={{ exp: "1", con: 5, fit: "max" }}
              fill
              placeholder={false}
            />
          )}
        </div>
      </div>
      <div className="relative z-10 mt-auto">
        <Container>
          <div className="mb-6 max-w-sm">
            <HeadingWCopy
              icon={icon}
              heading={heading}
              copy={copy}
              inverse={inverse}
            />
            {(button?.link || buttonAlt?.link) && (
              <div className="mt-8 flex flex-wrap items-center">
                {button?.link && (
                  <div className="mr-6">
                    <Button to={button.link}>{button.text}</Button>
                  </div>
                )}
                {buttonAlt?.link && (
                  <div className="my-4">
                    <ButtonAlt to={buttonAlt.link}>{buttonAlt.text}</ButtonAlt>
                  </div>
                )}
              </div>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};

HeroSimple.defaultProps = {};

export default HeroSimple;
