import React from "react";

import { Container, Image, ButtonAlt } from "@atoms";
import { HeadingWCopy } from "@molecules";

const Hero = ({ backgroundImage, heading, copy, subtitle }) => {
  return (
    <div className="relative z-0 bg-black pb-12 pt-24 sm:pt-32 lg:pb-20">
      <div className="relative z-10">
        <Container>
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-1/2">
              <div className="mb-4">
                <ButtonAlt to="/projects" color="orange" reverse>
                  All Projects
                </ButtonAlt>
              </div>
              <HeadingWCopy
                heading={heading}
                subtitle={subtitle}
                copy={copy}
                inverse
              />
            </div>
            {backgroundImage && (
              <div className="w-full md:w-1/2 md:pl-12">
                <div className="-mr-4 mt-4 shadow-xl sm:-mr-10 md:-mr-12 md:mt-0 lg:-mr-20 xl:-mr-24">
                  <Image
                    {...backgroundImage}
                    aspectratio="8:5"
                    params={{ crop: "faces" }}
                  />
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};

Hero.defaultProps = {};

export default Hero;
