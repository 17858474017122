import React from "react";

import { CardDownload } from "@molecules";

const CardDownloadGrid = ({ cards }) => {
  if (cards && cards.length > 0) {
    return (
      <div className="-mx-2 flex flex-wrap">
        {cards.map((card, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i} className="mb-4 w-full px-2 sm:w-1/3">
            <CardDownload centered {...card} />
          </div>
        ))}
      </div>
    );
  }
  return null;
};

CardDownloadGrid.defaultProps = {};

export default CardDownloadGrid;
