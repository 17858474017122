import React from "react";

import { Text, CbWrapper } from "@atoms";

const CbList = ({ copy, indent, count, type, unordered }) => {
  return (
    <CbWrapper
      type={type}
      indent={indent}
      count={unordered ? "unordered" : count}
    >
      <Text variant="body--small">{copy}</Text>
    </CbWrapper>
  );
};
CbList.defaultProps = {};

export default CbList;
