import React from "react";

import clsx from "clsx";
import { useAllActionsData } from "@staticQueries";
import Carousel from "./Carousel";
import CardFeaturedEntryCarousel from "./CardFeaturedEntryCarousel";

const PbFeaturedEntryCarousel = ({
  entries,
  showUpcomingEvents,
  prevBlock,
  color,
  prevBlockCount,
}) => {
  let cards = entries;
  if (!entries?.length && showUpcomingEvents) {
    cards = useAllActionsData()
      .sort()
      .map(entry => {
        return {
          uid: entry.uid,
          type: entry.type,
          heading: entry.meta.title || entry.title,
          subheading: entry.date,
          metaDescription: entry.meta.descrption,
          image: entry.meta.image || entry.backgroundImage,
          url: entry.url,
        };
      });
  }
  return (
    <div
      className={clsx("relative text-black", {
        "pb-0 pt-12": prevBlock !== "featuredEntryCarousel",
        "pb-20 pt-12 sm:pt-0": prevBlock === "featuredEntryCarousel",
        "sm:-mt-16":
          prevBlock === "featuredEntryCarousel" && prevBlockCount % 2 === 1,
      })}
    >
      <Carousel
        altLayout
        maxVisible={1}
        color={color}
        altPosition={
          prevBlock === "featuredEntryCarousel" && prevBlockCount % 2 === 1
        }
      >
        {cards?.map((e, i) => (
          <div key={(i, i)} className="px-6">
            <CardFeaturedEntryCarousel
              {...e}
              color={color}
              altLayout={
                prevBlock === "featuredEntryCarousel" &&
                prevBlockCount % 2 === 1
              }
              overlap={prevBlock === "featuredEntryCarousel"}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

PbFeaturedEntryCarousel.defaultProps = {};

export default PbFeaturedEntryCarousel;
