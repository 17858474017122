import React from "react";

import { Text, Icon, Button } from "@atoms";

const CardIcon = ({ icon, heading, copy, link }) => {
  return (
    <div className="flex h-full flex-col items-center bg-white p-3 text-center shadow-xl lg:p-6">
      <div>
        {/* TODO: fix fill on icons once approved */}
        <Icon name={icon} className="h-10 w-10 text-transparent" fitHeight />
      </div>
      <div className="my-3">
        <Text variant="h4" className="text-red">
          {heading}
        </Text>
      </div>
      <div className="my-3">
        <Text variant="body--tight" className="rich-text">
          {copy}
        </Text>
      </div>
      {link && link.link && (
        <div className="mt-auto">
          <Button to={link.link}>{link.text}</Button>
        </div>
      )}
    </div>
  );
};

CardIcon.defaultProps = {};

export default CardIcon;
