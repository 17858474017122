import React from "react";

import { Image, Text, ButtonAlt } from "@atoms";
import { AppLink } from "@defaults";

const CardFeature = ({ heading, copy, image, button, tag }) => {
  return (
    <AppLink
      to={button?.link}
      className="group relative block bg-black-true p-3 text-white md:p-8 lg:p-10"
    >
      <div className="absolute inset-0 z-0">
        <div className="fade-to-black-true-horz absolute inset-0 z-20 rotate-180 transform bg-black opacity-75 transition duration-200 hover:opacity-80" />
        <Image
          {...image}
          params={{ exp: -2, con: 0, fit: "crop", crop: "edges" }}
          fill
        />
      </div>
      {/* the guts */}
      <div className="relative z-30 w-full max-w-sm lg:w-1/2">
        <div className="absolute left-0 top-0 p-1 text-white">{tag}</div>
        <Text variant="h4" className="mb-3">
          {heading}
        </Text>
        <Text variant="body--small">{copy}</Text>
        <div className="mt-3">
          <ButtonAlt>{button?.text}</ButtonAlt>
        </div>
      </div>
    </AppLink>
  );
};

CardFeature.defaultProps = {};

export default CardFeature;
