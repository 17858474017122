import React from "react";

import { Container, Button } from "@atoms";
import { Carousel, HeadingWCopy, Supporter } from "@molecules";

const CarouselSupporters = ({ heading, copy, supporters, link }) => {
  if (supporters && supporters.length > 0) {
    return (
      <div className="relative w-full overflow-hidden bg-black pb-24 pt-20 text-white">
        <Container>
          <div className="relative">
            <HeadingWCopy
              heading={heading}
              copy={copy}
              className="mx-auto mb-12 max-w-xl lg:mb-16"
              centered
              inverse
            />
            {supporters.length > 2 && (
              <Carousel
                showMultiple
                gradient="brown"
                maxVisible={3}
                centerItems
                className="-mx-3"
              >
                {supporters.map((s, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={i} className="px-3">
                    <Supporter {...s} />
                  </div>
                ))}
              </Carousel>
            )}
            {supporters.length <= 2 && (
              <div className="-mx-3 flex flex-wrap">
                {supporters.map((s, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={i} className="w-full px-3 md:w-1/3">
                    <Supporter {...s} />
                  </div>
                ))}
              </div>
            )}
          </div>
          {link && (
            <div className="mt-12 flex justify-center">
              <Button to={link.link}>{link.text}</Button>
            </div>
          )}
        </Container>
      </div>
    );
  }
  return null;
};

CarouselSupporters.defaultProps = {};

export default CarouselSupporters;
