import React, { useState, useEffect, useRef } from "react";

import { AtForm, Text } from "@atoms";
import gsap from "gsap";
import { useAppState } from "@state";
import Share from "./Share";

const ActionShort = ({
  formid,
  activistCodes,
  completedHeading,
  thankYouHeading,
  completedCopy,
  thankYouCopy,
  shareUrlOverride,
  activistCodesExclusive,
  shareCta,
  url,
}) => {
  const [{ ea }] = useAppState();

  const hasCodes = ea && ea.activistCodes;
  const actionContainer = useRef();
  const actionStep = useRef();
  const thankYouStep = useRef();
  const [actionState, setActionState] = useState({
    trackingId: null,
    amount: null,
    status:
      hasCodes &&
      activistCodesExclusive &&
      activistCodesExclusive.find(code => ea.activistCodes.includes(code))
        ? "completed"
        : "unsubmitted",
  });

  // show thankyou state
  useEffect(() => {
    if (actionState.status === "submitted") {
      gsap.to(actionContainer.current, { x: "-50%" });
      gsap.to(actionStep.current, { height: "0" });
      gsap.to(thankYouStep.current, { height: "auto" });
    }
    if (actionState.status === "reset") {
      gsap.to(actionContainer.current, { x: 0 });
      gsap.to(actionStep.current, { height: "auto" });
      gsap.to(thankYouStep.current, { height: "0" });
    }
    if (actionState.status === "completed") {
      gsap.set(actionContainer.current, { x: "-50%" });
      gsap.set(actionStep.current, { height: "0" });
      gsap.set(thankYouStep.current, { height: "auto" });
    }
  }, [actionState]);

  return (
    <div className="action--short relative w-full overflow-hidden">
      <div
        ref={actionContainer}
        className="relative z-10 flex"
        style={{
          width: "200%",
        }}
      >
        {/* Action State */}
        <div ref={actionStep} className="w-1/2 overflow-hidden px-6 text-black">
          <div className="-mx-6 flex flex-wrap justify-start lg:-mx-8">
            <AtForm
              formId={formid}
              activistCodes={activistCodes}
              actionState={[actionState, setActionState]}
              short
            />
          </div>
        </div>
        {/* Thank You State */}
        <div ref={thankYouStep} className="w-1/2 overflow-hidden">
          <div className="bg-black-alpha p-6">
            <Text variant="body--2xl-tight" className="mb-3 font-medium">
              {actionState.status === "completed"
                ? completedHeading
                : thankYouHeading}
            </Text>
            <Text variant="body--tight" className="mb-3">
              {actionState.status === "completed"
                ? completedCopy
                : thankYouCopy}
            </Text>
            <Share
              small
              cta={shareCta}
              formid={formid}
              trackingId={actionState.trackingId}
              url={url}
              shareUrlOverride={shareUrlOverride}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ActionShort.defaultProps = {};

export default ActionShort;
