import React from "react";

import { Image } from "@atoms";
import Share from "./Share";
import HeadingWCopy from "./HeadingWCopy";

const CardCta = ({
  heading,
  copy,
  image,
  url,
  formid,
  shareUrlOverride,
  trackingId,
  shareCta,
  inverse,
  disableShare,
}) => {
  return (
    <div>
      <div className="-mx-3 flex flex-wrap items-center lg:p-6">
        <div className="order-2 w-full px-3 sm:order-1 sm:w-1/2">
          <HeadingWCopy inverse={inverse} small heading={heading} copy={copy} />
          {!disableShare && (
            <div className="mt-6">
              <Share
                cta={shareCta}
                shareUrlOverride={shareUrlOverride}
                trackingId={trackingId}
                formid={formid}
                url={url}
                small
              />
            </div>
          )}
        </div>
        <div className="relative order-1 w-full px-3 sm:order-2 sm:w-1/2">
          <Image {...image} />
        </div>
      </div>
    </div>
  );
};

CardCta.defaultProps = {};

export default CardCta;
