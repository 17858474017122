const resolveImages = images => {
  if (images && images.length > 0) {
    return images.map(image => {
      const { status, title, url, height, width, hasFocalPoint, focalPoint } =
        image;
      return {
        status,
        title,
        url,
        height,
        width,
        focalPoint: hasFocalPoint ? focalPoint : false,
      };
    });
  }
  return null;
};

export default resolveImages;
