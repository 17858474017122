import React from "react";

import { Image, Text, ButtonAlt } from "@atoms";
import clsx from "clsx";

const CardFeaturedEntryCarousel = ({
  image,
  title,
  heading,
  url,
  cta,
  category,
  tag,
  date,
  descriptor,
  altLayout,
  color,
}) => {
  return (
    <div
      className={clsx("group transition duration-200")}
      style={{ minHeight: "400px" }}
    >
      <div className="-mx-3 flex flex-wrap">
        {image && image?.url && (
          <div
            className={clsx(
              "relative h-64 w-full px-3 sm:min-h-1/4-screen sm:w-2/5 sm:self-stretch lg:w-1/2",
              {
                "sm:order-1": !altLayout,
                "pr-12 sm:order-2": altLayout,
              }
            )}
          >
            <Image {...image} fill />
          </div>
        )}
        {(!image || !image?.url) && (
          <div
            className={clsx(
              "relative -my-6 h-full min-h-1/4-screen w-full px-3 sm:w-2/5 lg:w-1/2",
              {
                "sm:order-1": !altLayout,
                "pr-12 sm:order-2": altLayout,
              }
            )}
          >
            <div
              className={clsx("absolute inset-0 bg-black", {})}
              style={{ background: color }}
            />
          </div>
        )}
        <div
          className={clsx("w-full px-6 py-10 sm:w-3/5 lg:w-1/2", {
            "ml-auto sm:order-2 sm:pb-20 sm:pr-12": !altLayout,
            "sm:order-1 sm:pb-16 sm:pl-16 sm:pt-32": altLayout,
          })}
        >
          {tag !== "page" && (
            <div
              className={`mb-3 inline-flex p-1 text-white ${
                category?.color || color ? "" : "bg-orange"
              }`}
              style={{ background: category?.color || color || null }}
            >
              <Text variant="label--small">
                {category?.title || tag || "Action"}
              </Text>
            </div>
          )}
          <Text variant="h4" className="mb-3">
            {heading || title}
          </Text>
          {date && (
            <Text variant="h7" className="my-3 font-medium">
              {date}
            </Text>
          )}
          <Text variant="body--small" className="my-2">
            {descriptor}
          </Text>

          <div className="mt-auto">
            <ButtonAlt
              color={
                (category && category?.color) || color
                  ? color || category?.color
                  : "orange"
              }
              size="sm"
              to={url}
            >
              {cta || "Read More"}
            </ButtonAlt>
          </div>
        </div>
      </div>
    </div>
  );
};

CardFeaturedEntryCarousel.defaultProps = {};

export default CardFeaturedEntryCarousel;
