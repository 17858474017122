import React from "react";

import { AppLink } from "@defaults";
import { Icon, Image, Text } from "@atoms";

const PressTease = ({ url, image, title }) => {
  return (
    <AppLink to={url} className="group flex w-full text-left">
      {!image && (
        <div className="mr-4 flex w-12 w-16 flex-shrink-0 items-center justify-center  text-red sm:w-24 md:w-32">
          <Icon name="document" className="h-12 w-12" />
        </div>
      )}
      {image && (
        <div className="mr-4 w-16 flex-shrink-0 text-red  sm:w-24 md:w-32">
          <Image {...image} />
        </div>
      )}
      <Text variant="body--large-tight" className="font-medium">
        {title}
      </Text>
    </AppLink>
  );
};

PressTease.defaultProps = {};

export default PressTease;
