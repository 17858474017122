import React from "react";

import clsx from "clsx";

const PbStats = () => {
  return <>{/* the guts */}</>;
};

PbStats.defaultProps = {};

export default PbStats;
