import React from "react";

import { Image, Text } from "@atoms";

const Testimonial = ({ image, quote, citation }) => {
  return (
    <div className="p-3">
      <div className="-mx-6 flex flex-wrap items-center sm:flex-nowrap">
        <div className="w-full flex-shrink-0 px-6 sm:w-1/2">
          {image && (
            <div className="mx-auto mb-3 w-full shadow-xl">
              <Image {...image} params={{ crop: "faces" }} />
            </div>
          )}
        </div>
        <div className="my-6 w-full px-6 sm:w-auto">
          {quote && (
            <Text variant="body" className="mb-3 font-serif" quotes>
              {quote}
            </Text>
          )}
          {citation && (
            <Text className="citation mt-4 font-sans text-sm uppercase">
              {citation}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

Testimonial.defaultProps = {
  inverse: false,
};

export default Testimonial;
