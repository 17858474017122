import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import useWindowSize from "@hooks/useWindowSize";

const Sticky = ({ children, className }) => {
  const el = useRef();
  const elParent = useRef();
  const scrollListener = useRef();
  const [topScroll, setTopScroll] = useState(0);
  const [bottomScroll, setBottomScroll] = useState(0);
  const { innerWidth } = useWindowSize();

  useEffect(() => {
    // create observer
    let elObserver;
    if (el.current) {
      elObserver = new ResizeObserver(entries => {
        entries.forEach(() => {
          const elHeight = el.current?.clientHeight;
          const pHeight = elParent.current?.clientHeight;
          if (elHeight < pHeight - 100) {
            const rect = el.current.parentElement.getBoundingClientRect();
            // parentEl.style.minHeight = stickyEl.current.clientHeight;
            setTopScroll(rect.top + window.scrollY);
            setBottomScroll(
              rect.bottom + window.scrollY - el.current.clientHeight
            );
          }
        });
      });
      // elObserver.observe(el.current.parentElement);
      elObserver.observe(el.current);
    }
    return () => {
      // disconnect observer
      if (elObserver) {
        elObserver.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (scrollListener.current) {
      document.removeEventListener("scroll", scrollListener.current);
    }
    if (topScroll && bottomScroll && innerWidth >= 850) {
      if (scrollListener.current) {
        document.removeEventListener("scroll", scrollListener.current);
      }
      scrollListener.current = () => {
        const heightAdjust =
          el.current.clientHeight > window.innerHeight
            ? el.current.clientHeight - window.innerHeight
            : 0;
        if (
          window.scrollY > topScroll + heightAdjust &&
          window.scrollY < bottomScroll
        ) {
          gsap.set(el.current, {
            y: window.scrollY - topScroll - heightAdjust,
          });
        }
      };
      document.addEventListener("scroll", scrollListener.current);
    } else if (innerWidth < 850) {
      gsap.set(el.current, {
        y: 0,
      });
    }
    return () => {
      if (scrollListener.current) {
        document.removeEventListener("scroll", scrollListener.current);
      }
    };
  }, [topScroll, bottomScroll, innerWidth]);

  return (
    <div ref={elParent} className="relative h-full w-full">
      <div ref={el} className={className}>
        {children}
      </div>
    </div>
  );
};

Sticky.defaultProps = {};

export default Sticky;
