import React from "react";

import clsx from "clsx";
import { Container, Image } from "@atoms";
import { AppLink } from "@defaults";
import HeadingWCopy from "./HeadingWCopy";
import Carousel from "./Carousel";

const PbSponsorCarousel = ({ heading, descriptor, sponsors }) => {
  return (
    <div className="my-12">
      <Container>
        <HeadingWCopy
          heading={heading}
          copy={descriptor}
          small
          green
          centered
        />
      </Container>
      <div className="px-12">
        <Carousel showMultiple dark>
          {sponsors.map((s, i) => {
            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className="flex flex-grow items-center justify-center px-3"
              >
                <div
                  className={clsx(
                    "relative inset-0 mb-3 flex w-full flex-col items-center justify-center px-1 py-6 text-center",
                    {
                      "h-24 md:px-1": s.logo.width * 0.75 > s.logo.height,
                      "h-48 md:px-2": s.logo.width * 0.75 <= s.logo.height,
                    }
                  )}
                >
                  <AppLink
                    to={s.url}
                    className="group flex h-full w-full transform items-center p-1 transition duration-200 hover:scale-105"
                  >
                    <div className="relative h-full w-full">
                      <Image
                        {...s.logo}
                        className="absolute inset-0 flex h-full w-full items-center"
                        placeholder={false}
                        ignoreHeight
                      />
                    </div>
                  </AppLink>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

PbSponsorCarousel.defaultProps = {};

export default PbSponsorCarousel;
