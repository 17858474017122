import React from "react";

const Square = ({ children, className: _className }) => {
  return (
    <div
      className={`${_className} relative w-full`}
      style={{ paddingBottom: "100%" }}
    >
      <div className="absolute inset-0 z-10 flex flex-col">{children}</div>
    </div>
  );
};

Square.defaultProps = {};

export default Square;
