import React from "react";

import {
  HeadingWCopy,
  CardFeaturedAction,
  CardFeature,
  CardPreview,
} from "@molecules";
import { Text, Container, Button, Image, BrushBorder } from "@atoms";
import { chunkify } from "@utils";

const ImpactGrid = ({ heading, icon, copy, impact, inverse, link }) => {
  const renderSwitch = card => {
    switch (card.type) {
      case "feature":
        return (
          <div className="relative mb-4 w-full lg:mb-6">
            <CardFeature {...card} />
          </div>
        );
      case "image":
        return (
          <div className="relative mb-4 w-full lg:mb-6">
            <Image {...card.image} caption={card.caption} />
          </div>
        );
      case "card":
        return (
          <div className="relative mb-4 w-full lg:mb-6">
            <CardPreview {...card} />
          </div>
        );
      case "action":
        return (
          <div className="relative mb-4 w-full lg:mb-6">
            <CardFeaturedAction {...card} tag={card?.category?.title} />
          </div>
        );
      default:
        return card.type;
    }
  };

  return (
    <div className="relative py-px">
      <BrushBorder position="top" color="black" />
      <div className="relative -mt-16 py-px">
        <Container>
          <HeadingWCopy
            heading={heading}
            icon={icon}
            className="mb-4 max-w-2xl lg:mb-6"
            inverse={inverse}
          />
          {copy && <Text variant="body--large">{copy}</Text>}
          {/* TODO: chunkify */}
          <div className="-mx-2 flex flex-wrap">
            <div className="mb-2 w-full px-2">{renderSwitch(impact[0])}</div>
            {impact &&
              chunkify(impact.slice(1, impact.length), 2)
                .filter(a => a)
                .map((a, i) => {
                  return (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      className="mb-6 w-full px-3 sm:w-1/2 md:mb-10 md:px-4"
                    >
                      {a?.map(card => renderSwitch(card))}
                    </div>
                  );
                })}
          </div>
          {link && link.link && (
            <div className="mt-12 flex items-center justify-center">
              <Button size="sm" to={link.link}>
                {link.text}
              </Button>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

ImpactGrid.defaultProps = {
  inverse: false,
};

export default ImpactGrid;
