import React from "react";

import clsx from "clsx";
import { Text, Image, ButtonAlt } from "@atoms";
import { AppLink } from "@defaults";

const ListItemArticle = ({
  image,
  tag,
  color,
  heading,
  subheading,
  copy,
  url,
  cta,
}) => {
  return (
    <AppLink to={url} className="block w-full text-left">
      <div className={clsx("flex", { "mb-2": !image })}>
        <div className="bg-black p-1 text-white " style={{ background: color }}>
          <Text variant="label--small">{tag || "Article"}</Text>
        </div>
      </div>
      <div className="flex flex-wrap gap-4 md:flex-nowrap">
        {image && (
          <div className="relative w-full flex-shrink-0 md:w-1/3">
            <Image {...image} aspectratio="4:3" />
          </div>
        )}
        <div className="w-auto flex-grow">
          <Text variant="h5">{heading}</Text>
          <Text variant="label" className="my-2 text-orange">
            {subheading}
          </Text>
          <Text variant="body--small">{copy}</Text>
          <div className="mt-2">
            <ButtonAlt noIcon size="sm" color="orange">
              {cta}
            </ButtonAlt>
          </div>
        </div>
      </div>
    </AppLink>
  );
};

ListItemArticle.defaultProps = {};

export default ListItemArticle;
