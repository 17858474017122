/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React, { useEffect } from "react";

import { Container, EmbedCode, Button } from "@atoms";
import { HeadingWCopy, SocialLinks, Carousel } from "@molecules";

const SocialFeed = ({ heading, copy, button, embedCodes }) => {
  if (embedCodes && embedCodes.length > 0) {
    useEffect(() => {
      setTimeout(() => {
        if (
          typeof window !== "undefined" &&
          window.FB &&
          window.twttr &&
          window.instgrm
        ) {
          window.FB.XFBML.parse();
          window.twttr.widgets.load();
          window.instgrm.Embeds.process();
        }
      }, 1000);
    }, []);

    return (
      <div className="relative z-0 w-full">
        <Container>
          <div className="mx-auto max-w-lg">
            <HeadingWCopy centered small green heading={heading} copy={copy} />
          </div>
          <div className="mt-8 flex items-center justify-center">
            <Button to={button.link}>{button.text}</Button>
          </div>
          <div className="my-10 w-full">
            <Carousel
              dark
              showMultiple
              gradient="white-secondary"
              maxVisible={3}
              forceButtons
              buttonPosition="50%"
            >
              {embedCodes &&
                embedCodes.map((code, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={i} className="px-2">
                    <div className="mb-4 h-1/3-screen w-full overflow-x-hidden overflow-y-scroll rounded shadow-xl">
                      <EmbedCode code={code} />
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
          <div className="-mt-2 flex items-center justify-center">
            <SocialLinks large />
          </div>
        </Container>
      </div>
    );
  }
  return null;
};

SocialFeed.defaultProps = {};

export default SocialFeed;
