import React from "react";

import { Text, Button } from "@atoms";

const LevelCardSmall = ({
  heading,
  subheading,
  benefits,
  benefitsTitle,
  copy,
  link,
  footnote,
}) => {
  return (
    <div className="relative mx-auto h-full max-w-xl bg-white shadow-xl">
      <div className="relative z-10 flex h-full flex-col text-center">
        <div className="px-3 py-2 md:px-4 md:py-3">
          <div className="mb-2">
            <Text variant="h7" className="text-green">
              {heading}
            </Text>
          </div>
          <div className="my-1">
            <Text
              variant="label"
              className="rich-text font-sans font-medium text-orange"
            >
              {subheading}
            </Text>
          </div>
          <div className="-mb-3 mt-3">
            <Text variant="body--small" className="rich-text text-black">
              {copy}
            </Text>
          </div>
        </div>
        <div className="flex flex-grow flex-col bg-green px-3 pb-3 pt-2 md:px-4 md:pb-4">
          <Text
            variant="body--small"
            className="mb-2 mt-3 w-full text-left font-medium text-white"
          >
            {benefitsTitle}
          </Text>
          <ul className="mb-3 w-full">
            {benefits.map((b, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <li key={i} className="mb-1 flex w-full">
                  <span className="flex h-3 w-3 items-center justify-center">
                    <span className="h-1 w-1 rounded-full bg-white" />
                  </span>
                  <Text
                    variant="body--small"
                    className="rich-text w-full text-left"
                  >
                    {b}
                  </Text>
                </li>
              );
            })}
          </ul>
          {footnote && (
            <Text
              variant="body--small-tight"
              className="mb-4 w-full text-center"
            >
              {footnote}
            </Text>
          )}
          {link && link.link && (
            <div className="mt-auto">
              <Button to={link.link}>{link.text}</Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

LevelCardSmall.defaultProps = {};

export default LevelCardSmall;
