import moment from "moment-timezone";
import resolveImage from "./resolveImage";
// these resolutions need to be reflexive and recursive
// ! IMPORTANT: if something's broken here, look here
// eslint-disable-next-line import/no-cycle
import resolveIssue from "./resolveIssue";
import resolveCB from "./resolveCB";

const resolveAction = _action => {
  if (_action) {
    const action = { ..._action };

    const {
      title,
      uid,
      slug,
      url,
      typeHandle,
      metaTitle,
      metaDescription,
      metaImage,
      // general
      actionAlignment,
      includeInNextActions,
      // action details
      heading0,
      videoUrl,
      backgroundImage2,
      copy0,
      copy3,
      backgroundImage3,
      ctaHeading,
      backgroundImage0,
      nextActions,
      alternateAction,
      alternateActionCta,
      // everyaction details
      actionType,
      actionCustomCtaText,
      formid,
      activistCodes,
      activistCodesExclusive,
      // thank you details
      heading3,
      copy1,
      heading4,
      heading2,
      copy2,
      heading5,
      shareUrlOverride,
      backgroundImage1,
      // impact
      showImpactGrid,
      heading1,
      impactGrid,
      // social feed
      showSocialFeed,
      socialFeed,
      // related content
      showRelatedIssues,
      relatedIssues,
      // parent issue
      issue,
      // other
      startDatetime,
      endDatetime,
      tag,
      actionCategory,
      volunteerCategories,
      // raffle
      color0,
      color1,
      color2,
      boolean2,
      boolean1,
      contentBuilder,
    } = action;

    const resolveDates = (startDate, endDate) => {
      const sd = startDate
        ? moment(startDate).tz("America/Los_Angeles")
        : false;
      const ed = endDate ? moment(endDate).tz("America/Los_Angeles") : false;
      if (sd && ed) {
        if (sd.format("MMDDYYYY") === ed.format("MMDDYYYY")) {
          return `${sd.format("MMMM D @ h:mma")} - ${ed.format("h:mma")}`;
        }
        return `${sd.format("MMMM D @ h:mma")} - ${ed.format(
          "MMMM D @ h:mma"
        )}`;
      }
      if (sd) {
        return `${sd.format("MMMM D @ h:mma")}`;
      }
      return false;
    };

    return {
      uid,
      url: url || shareUrlOverride,
      meta: {
        title,
        metaDescription,
        description: metaDescription,
        metaImage: resolveImage(metaImage),
        metaTitle,
        slug,
        url,
      },
      includeInNextActions,
      slug,
      title,
      disableShare: typeHandle === "raffle" ? showSocialFeed : boolean1,
      date: tag || resolveDates(startDatetime, endDatetime),
      startDatetime,
      layout: actionAlignment,
      // action details
      backgroundImage: resolveImage(backgroundImage0),
      heading: heading0 || metaTitle || title,
      copy: copy0,
      readMore: copy3,
      readMoreCta: ctaHeading,
      alternateAction,
      alternateActionCta,
      category: {
        title: actionCategory?.[0]?.title || volunteerCategories?.[0]?.title,
        color: actionCategory?.[0]?.color1 || volunteerCategories?.[0]?.color1,
      },
      categories: actionCategory || volunteerCategories,
      videoUrl,
      placeholder: resolveImage(backgroundImage2),
      nextActions:
        nextActions && nextActions.length > 0
          ? nextActions
              .map(nextAction => resolveAction(nextAction))
              .filter(nextAction => nextAction.includeInNextActions)
          : [],
      // everyaction details
      actionType,
      cta: actionCustomCtaText || "Take Action",
      activistCodes: activistCodes?.map(code => code.code),
      activistCodesExclusive: activistCodesExclusive?.map(code => code.code),
      formid,
      // thank you details
      thankYouHeading: heading3,
      thankYouCopy: copy1,
      completedHeading: heading4,
      completedCopy: copy2,
      shareCta: heading5,
      shareUrlOverride,
      thankYouBackgroundImage: resolveImage(backgroundImage3),
      thankYouContentImage: resolveImage(backgroundImage1),
      // impact
      showImpactGrid,
      impact: {
        enabled: showImpactGrid,
        heading: heading1,
        copy: copy2 || null,
        impact: impactGrid?.map(card => {
          return {
            ...card,
            image: resolveImage(card.image),
            logo: resolveImage(card.logo),
          };
        }),
      },
      // social feed
      showSocialFeed,
      social: {
        enabled: showSocialFeed,
        heading: heading2,
        embedCodes: socialFeed
          ?.filter(code => code.enabled)
          .map(code => {
            return code.code;
          }),
      },
      // related content
      showRelatedContent: showRelatedIssues,
      relatedContent: {
        enabled: showRelatedIssues,
        heading: "Dig Into Related Issues",
        cards: relatedIssues
          ?.filter(card => card.enabled)
          .map(card => {
            switch (card.__typename) {
              case "Craft_relatedIssues_action_BlockType":
                // eslint-disable-next-line no-case-declarations
                const cardAction = resolveAction(card.action[0]);
                return {
                  image: cardAction.metaImage,
                  url: `/action/${cardAction.slug}`,
                  cta: cardAction.linkText || "Take Action",
                  heading: cardAction.heading,
                  label: "action",
                };
              case "entry":
                return card;
              default:
                return {
                  image: resolveImage(card.image),
                  program: card.organization,
                  url: card.linkLink?.url,
                  cta: card.cta,
                  heading: card.heading,
                  label: card.contentType,
                };
            }
          }),
      },
      contentBuilder: resolveCB(contentBuilder),
      // parent issue
      issue: resolveIssue(issue?.[0]),
      metaImage: resolveImage(metaImage),
      metaDescription,
      // raffle stuff
      raffle: {
        hero: resolveImage(backgroundImage3),
        backgroundColor: color0,
        accentColor: color1,
        secondaryColor: color2,
        backgroundFill: resolveImage(backgroundImage1),
        backgroundTop: resolveImage(backgroundImage0),
        backgroundBottom: resolveImage(backgroundImage2),
        darkMenu: boolean2,
        inverseText: boolean1,
        contentBuilder: resolveCB(contentBuilder),
      },
    };
  }
  return null;
};

export default resolveAction;
