import React from "react";

import { Image, Text } from "@atoms";
import { HeadingWCopy } from "@molecules";

const HeroArticle = ({ image, heading, copy, date }) => {
  return (
    <div className="relative z-0 pb-6 pt-40 text-black">
      <div className="relative z-10">
        <div className="flex items-center">
          <div className="w-full">
            {image && (
              <div className="mb-6">
                <Image {...image} aspectratio="8:5" />
              </div>
            )}
            <HeadingWCopy heading={heading} />
            <Text variant="label" className="my-6">
              {`${date}`}
            </Text>
            <Text variant="body--large-tight">{copy}</Text>
            {/* <div className="mt-6">
                <Share small url={url} />
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

HeroArticle.defaultProps = {};

export default HeroArticle;
