module.exports = {
  mode: "jit",
  content: ["./src/**/*.{js,svg,css}"],
  theme: {
    lineHeight: {
      heading: 1.1,
      body: 1.25,
      more: 1.5,
      metric: 1.75,
      none: 1,
    },
    fontSize: {
      xxs: ".625rem",
      // sm: ".875rem",
      // base: "1rem",
      xl: "1.25rem",
      "1-5xl": "1.33rem",
      "2xl": "1.5rem",
      "2-5xl": "1.75rem",
      "3xl": "2rem",
      "4xl": "2.5rem",
      "5xl": "3rem",
      "6xl": "4rem",
      "7xl": "5rem",
      "8xl": "6rem",
      "9xl": "7rem",
      "10xl": "8rem",
      // brand guideline fonts
      h1: "3.33rem",
      h2: "3rem",
      h3: "2.67rem",
      h4: "2rem",
      h5: "1.67rem",
      h6: "1.5rem",
      h7: "1.08rem",
      lg: "1.17rem",
      md: "1rem",
      base: "1rem",
      sm: "0.66rem",
      xs: "0.54rem",
      "label-lg": "0.7625rem",
      label: "0.69rem",
      "label-sm": "0.54rem",
      byline: "0.54rem",
      fineprint: "0.45rem",
      metric: "4.17rem",
    },
    shadow: {
      // xs: "box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)",
      // sm: "box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      // "shadow	box": -"shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
      // md:
      //   "box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
      lg: "box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3)",
      xl: "box-shadow: 0 15px 40px rgba(0, 0, 0, 0.5)",
      // "2xl": "box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)",
      // inner: "box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
      // outline: "box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)",
      // none: "box-shadow: none",
    },
    screens: {
      xxs: { max: "350px" }, // for super small screens
      sm: "700px", // bigger than most phones
      md: "850px",
      lg: "1200px",
      xl: "1700px", // larger than 15" macbook pro
      xxl: "2000px",
    },
    fontFamily: {
      sans: ["oswald", "sans-serif"],
      serif: ["serifa", "serif"],
      // "serif-condensed": ["serifa-condensed", "serif"],
      stats: ["wicked-grit", "sans-serif"],
    },
    extend: {
      colors: {
        transparent: "transparent",
        green: {
          DEFAULT: "#6C8253",
          secondary: "#4C7067",
        },
        orange: "#F08721",
        blue: "#546D85",
        brown: "#8E6F51",
        red: "#8E4A4A",
        white: {
          DEFAULT: "#FFFFFF",
          secondary: "#F6F6F4",
        },
        gray: {
          DEFAULT: "#9F9C9B",
          light: "#EDF0F2",
        },
        black: {
          DEFAULT: "#312B26",
          alpha: "#312B2666",
          true: "#111111",
        },
      },
      // include site-specific styles
      borderWidth: {
        3: "3px",
      },
      zIndex: {
        60: 60,
      },
      maxWidth: {
        xs: "20rem",
        sm: "24rem",
        md: "28rem",
        lg: "32rem",
        xl: "36rem",
        "2xl": "44rem",
        "3xl": "52rem",
        "4xl": "60rem",
        "5xl": "68rem",
        "6xl": "76rem",
        "7xl": "84rem",
        "8xl": "92rem",
        full: "100%",
        screen: "100vw",
      },
      translate: {
        "1/2": "50%",
      },
      maxHeight: {
        hero: "1080px",
      },
      transitionProperty: {
        DEFAULT:
          " background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, box-shadow",
      },
      opacity: {
        10: ".1",
        20: ".2",
        30: ".3",
        40: ".4",
        60: ".6",
        70: ".7",
        80: ".8",
        90: ".9",
        95: ".95",
      },
      letterSpacing: {
        // calculated to px value of # @ 16px
        "-3": "-0.1875rem",
        "-2": "-0.125rem",
        "-1": "-0.0625rem",
        0: "0px",
        button: "1px",
        0.5: "0.03125rem",
        1: "0.0625rem",
        2: "0.125rem",
        3: "0.1875rem",
      },
      minHeight: {
        cards: "800px",
        hero: "800px",
        "1/4-screen": "25vh",
        "1/3-screen": "33.333vh",
        "2/5-screen": "40vh",
        "3/7-screen": "43vh",
        "1/2-screen": "50vh",
        "4/7-screen": "57vh",
        "3/5-screen": "60vh",
        "2/3-screen": "66.666vh",
        "3/4-screen": "75vh",
        "7/8-screen": "87.5vh",
      },
      height: {
        25: "25%",
        "1/3-screen": "33.3vh",
        "1/2-screen": "50vh",
      },
      width: {
        "1/1": "100%",
        "1/7": "14%",
        "2/7": "28%",
        "3/7": "42%",
        "4/7": "58%",
        "5/7": "72%",
        "6/7": "86%",
      },
      fill: theme => theme("colors"),
    },
  },
  variants: {
    opacity: ["hover", "group-hover", "responsive"],
    fill: ["hover", "group-hover", "responsive"],
    scale: ["hover", "group-hover", "responsive"],
    translate: ["hover", "group-hover", "responsive"],
    boxShadow: ["hover", "group-hover", "responsive"],
    textColor: ["responsive", "hover", "focus", "active", "group-hover"],
    zIndex: ["hover", "group-hover", "responsive"],
    borderColor: ["hover", "group-hover", "responsive"],
  },
  future: {
    removeDeprecatedGapUtilities: true,
    purgeLayersByDefault: true,
  },
};
