import React from "react";

import { AppLink } from "@defaults";
import { arrow as Arrow } from "@svg";

const BigLink = ({ children, to, className: _className, key }) => {
  return (
    <AppLink key={key} to={to} className={`group flex ${_className}`}>
      <div className="scmts-icon mr-2 h-5 w-5 flex-shrink-0 transition duration-200 group-hover:text-red">
        <Arrow />
      </div>
      <div className="font-medium underline decoration-transparent decoration-2 hover:decoration-current">
        {children}
      </div>
    </AppLink>
  );
};

BigLink.defaultProps = {};

export default BigLink;
