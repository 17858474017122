import React from "react";

import { Container, Button } from "@atoms";
import { HeadingWCopy, CardDownload } from "@molecules";

const MembershipBenefits = ({ heading, copy, cards, link }) => {
  return (
    <div className="relative w-full overflow-hidden">
      <Container>
        <div className="relative">
          <HeadingWCopy
            heading={heading}
            copy={copy}
            className="mx-auto mb-12 max-w-xl lg:mb-16"
            centered
          />
        </div>
        <div className="-mx-2 flex flex-wrap">
          {cards.map(card => (
            <div key={card.heading} className="mb-4 w-full px-2 sm:w-1/3">
              <CardDownload {...card} />
            </div>
          ))}
        </div>
        <div className="mt-12 flex justify-center">
          <Button to={link.link}>{link.text}</Button>
        </div>
      </Container>
    </div>
  );
};

MembershipBenefits.defaultProps = {};

export default MembershipBenefits;
