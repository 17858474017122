import React from "react";

import { Container, Button, Image } from "@atoms";
import { SocialLinks } from "@molecules";
import { AppLink } from "@defaults";
import moment from "moment";
import { useAppState } from "@state";
import { useBrandData } from "@staticQueries";
import Cta from "./Cta";

const SiteFooter = ({ backgroundImage, nav, action }) => {
  const { logoLight } = useBrandData();

  const [{ layout }] = useAppState();
  const {
    template,
    // hideNav
  } = layout;
  const isAction = template === "action";
  return (
    <footer
      className={` mt-auto w-full px-2 font-medium tracking-0.5 transition duration-500 sm:px-0 ${
        isAction ? "bg-green py-10" : "bg-black pb-8 pt-16 sm:pb-12 sm:pt-20"
      } relative z-0 font-sans text-base text-white sm:text-sm`}
    >
      <div
        className={`absolute inset-0 z-0  transition duration-500 ${
          isAction ? "opacity-0" : "opacity-100"
        } `}
      >
        <div className="absolute inset-0 z-10 bg-gradient-to-b from-transparent to-black opacity-50" />

        <div className="absolute inset-0 z-0">
          {backgroundImage && (
            <Image
              {...backgroundImage}
              params={{ exp: "-10", con: 5, fit: "max" }}
              fill
            />
          )}
        </div>
      </div>
      <div className="relative z-10 mt-auto">
        <Container>
          <div>
            {!isAction && action && (
              <div className="mb-10 shadow-xl">
                <Cta
                  heading={action.heading}
                  copy={action.copy}
                  backgroundImage={action.backgroundImage}
                  action={action}
                  showAction
                />
              </div>
            )}
          </div>
          <div className="flex flex-wrap md:flex-nowrap md:justify-between">
            {/* logo */}
            <div className="order-2 mb-6 mr-6 flex-shrink-0 sm:order-1">
              <div>
                <AppLink
                  className="relative flex h-20 w-40 max-w-full items-center py-2 font-medium"
                  to={
                    process.env.GATSBY_PRIMARY_URL ||
                    "https://santacruztrails.org/"
                  }
                >
                  {/* <Icon name="logo" className="w-full absolute inset-0 h-auto" />
                <Icon
                  name="logoInverse"
                  className={`w-full absolute inset-0 h-auto transition duration-200
                  ${theme === "light" ? "opacity-0" : "opacity-100"}`}
                /> */}
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: logoLight }}
                    className="scmts-icon py-2 text-white"
                  />
                </AppLink>
              </div>

              {/* details */}
              <div className="my-4">
                <AppLink
                  className="text-label-large uppercase sm:text-label"
                  to="https://santacruztrails.org"
                >
                  santacruztrails.org
                </AppLink>
              </div>

              {/* copyright */}
              <div className="my-4 w-56 font-serif text-label sm:w-48">
                <p>
                  &copy; {moment().format("YYYY")} Santa Cruz Mountains Trail
                  Stewardship
                  <br />
                  EIN 77-0457425
                  <br />
                  PO Box 331. Santa Cruz, CA 95061 USA
                </p>
              </div>
              <div className="my-4 uppercase">
                {/* TODO: Pull from globals */}
                <AppLink
                  className="text-label-large sm:text-label"
                  to="/privacy"
                >
                  Privacy Policy
                </AppLink>
              </div>
            </div>
            {/* nav */}
            <div className="order-1 mt-6 max-w-sm sm:order-2 sm:mt-0 sm:pt-12">
              {/* mobile */}
              <div className="text-label-large -mx-6 -mb-4 flex flex-wrap justify-end text-white sm:hidden sm:text-label">
                {nav &&
                  nav.map(item => {
                    const { uid, type, link } = item;
                    switch (type) {
                      case "smallLink":
                        return (
                          <div
                            key={uid}
                            className="order-4 mb-6 flex w-full items-center px-6 text-red"
                          >
                            <AppLink to={link.link}>{link.text}</AppLink>
                          </div>
                        );
                      case "largeLink":
                        return (
                          <div
                            key={uid}
                            className="order-2 mb-6 flex w-full items-center px-6 uppercase"
                          >
                            <AppLink to={link.link}>{link.text}</AppLink>
                          </div>
                        );
                      case "button":
                        return (
                          <div
                            key={uid}
                            className="order-3 mb-6 flex w-full items-center px-3"
                          >
                            <Button to={link.link}>{link.text}</Button>
                          </div>
                        );
                      default:
                        return null;
                    }
                  })}

                <div className="order-1 mb-6 flex w-full items-center px-6 sm:w-1/3">
                  <SocialLinks />
                </div>
              </div>
              {/* tablet + desktop */}
              <div className="-mx-6 hidden flex-wrap justify-end text-white sm:flex">
                {nav &&
                  nav.map(item => {
                    const { uid, type, link } = item;
                    switch (type) {
                      case "smallLink":
                        return (
                          <div
                            key={uid}
                            className="mb-6 flex w-1/2 items-center px-6 text-red sm:w-1/3"
                          >
                            <AppLink to={link.link}>{link.text}</AppLink>
                          </div>
                        );
                      case "largeLink":
                        return (
                          <div
                            key={uid}
                            className="mb-6 flex w-1/2 items-center px-6 uppercase sm:w-1/3"
                          >
                            <AppLink to={link.link}>{link.text}</AppLink>
                          </div>
                        );
                      case "button":
                        return (
                          <div
                            key={uid}
                            className="mb-6 flex w-1/2 items-center px-3 sm:w-1/3"
                          >
                            <Button to={link.link}>{link.text}</Button>
                          </div>
                        );
                      default:
                        return null;
                    }
                  })}

                <div className="mb-6 flex w-full items-center px-6 sm:w-1/3">
                  <SocialLinks large />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
};

SiteFooter.defaultProps = {
  isAction: true,
};

export default SiteFooter;
