import React, { useState, useEffect } from "react";

import Cookies from "js-cookie";

const AnnouncementBanner = ({ announcement }) => {
  const [visible, setVisible] = useState(false);

  if (typeof window !== "undefined") {
    // set cookie name using env var
    const cookieName = `${process.env.GATSBY_APP_NAME}_announcement`;
    // see if user has seen this announcement yet
    // you may want to change this based on desired show/hide logic
    const cookie = Cookies.get(cookieName) === announcement;

    // DIY set the cookie
    const setCookie = () => {
      // set cookie to expire in one week
      Cookies.set(cookieName, announcement, { expires: 7 });
    };

    // set state and cookie
    const dismissAnnouncement = () => {
      setVisible(false);
      setCookie();
    };

    // load the announcement if there's no cookie!
    useEffect(() => {
      if (!cookie) {
        setVisible(true);
      }
    }, []);

    // bring in announcement
    useEffect(() => {}, [visible]);

    // show if visible
    if (visible) {
      return (
        <div className="flex items-center justify-between bg-black p-4 text-white">
          <div>{announcement}</div>
          <div className="flex-shrink-0">
            <button type="button" onClick={dismissAnnouncement}>
              <span className="sr-only">close</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
            </button>
          </div>
        </div>
      );
    }
  }
  return null;
};

AnnouncementBanner.defaultProps = {
  announcement: "It's an announcement!",
};

export default AnnouncementBanner;
