import React from "react";
import { BrushBorder } from "@atoms";
import CarouselImageWCopy from "@organisms/CarouselImageWCopy";

const PbCardCarousel = props => {
  return (
    <div className="relative z-20 bg-black px-6 py-12 sm:px-0">
      <BrushBorder color="black" position="top" />
      <CarouselImageWCopy {...props} />
      <BrushBorder color="black" position="bottom" />
    </div>
  );
};

PbCardCarousel.defaultProps = {};

export default PbCardCarousel;
