import React from "react";

import { Text, CbWrapper } from "@atoms";

const CbCopy = ({ copy, indent, type }) => {
  return (
    <CbWrapper type={type} indent={indent}>
      <Text variant="body">{copy}</Text>
    </CbWrapper>
  );
};

CbCopy.defaultProps = {};

export default CbCopy;
