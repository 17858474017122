const selectAmount = amount => {
  if (amount) {
    const amountEls = Array.from(
      document.querySelectorAll(".SelectAmount .label-amount")
    );
    if (amountEls.length) {
      try {
        amountEls
          .find(el => {
            return (
              String(parseFloat(el.getAttribute("data-amount")).toFixed(2)) ===
              String(parseFloat(amount).toFixed(2))
            );
          })
          .click();
      } catch (err) {
        try {
          document
            .querySelector(".SelectAmount .label-otheramount input")
            .focus();
          document.querySelector(
            ".SelectAmount .label-otheramount input"
          ).value = parseFloat(amount);
        } catch (_err) {
          // eslint-disable-next-line no-console
          console.log(_err);
        }
      }
    }
  }
};

export default selectAmount;
