import resolveImages from "./resolveImages";
import resolveImage from "./resolveImage";

const resolveCB = contentBuilder =>
  contentBuilder?.map(block => {
    if (block) {
      const { typeHandle: type } = block;
      switch (type) {
        case "heading":
          return {
            type,
            heading: block.heading,
          };
        case "subheading":
          return {
            type,
            subheading: block.subheading,
          };
        case "leadText":
          return {
            type,
            copy: block.leadText,
          };
        case "copy":
          return {
            type,
            copy: block.copy,
            indent: block.indent,
          };
        case "list":
          return {
            type,
            copy: block.listItem,
            unordered: block.unordered,
            restartCount: block.restartCount,
          };
        case "image":
          return {
            type,
            images: resolveImages(block.images),
            indent: block.indent,
          };
        case "video":
          return {
            type,
            indent: block.indent,
            videoUrl: block.videoUrl,
            caption: block.caption,
          };
        case "link":
          return {
            type,
            link: { link: block.linkLink?.url, text: block.linkText },
            indent: block.indent,
            margin: block.removeTopMargin ? "removeTop" : "default",
          };
        case "blockQuote":
          return {
            type,
            quote: block.quote,
            citation: block.citation,
          };
        case "button":
          return {
            type,
            link: block.linkLink?.url,
            text: block.text,
          };
        case "codeEmbed":
          return {
            type,
            code: block.embedCode,
          };
        case "sponsorGrid":
          return {
            type,
            heading: block.heading,
            descriptor: block.descriptor,
            sponsors: block.sponsors.map(s => {
              return {
                type,
                name: s.title,
                logo: resolveImage(s.backgroundImage0),
                url: s.shareUrlOverride,
              };
            }),
          };
        default:
          return null;
      }
    }
    return null;
  });

export default resolveCB;
