import React from "react";

import { Text, Button, Image } from "@atoms";

const CardCta = ({ heading, copy, link, image, small }) => {
  return (
    <div className="flex flex-wrap bg-white shadow-lg">
      <div
        className={`order-2 md:order-1 md:w-3/5  ${
          small ? "p-6 lg:p-10" : "p-3 lg:p-6"
        }`}
      >
        <div>
          <Text variant={small ? "h4" : "h3"}>{heading}</Text>
        </div>
        <div className="my-3">
          <Text variant="body--tight">{copy}</Text>
        </div>
        <div className="mt-6">
          <Button size={small ? "sm" : "default"} to={link.link}>
            {link.text}
          </Button>
        </div>
      </div>
      <div className="relative order-1 w-full md:order-2 md:ml-auto md:w-2/5">
        <div className="md:hidden">
          <Image {...image} aspectratio="8:5" />
        </div>
        <div className="absolute inset-0 hidden md:block">
          <Image {...image} fill />
        </div>
      </div>
    </div>
  );
};

CardCta.defaultProps = { small: false };

export default CardCta;
