import createAppState from "./lib/createAppState";
import layout from "./reducers/layout";
import ea from "./reducers/ea";

const reducer = {
  layout: layout.reducer,
  ea: ea.reducer,
};

const initialState = {
  layout: layout.initialState,
  ea: ea.initialState,
};

export const { AppStateProvider, AppStateConsumer, useAppState } =
  createAppState(reducer, initialState);

export default {
  AppStateProvider,
  AppStateConsumer,
  useAppState,
};
