import React from "react";
import "./src/css/index.css";
import { Default } from "@layout";
import { GdprBanner } from "@organisms";
import { AnimatePresence } from "framer-motion";
import wrapWithState from "./src/state/wrapWithState";

export const wrapRootElement = wrapWithState;

export const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props;
  return (
    <Default pageContext={pageContext}>
      <AnimatePresence mode="wait" initial={false}>
        {element}
        <GdprBanner />
      </AnimatePresence>
    </Default>
  );
};
