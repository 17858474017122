import React from "react";

import { Container, Image, Text } from "@atoms";
import { HeadingWCopy, Share } from "@molecules";

const Hero = ({ backgroundImage, heading, subheading, copy, url }) => {
  return (
    <div className="relative z-0 bg-green pb-12 pt-24 sm:pt-32 lg:pb-20">
      <div className="relative z-10">
        <Container>
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-1/2">
              <HeadingWCopy heading={heading} copy={copy} inverse />
              {subheading && subheading.length > 1 && (
                <Text variant="h4" className="mt-6 text-red">
                  {subheading}
                </Text>
              )}
              <div className="mt-3">
                <Share small url={url} />
              </div>
            </div>
            {backgroundImage && (
              <div className="mt-4 w-full md:mt-0 md:w-1/2 md:pl-12">
                <div className="shadow-xl md:-mr-12 lg:-mr-20 xl:-mr-24">
                  <Image
                    {...backgroundImage}
                    aspectratio="8:5"
                    params={{ crop: "faces" }}
                  />
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};

Hero.defaultProps = {};

export default Hero;
