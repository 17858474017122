import React from "react";

import { Image, Text, ButtonAlt } from "@atoms";
import { AppLink } from "@defaults";
import clsx from "clsx";

const CardFeaturedAction = ({
  tag,
  heading,
  title,
  date,
  copy,
  metaDescription,
  cta,
  url,
  metaImage,
  category,
  small,
}) => {
  return (
    <AppLink to={url} className="group relative block bg-white text-black">
      <div>
        {!small && tag && (
          <div
            className={`absolute left-0 top-0 z-10 mb-3 p-1 text-white ${
              category?.color ? "" : "bg-green"
            }`}
            style={{ background: category?.color || null }}
          >
            <Text variant="label--small">{category?.title || tag}</Text>
          </div>
        )}
        <Image {...metaImage} aspectratio={small ? "4:3" : "16:9"} />
      </div>
      <div className="flex flex-col p-3">
        <div className={clsx("mb-2", { "text-green": !small })}>
          <Text variant="h7">{heading || title}</Text>
        </div>
        {date && !small && (
          <div className="my-2">
            <Text variant="h7">{date}</Text>
          </div>
        )}
        {date && small && (
          <div className="my-0 text-orange">
            <Text variant="label">{date}</Text>
          </div>
        )}
        {(metaDescription || copy) && (
          <div className="my-2">
            <Text variant="body--small">{metaDescription || copy}</Text>
          </div>
        )}
        <div className="mt-2">
          <ButtonAlt size="sm" color={small ? "orange" : "green"}>
            {cta}
          </ButtonAlt>
        </div>
      </div>
    </AppLink>
  );
};

CardFeaturedAction.defaultProps = {
  tag: "Featured Action",
};

export default CardFeaturedAction;
