import React from "react";

const Icon = ({ name, className: _className, fitHeight }) => {
  if (name) {
    // eslint-disable-next-line global-require
    const Content = React.memo(require("@svg")[name] || name);
    return (
      <div
        className={`scmts-icon${fitHeight ? "--fit-height" : ""} ${_className}`}
      >
        <Content />
      </div>
    );
  }
  return null;
};

Icon.defaultProps = {
  fitHeight: false,
  className: "w-12 h-12",
};

export default Icon;
