import React from "react";

import { CbWrapper } from "@atoms";
import { AppLink } from "@defaults";

const CbCopy = ({ link, indent, margin, type }) => {
  return (
    <CbWrapper type={type} indent={indent} margin={margin}>
      <AppLink to={link.link} className="inline-flex items-center">
        {/* label */}
        <span className="underline-red leading-normal text-xs font-medium uppercase">
          {link.text}
        </span>
      </AppLink>
    </CbWrapper>
  );
};

CbCopy.defaultProps = {};

export default CbCopy;
