import React from "react";
import { Button, ButtonAlt, Container } from "@atoms";

const PbBrandStatement = ({ statement, button, altLink }) => {
  return (
    <div className="pb-20 pt-16">
      {/* the guts */}
      <Container>
        <h2 className="px-6 text-center font-stats text-xl leading-body text-green sm:text-2xl md:text-3xl lg:text-4xl">
          {statement}
        </h2>
      </Container>
      {button?.url && (
        <div className="mt-6 flex items-center justify-center">
          <Button to={button.url}>{button.text}</Button>
        </div>
      )}
      {altLink?.url && (
        <div className="mt-6 flex items-center justify-center text-black">
          <ButtonAlt to={altLink.url}>{altLink.text}</ButtonAlt>
        </div>
      )}
    </div>
  );
};

PbBrandStatement.defaultProps = {};

export default PbBrandStatement;
