import React from "react";

import { Text, CbWrapper } from "@atoms";

const CbLeadText = ({ copy, indent, type }) => {
  return (
    <CbWrapper type={type} indent={indent}>
      <Text variant="body--large">{copy}</Text>
    </CbWrapper>
  );
};

CbLeadText.defaultProps = {};

export default CbLeadText;
