// remove and reload action tag scripts
const resetAt = () => {
  // remove existing nv instance
  if ("nvtag" in window) {
    // eslint-disable-next-line no-console
    console.log("Resetting ActionTag...");
    document.documentElement.removeAttribute("class");
    delete window.nvtag;
    delete window.nvtag_callbacks;
    delete window.nvtag_plugins;
    document.getElementById("attagscript").remove();
  }
  //
  if (!document.getElementById("attagscript")) {
    // eslint-disable-next-line no-console
    console.log("Loading ActionTag...");
    const actionTag = document.createElement("script");
    actionTag.setAttribute("id", "attagscript");
    actionTag.setAttribute("src", process.env.GATSBY_AT_JS);
    document.body.appendChild(actionTag);
  }
};

export default resetAt;
