const selectFreq = freq => {
  const freqEls = Array.from(
    document.querySelectorAll(".at-recurring .label-amount input")
  );
  const freqNum = freq === "annual" ? 6 : 4;
  if (freqEls.length) {
    try {
      freqEls
        .find(el => {
          return el.getAttribute("value") === String(freqNum);
        })
        .click();
    } catch (err) {
      // do nothing
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }
};

export default selectFreq;
