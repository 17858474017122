import React from "react";

import { Text, Icon, Button } from "@atoms";

const CardIconLarge = ({ icon, heading, copy1, copy2, copy3, link }) => {
  return (
    <div className="bg-white  p-3 shadow-xl lg:p-6">
      <div className="-mx-3 flex flex-wrap text-center">
        <div className="flex w-full flex-col items-center px-3 md:w-1/3">
          <div>
            {/* TODO: fix fill on icons once approved */}
            <Icon
              name={icon}
              className="h-16 w-16 text-transparent"
              fitHeight
            />
          </div>
          <div className="my-3">
            <Text variant="h4" className="text-red">
              {heading}
            </Text>
          </div>
          <div className="my-3">
            <Text variant="body--tight" className="rich-text">
              {copy1}
            </Text>
          </div>
        </div>
        <div className="flex w-full flex-col items-center px-3 md:w-1/3">
          <div className="my-3">
            <Text variant="body--tight" className="rich-text">
              {copy2}
            </Text>
          </div>
        </div>
        <div className="flex w-full flex-col items-center px-3 md:w-1/3">
          <div className="my-3">
            <Text variant="body--tight" className="rich-text">
              {copy3}
            </Text>
          </div>
          {link && link.link && (
            <div className="mt-auto">
              <Button to={link.link}>{link.text}</Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CardIconLarge.defaultProps = {};

export default CardIconLarge;
