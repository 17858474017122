import React from "react";

import { AppLink } from "@defaults";
import { Icon, Image, Text } from "@atoms";

const VideoAudioTease = ({ condensed, url, image, date, title }) => {
  return (
    <AppLink
      to={url}
      className={`group flex w-full flex-wrap text-left
      ${condensed ? "flex-col" : "-mx-3"}`}
    >
      <div className={`${!condensed ? "w-full px-3 sm:w-1/3" : "w-full"}`}>
        {image && image.url && (
          <div className="relative" style={{ paddingBottom: "56.25%" }}>
            <div className="absolute inset-0 flex items-center justify-center">
              <Image {...image} fill />
              <div className="absolute inset-0 z-20 flex transform items-center justify-center transition duration-200 group-hover:scale-105">
                <Icon
                  name="paintStrokeSmall"
                  className="stretch h-10 w-10 text-red"
                />
              </div>
              <div className="absolute inset-0 z-20 flex transform items-center justify-center transition duration-200 group-hover:scale-105">
                <Icon name="play" className="h-6 w-6 text-white" />
              </div>
            </div>
          </div>
        )}
        {(!image || !image.url) && (
          <div className="relative " style={{ paddingBottom: "56.25%" }}>
            <div className="absolute inset-0 flex items-center justify-center bg-black p-6">
              <Icon
                name="scmts-icon"
                className="h-full w-full opacity-25"
                fitHeight
              />
            </div>
            <div className="absolute inset-0 flex transform items-center justify-center transition duration-200 group-hover:scale-105">
              <Icon
                name="paintStrokeSmall"
                className="stretch h-10 w-10 text-red"
              />
            </div>
            <div className="absolute inset-0 flex transform items-center justify-center transition duration-200 group-hover:scale-105">
              <Icon name="play" className="h-6 w-6 text-white" />
            </div>
          </div>
        )}
      </div>
      <div className={`${!condensed ? "w-full px-3 sm:w-2/3" : "w-full"}`}>
        <Text variant="label" className="mb-2 mt-4">
          {date}
        </Text>
        <Text variant="body--large-tight" className="my-2 font-medium">
          {title}
        </Text>
      </div>
    </AppLink>
  );
};

VideoAudioTease.defaultProps = {};

export default VideoAudioTease;
