import React from "react";

import { Container, Image } from "@atoms";
import { HeadingWCopy } from "@molecules";

const Hero = ({ backgroundImage, heading, copy }) => {
  return (
    <div className="relative z-0 bg-white pb-6 pt-48">
      <div
        className="absolute left-0 right-0 top-0 z-0 bg-black"
        style={{ height: "150%" }}
      >
        <div className="fade-to-black absolute left-0 right-0 top-0 z-10 h-48 rotate-180 transform opacity-50" />
        <div className="fade-to-cream absolute bottom-0 left-0 right-0 z-10 h-48" />
        <div className="absolute inset-0 z-0 opacity-40">
          {backgroundImage && (
            <Image {...backgroundImage} params={{ exp: "-7", con: 5 }} fill />
          )}
        </div>
      </div>
      <div className="relative z-10">
        <Container>
          <div className="mb-6 max-w-xl">
            <HeadingWCopy heading={heading} copy={copy} inverse />
          </div>
        </Container>
      </div>
    </div>
  );
};

Hero.defaultProps = {};

export default Hero;
