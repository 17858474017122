import React from "react";

import clsx from "clsx";
import { Container, Image, Button, ButtonAlt } from "@atoms";
import HeadingWCopy from "./HeadingWCopy";

const PbImageWCopy = ({
  heading,
  copy,
  image,
  prevBlock,
  prevBlockCount,
  button,
  altLink,
}) => {
  return (
    <Container className="mb-6 md:mb-12">
      <div className="flex flex-wrap md:flex-nowrap md:gap-12">
        <div
          className={clsx("min-h-1/3-screen w-full shadow-xl md:w-1/2", {
            "md:order-2": prevBlockCount % 2 === 1,
            "-mt-6": prevBlock === "none",
          })}
        >
          <Image {...image} fill />
        </div>
        <div
          className={clsx("w-full py-6 md:w-1/2 md:pb-0", {
            "md:order-1": prevBlockCount % 2 === 1,
          })}
        >
          <HeadingWCopy heading={heading} copy={copy} small green />
          {button?.url && (
            <div className="mt-6 flex items-center">
              <Button to={button.url}>{button.text}</Button>
            </div>
          )}
          {altLink?.url && (
            <div className="mt-6 flex items-center text-black">
              <ButtonAlt to={altLink.url}>{altLink.text}</ButtonAlt>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

PbImageWCopy.defaultProps = {};

export default PbImageWCopy;
