import React from "react";

import { BrushBorder, Container, Button } from "@atoms";
import HeadingWCopy from "./HeadingWCopy";
import Carousel from "./Carousel";
import CardTeamMember from "./CardTeamMember";

const PbTeamCarousel = ({ heading, descriptor, teamMembers, button }) => {
  return (
    <div className="relative z-20 bg-green-secondary py-20">
      <BrushBorder position="top" color="green-secondary" />
      <Container variant="xs" className="mb-6">
        <HeadingWCopy
          heading={heading}
          copy={descriptor}
          inverse
          small
          centered
        />
      </Container>
      <div className="relative w-full px-12">
        <Carousel showMultiple maxVisible={3} gradient="green-secondary">
          {teamMembers.map((m, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className="px-3">
              <CardTeamMember {...m} />
            </div>
          ))}
        </Carousel>
      </div>
      {button?.url && (
        <div className="mt-6 flex items-center justify-center">
          <Button to={button.url}>{button.text}</Button>
        </div>
      )}
      <BrushBorder position="bottom" color="green-secondary" />
    </div>
  );
};

PbTeamCarousel.defaultProps = {};

export default PbTeamCarousel;
