import React from "react";
import clsx from "clsx";
import { AppLink } from "@defaults";
import { Text, ButtonAlt } from "@atoms";
import { motion } from "framer-motion";

const sublinkVariants = {
  show: {
    marginTop: "0.5rem",
    paddingTop: "1rem",
    paddingBottom: "0.5rem",
    height: "auto",
    opacity: 1,
    borderTop: "2px solid",
  },
  hide: {
    borderTop: 0,
    marginTop: 0,
    paddingTop: 0,
    paddingBottom: 0,
    height: 0,
    opacity: 0,
  },
};

const Sublinks = ({ links }) => (
  <motion.ul
    initial="hide"
    animate="show"
    variants={sublinkVariants}
    exit="hide"
    className="relative z-20 grid w-full grid-cols-4 gap-4 border-white text-sm lg:grid-cols-5 lg:gap-6"
    onHove
  >
    {links.map(({ link, text, uid, subheading, descriptor, emphasize }, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <li
        key={uid}
        className={clsx({
          "font-sans font-medium uppercase": subheading,
          "font-normal": !subheading,
          "mt-2 lg:mt-4": !emphasize,
          "col-span-2": emphasize,
        })}
      >
        <AppLink
          className={clsx(
            "group flex flex-col gap-2 text-left transition duration-200",
            {
              "relative col-span-2 row-span-2 bg-brown p-2 hover:bg-orange lg:p-4":
                emphasize,
              "hover:text-orange": !emphasize,
            }
          )}
          to={link}
        >
          {emphasize && (
            <Text className="font-sans text-lg uppercase">{text}</Text>
          )}
          {!emphasize && <Text variant="label--large">{text}</Text>}
          {descriptor && (
            <Text
              richText={false}
              className={clsx("leading-tight leading-tight -mb-0.5", {
                "text-sm": !emphasize,
                "text-label-lg": emphasize,
              })}
            >
              {descriptor}
            </Text>
          )}
          {emphasize && (
            <ButtonAlt className="font-sans text-lg uppercase">
              Learn more
            </ButtonAlt>
          )}
        </AppLink>
      </li>
    ))}
  </motion.ul>
);

export default Sublinks;
