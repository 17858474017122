import React from "react";

import Text from "./Text";

const CbWrapper = ({ indent: _indent, count, children, margin, type }) => {
  let indent = _indent;
  if (count) {
    indent = true;
  }
  const marginValues = {
    removeTop: "mb-4 -mt-2",
    topOnly: "mt-4",
    bottomOnly: "mb-4",
    default: "my-6",
  };
  return (
    <div
      className={`content-builder__${type} relative ${indent ? "pl-3" : ""} ${
        marginValues[margin]
      }`}
    >
      {count && typeof count === "number" && (
        <div className="absolute left-0 top-0 w-3 font-medium text-orange">
          <Text variant="body--small">{`${count}.`} </Text>
        </div>
      )}
      {count && count === "unordered" && (
        <div className="absolute left-0 top-0 flex h-3 w-2 items-center justify-center font-medium text-orange">
          <div className="h-1 w-1 rounded-full bg-orange" />
        </div>
      )}
      {children}
    </div>
  );
};

CbWrapper.defaultProps = {
  margin: "default",
  indent: false,
};

export default CbWrapper;
