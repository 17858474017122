import React from "react";

import { CbWrapper } from "@atoms";

const CbCodeEmbed = ({ type, code }) => {
  return (
    <CbWrapper type={type}>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: code }} />
    </CbWrapper>
  );
};

CbCodeEmbed.defaultProps = {};

export default CbCodeEmbed;
