import { useState, useEffect, useRef } from "react";
import isTouchDevice from "./isTouchDevice";

const hasWindow = typeof window !== "undefined";

const getSize = () => {
  if (hasWindow && isTouchDevice) {
    return {
      innerHeight: hasWindow
        ? // eslint-disable-next-line no-restricted-globals
          screen?.width ||
          document.documentElement.clientWidth ||
          window.innerHeight
        : 0,
      innerWidth: hasWindow
        ? // eslint-disable-next-line no-restricted-globals
          screen?.width ||
          document.documentElement.clientHeight ||
          window.innerWidth
        : 0,
      outerHeight: hasWindow ? window.outerHeight : 0,
      outerWidth: hasWindow ? window.outerWidth : 0,
    };
  }
  return {
    innerHeight: hasWindow ? window.innerHeight : 0,
    innerWidth: hasWindow ? window.innerWidth : 0,
    outerHeight: hasWindow ? window.outerHeight : 0,
    outerWidth: hasWindow ? window.outerWidth : 0,
  };
};

export function useWindowSize() {
  const ticking = useRef();
  const [windowSize, setWindowSize] = useState(getSize());

  const handleResize = () => {
    if (!hasWindow) {
      return false;
    }

    if (!ticking.current) {
      window.requestAnimationFrame(() => {
        setWindowSize(getSize());
        ticking.current = false;
      });

      ticking.current = true;
    }

    return true;
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export default useWindowSize;
