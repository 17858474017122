import resolveImage from "./resolveImage";

const resolveTrail = trail => {
  const {
    title,
    boolean0,
    metaTitle,
    metaImage,
    metaDescription,
    heading0,
    otherInfoIcon,
    url,
  } = {
    ...trail,
  };
  let infoIcon = "info";
  switch (otherInfoIcon) {
    case "x":
      infoIcon = "close";
      break;
    case "c":
      infoIcon = "checkMark";
      break;
    default:
      break;
  }
  return {
    url,
    closed: boolean0,
    title,
    info: heading0,
    infoIcon,
    meta: {
      title: metaTitle || title,
      description: metaDescription,
      image: resolveImage(metaImage),
    },
  };
};

export default resolveTrail;
