const gtmEvent = ({ event_name, ...rest }) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "gtm_event",
    event_name,
    ...rest,
  });
};

export default gtmEvent;
