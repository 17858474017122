import React from "react";

import { BrushBorder, Container } from "@atoms";
import { CardExpanded } from "@molecules";

const ActionList = ({ actions }) => {
  return (
    <div className="bg-black py-10">
      <BrushBorder color="black" position="top" />
      <div className="relative z-10">
        <Container>
          <ul>
            {actions &&
              actions.map(a => (
                <li key={a.uid} className="my-12">
                  <CardExpanded {...a} alt />
                </li>
              ))}
          </ul>
        </Container>
      </div>
      <BrushBorder color="black" position="bottom" />
    </div>
  );
};

ActionList.defaultProps = {};

export default ActionList;
