import React from "react";

import { AppLink } from "@defaults";

const Button = ({
  color,
  to,
  title,
  type,
  onClick,
  linkObj: LinkObj,
  children,
  fullWidth,
  size,
}) => {
  const colors = {
    green: "bg-green",
    orange: "bg-orange",
    blue: "bg-blue",
    brown: "bg-brown",
    red: "bg-red",
    white: "bg-white hover:bg-orange",
    gray: "bg-gray",
    black: "bg-black",
  };
  let classes = `inline-flex font-medium tracking-button font-sans justify-center items-center cursor-pointer items-center relative z-0 ${
    colors[color]
  } ${
    color === "white"
      ? "text-green hover:text-white"
      : "text-white hover:opacity-75"
  }  transition duration-200 uppercase group leading-heading rounded-full`;

  // set size
  switch (size) {
    case "fluid":
      classes += " p-2 text-xs";
      break;
    case "xxs":
      classes += " p-1 text-xxs";
      break;
    case "xs":
      classes += " px-3 py-1 text-xxs";
      break;
    case "sm":
      classes += " px-3 py-2  text-label";
      break;
    case "xs-social":
      classes += " p-2 w-6 h-6 text-label";
      break;
    case "sm-social":
      classes += " p-2 w-8 h-8 text-label";
      break;
    case "md":
      classes += " px-4 py-3  text-xs";
      break;
    case "lg":
      classes += " px-6 py-3 text-sm";
      break;
    default:
      classes += " px-8 py-5  text-sm";
      break;
  }

  if (fullWidth) {
    classes += " w-full";
  }

  if (to && to.length > 1) {
    return (
      <LinkObj to={to} title={title} className={`${classes}`} onClick={onClick}>
        <div className="relative z-10 flex items-center justify-center text-center">
          {children}
        </div>
      </LinkObj>
    );
  }
  return (
    // eslint-disable-next-line react/button-has-type
    <button type={type} className={`${classes}`} onClick={onClick}>
      <div className="relative z-10 flex items-center justify-center text-center">
        {children}
      </div>
    </button>
  );
};

Button.defaultProps = {
  linkObj: AppLink,
  color: "orange",
  title: null,
  type: "button",
  onClick: null,
  size: "sm",
  to: null,
};

export default Button;
