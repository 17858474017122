import React from "react";

import { Button, Container, Text, AnimateIn } from "@atoms";

const CovidWarning = ({ heading, copy, button }) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 z-50">
      <div className="relative w-full">
        <AnimateIn preset="fadeUpFast">
          <div className="py-3 md:py-6">
            <div className="absolute inset-0 z-0 bg-green opacity-95" />
            <Container>
              <div className="relative z-10 -mx-2 flex flex-wrap items-center text-sm leading-body md:flex-nowrap">
                <div className="px-2">
                  <div className="float-left -mt-px inline pr-1 font-sans text-sm font-medium">
                    <Text variant="label">{heading}</Text>
                  </div>
                  <div className="pt-px">
                    <Text variant="body--small">{copy}</Text>
                  </div>
                </div>
                <div className="flex-shrink-0 p-2">
                  <Button to={button.link}>{button.text}</Button>
                </div>
              </div>
            </Container>
          </div>
        </AnimateIn>
      </div>
    </div>
  );
};

CovidWarning.defaultProps = {};

export default CovidWarning;
