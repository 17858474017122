import React from "react";

import { Text, CbWrapper } from "@atoms";

const CbSubheading = ({ subheading, indent, type }) => {
  return (
    <CbWrapper type={type} indent={indent}>
      <Text variant="h7">{subheading}</Text>
    </CbWrapper>
  );
};

CbSubheading.defaultProps = {};

export default CbSubheading;
