import React, { useState, useEffect, useRef } from "react";
import { useAppState } from "@state";

import { Icon, Text, Container, Button } from "@atoms";
import { AppLink } from "@defaults";
import gsap from "gsap";

const SiteNav = ({ data, open, openState: _openState }) => {
  const { heading, copy, secondaryNav, button, primaryNav } = data;
  // eslint-disable-next-line no-empty-pattern
  const [{ layout }, dispatch] = useAppState();
  const { theme, isTransitioning } = layout;

  // the component itself
  const [prevTheme, setPrevTheme] = useState(theme);
  const [, setOpenState] = _openState;

  const tl = useRef();
  const navEl = useRef();
  // set up timeline
  useEffect(() => {
    tl.current = gsap
      .timeline()
      .pause()
      .set(navEl.current, { display: "none" })
      .set(navEl.current, { display: "block" })
      .set(".SiteNav--animate", { opacity: 0, y: -20 })
      .fromTo(
        navEl.current,
        { opacity: 0, y: -100 },
        {
          opacity: 1,
          y: 0,
          duration: 0.25,
          ease: "power1.in",
        },
        0.01
      )
      .fromTo(
        ".SiteNav--animate",
        { opacity: 0, y: -20 },
        {
          opacity: 1,
          y: 0,
          ease: "Power1.in",
          duration: 0.125,
          stagger: 0.05,
        },
        0.2
      );
    return () => {
      tl.current.kill();
    };
  }, []);

  useEffect(() => {
    if (!open) {
      setPrevTheme(theme);
    }
  }, [theme]);

  // whethere there are any activist codes
  useEffect(() => {
    if (open) {
      tl.current.play();
      if (prevTheme === "light") {
        dispatch({ type: "setThemeDark" });
      }
    } else {
      tl.current.reverse();
      if (prevTheme === "light") {
        dispatch({ type: "setThemeLight" });
      }
    }
  }, [open]);

  useEffect(() => {
    if (isTransitioning) {
      setOpenState(false);
    }
  }, [isTransitioning]);

  return (
    <div
      ref={navEl}
      className="absolute left-0 right-0 top-0 z-50 hidden min-h-1/2-screen p-12 text-white"
    >
      <div className="absolute inset-0 bg-black opacity-95" />
      <Container>
        <div className="relative">
          <div className="-mx-3 flex flex-wrap pr-8 sm:-mx-6 md:pr-0 lg:-mx-12">
            <div className="SiteNav--animate absolute right-0 top-0 flex h-8 w-8 items-center justify-center">
              <button
                type="button"
                onClick={() => {
                  setOpenState(false);
                }}
                className="cursor-pointer text-white transition duration-200 hover:text-orange"
              >
                <span className="sr-only">close</span>
                <Icon name="close" className="h-6 w-6" />
              </button>
            </div>
            <div className="w-full px-3 md:w-2/5 md:px-6 lg:px-12">
              <div className="SiteNav--animate">
                <Text variant="h3" richText={false}>
                  {heading}
                </Text>
                {copy && (
                  <Text variant="body" className="mt-3">
                    {copy}
                  </Text>
                )}
              </div>
              {primaryNav && (
                <div className="SiteNav--animate mt-12 font-medium uppercase tracking-1 text-red">
                  {primaryNav.map(link => {
                    return (
                      <div key={link.uid} className="mb-4">
                        <AppLink
                          to={link?.link}
                          className="leading-tight py-1 font-medium uppercase text-white underline decoration-transparent decoration-2 transition duration-200 hover:decoration-current"
                        >
                          {link?.text}
                        </AppLink>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {button && (
              <div className="my-4 block w-full px-6 md:my-0 md:hidden md:w-3/5 lg:px-12">
                <Button to={button?.link}>{button?.text}</Button>
              </div>
            )}
            <div className="my-4 w-full px-3 md:my-0 md:w-3/5 lg:px-12">
              <ul className="-mx-3 flex flex-wrap md:pt-12">
                {secondaryNav &&
                  secondaryNav.map((link, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={i} className="mb-6 w-full px-3 sm:w-1/2">
                      <AppLink
                        to={link?.link}
                        className="leading-tight font-sans text-h7 uppercase text-orange underline decoration-transparent decoration-2 transition duration-200 hover:text-white hover:decoration-current"
                      >
                        {link?.text}
                      </AppLink>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

SiteNav.defaultProps = {
  open: false,
};

export default SiteNav;
