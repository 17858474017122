import React from "react";

import { Image, Button } from "@atoms";
import { HeadingWCopy } from "@molecules";

const Contact = ({ image, heading, copy, button, inverse }) => {
  return (
    <>
      <div
        className={`flex items-center ${inverse ? "text-white" : "text-black"}`}
      >
        <div className="flex w-full items-center justify-center px-2 sm:w-1/2">
          <div className="w-full flex-shrink-0 p-6 sm:w-auto">
            {image && (
              <div className="mx-auto h-48 w-48 overflow-hidden rounded-full md:h-56 md:w-56">
                <Image
                  {...image}
                  params={{ fit: "facearea", facepad: 3, faceindex: 1 }}
                  aspectratio="1:1"
                  round
                />
              </div>
            )}
          </div>
        </div>
        <div className="w-full px-2 sm:w-1/2">
          <HeadingWCopy small inverse={inverse} heading={heading} copy={copy} />
        </div>
      </div>
      {button && (
        <div className="my-12 flex items-center justify-center">
          <Button to={button.link}>{button.text}</Button>
        </div>
      )}
    </>
  );
};

Contact.defaultProps = {};

export default Contact;
