import React from "react";

import clsx from "clsx";
import { Container, Image } from "@atoms";
import { AppLink } from "@defaults";
import HeadingWCopy from "./HeadingWCopy";

const PbSponsorGrid = ({ heading, descriptor, sponsors, contentBuilder }) => {
  return (
    <div className="py-12 text-black">
      <Container variant="xs" className="mb-6">
        <HeadingWCopy
          heading={heading}
          copy={descriptor}
          small
          green
          centered
        />
      </Container>
      <Container>
        <ul
          className={clsx(
            "-mx-1 mt-12 flex flex-wrap items-center  justify-center",
            { "px-3": !contentBuilder }
          )}
        >
          {sponsors.map((s, i) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              className={clsx(
                "relative inset-0 mb-6 flex w-1/2 flex-col items-center justify-start px-1 text-center xxs:w-full",
                {
                  "h-16 px-3": s.logo.width * 0.75 > s.logo.height,
                  "h-24 px-6": s.logo.width * 0.75 <= s.logo.height,
                  "sm:w-1/3 md:w-1/4 lg:w-1/5": !contentBuilder,
                  "sm:w-1/3 xl:w-1/4": contentBuilder,
                }
              )}
            >
              <AppLink
                to={s.url}
                className="group flex h-full w-full transform items-center p-1 transition duration-200 hover:scale-105"
              >
                <div className="relative h-full w-full">
                  <Image
                    {...s.logo}
                    className="absolute inset-0 flex h-full w-full items-center"
                    placeholder={false}
                    fitHeight
                  />
                </div>
              </AppLink>
            </li>
          ))}
        </ul>
      </Container>
    </div>
  );
};

PbSponsorGrid.defaultProps = {
  contentBuilder: false,
};

export default PbSponsorGrid;
