import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview } from "@dataResolvers";
import resolveImage from "../dataResolvers/resolveImage";

const query = graphql`
  query BrandQuery {
    craft {
      globalSet(handle: "brand", site: "scmts") {
        ... on Craft_brand_GlobalSet {
          logoDark
          logoLight
          backgroundTexture {
            url
            width
            height
          }
        }
      }
    }
  }
`;

const previewQuery = `
query HeaderNavQuery {
  globalSet(handle: "header", site: $site) {
    ... on header_GlobalSet {
      name
      navHeading
      navCopy
      navCampaigns {
        ... on navCampaigns_campaign_BlockType {
          enabled
          campaign {
            ... on issue_issue_Entry {
              enabled
              heading0
              slug
            }
          }
        }
      }
      secondaryNav {
        ... on secondaryNav_navLink_BlockType {
          enabled
          navLink
          linkText 
        }
      }
    }
  }
}
`;

const dataResolver = ({ craft }) => {
  const { logoDark, logoLight, backgroundTexture } = craft.globalSet;
  return {
    // heading: navHeading,
    // copy: navCopy,
    // button: {
    //   link: headerButton.url,
    //   text: headerButtonLabel,
    // },
    logoDark,
    logoLight,
    backgroundTexture: resolveImage(backgroundTexture),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
