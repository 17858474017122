import React from "react";

import { Text, CbWrapper, Image } from "@atoms";
import Carousel from "./Carousel";

const CbImage = ({ images, indent, type }) => {
  if (images?.length > 1) {
    return (
      <CbWrapper type={type} indent={indent}>
        <Carousel showMultiple={false}>
          {images.map((image, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className="shadow-xl">
              <Image {...image} />
            </div>
          ))}
        </Carousel>
        {images[0].caption && (
          <Text variant="caption">{images[0].caption}</Text>
        )}
      </CbWrapper>
    );
  }
  if (images?.length === 1) {
    return (
      <CbWrapper type={type} indent={indent}>
        <div className="shadow-xl">
          <Image {...images[0]} />
        </div>
        {images[0].caption && (
          <Text variant="caption">{images[0].caption}</Text>
        )}
      </CbWrapper>
    );
  }
  return null;
};

CbImage.defaultProps = {};

export default CbImage;
