import React from "react";

import {
  CbBlockQuote,
  CbCopy,
  CbHeading,
  CbImage,
  CbLeadText,
  CbLink,
  CbList,
  CbSubheading,
  CbVideo,
  CbButton,
  CbCodeEmbed,
  PbSponsorGrid,
} from "@molecules";

const ContentBuilder = ({ blocks }) => {
  let listCount = 0;
  return (
    <div className="content-builder">
      {blocks &&
        blocks?.map(block => {
          if (block) {
            switch (block.type) {
              case "blockQuote":
                return <CbBlockQuote {...block} />;
              case "copy":
                return <CbCopy {...block} />;
              case "heading":
                return <CbHeading {...block} />;
              case "image":
                return <CbImage {...block} />;
              case "leadText":
                return <CbLeadText {...block} />;
              case "link":
                return <CbLink {...block} />;
              case "button":
                return <CbButton {...block} />;
              case "codeEmbed":
                return <CbCodeEmbed {...block} />;
              case "list":
                if (block.restarCount) {
                  listCount = 0;
                }
                if (!block.unordered) {
                  listCount += 1;
                }
                return <CbList {...block} count={listCount} />;
              case "subheading":
                return <CbSubheading {...block} />;
              case "video":
                return <CbVideo {...block} />;
              case "sponsorGrid":
                return <PbSponsorGrid {...block} contentBuilder />;
              default:
                return null;
            }
          }
          return null;
        })}
    </div>
  );
};

ContentBuilder.defaultProps = {};

export default ContentBuilder;
