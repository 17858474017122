import React from "react";

import { Image, Container, Button, ButtonAlt, Text } from "@atoms";
import { HeadingWCopy, CardFeaturedAction } from "@molecules";
import { AppLink } from "@defaults";

const HeroHome = ({
  backgroundImage,
  heading,
  copy,
  button,
  buttonAlt,
  featuredAction,
  featuredEntry,
  cards,
}) => {
  return (
    <div className="relative z-20 flex min-h-1/3-screen flex-col bg-gray-light pt-24 text-black sm:pt-40">
      <div className="absolute inset-0 z-0 bg-white">
        <div className="fade-to-white-horz absolute inset-0 z-10 rotate-180 transform opacity-50" />
        <div className="absolute inset-0 z-0 opacity-75">
          {backgroundImage && (
            <Image
              {...backgroundImage}
              params={{
                exp: "5",
                bri: "2",
                con: "3",
                crop: "focalpoint",
                "fp-x": 0,
                "fp-y": 0,
              }}
              fill
            />
          )}
        </div>
      </div>
      <div className="relative z-10 mt-auto">
        <Container>
          <div className="-mx-3 flex flex-wrap">
            <div className="mb-6 w-full max-w-2xl px-3 md:pr-12">
              <HeadingWCopy heading={heading} copy={copy} small />
              {(button?.link || buttonAlt?.link) && (
                <div className="mt-8 flex flex-wrap items-center">
                  {button?.link && (
                    <div className="mr-6">
                      <Button to={button.link}>{button.text}</Button>
                    </div>
                  )}
                  {buttonAlt?.link && (
                    <div>
                      <ButtonAlt to={buttonAlt.link}>
                        {buttonAlt.text}
                      </ButtonAlt>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="mx-auto mt-6 w-full px-3">
              <div className="-mx-3 flex flex-wrap">
                <div className="min-h-1/3-screen w-full px-3 sm:w-3/5">
                  {featuredEntry && (
                    <AppLink
                      to={featuredEntry.url}
                      className="group relative flex h-full w-full flex-col justify-end bg-black shadow-xl"
                    >
                      <div className="fade-to-black absolute inset-0 z-10 transition duration-200 group-hover:opacity-0" />
                      <div className="absolute inset-0 z-0">
                        <Image
                          {...featuredEntry.image}
                          fill
                          params={{ fit: "max" }}
                        />
                      </div>
                      <div className="relative z-20 max-w-xs p-3 pr-6 text-left text-white lg:p-6 lg:pr-12">
                        <Text variant="h5" className="mb-3 text-white">
                          {featuredEntry.heading}
                        </Text>
                        <Text className="text-sm leading-body text-white">
                          {featuredEntry.copy}
                        </Text>
                      </div>
                    </AppLink>
                  )}
                </div>
                <div className="px-3 sm:w-2/5">
                  {featuredAction && (
                    <div className="mt-6 sm:-mt-12">
                      <CardFeaturedAction
                        {...featuredAction}
                        // tag={i === 0 ? "Featured Action" : ""}
                      />
                    </div>
                  )}
                </div>
                <div className="-mb-6 mt-6 ">
                  <ul className="grid gap-3 px-3 md:grid-cols-3 md:gap-6">
                    {cards.map((c, i) => {
                      return (
                        <li
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          className="flex w-full flex-col border-3 border-transparent bg-blue p-3 shadow-xl transition duration-200 hover:border-gray-light lg:px-6 lg:pb-6"
                          style={{ backgroundColor: c.color }}
                        >
                          <AppLink
                            className="group flex text-white md:flex-col"
                            to={c.url}
                          >
                            <div className="flex w-1/3 transform items-center justify-center transition duration-200 group-hover:scale-105 md:mb-3 md:w-full">
                              <div
                                className="scmts-icon--fit-height h-24 w-24 fill-white"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{ __html: c.svg }}
                              />
                            </div>
                            <div className="w-2/3 md:w-full">
                              <div>
                                <Text
                                  variant="h5"
                                  className="mb-3 text-left text-white md:text-center"
                                >
                                  {c.heading}
                                </Text>
                              </div>
                              <div>
                                <Text className="text-left text-sm leading-body text-white md:text-center">
                                  {c.descriptor}
                                </Text>
                              </div>
                            </div>
                          </AppLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

HeroHome.defaultProps = {};

export default HeroHome;
