import React from "react";

// import { unWidowerHtmlString } from "@designbycosmic/cosmic-unwidower";

const Text = ({
  children,
  className: _className,
  variant,
  richText,
  quotes,
}) => {
  let Tag = "div";
  let classes = "text-base";
  switch (variant) {
    case "stat":
      Tag = "div";
      classes = "font-stats text-5xl xl:text-metric leading-none";
      break;
    case "statSub":
      Tag = "div";
      classes =
        "font-serif font-medium text-2xl sm:text-4xl lg:text-5xl leading-less";
      break;
    case "h1":
      Tag = "h1";
      classes = "font-sans text-h1 leading-heading";
      break;
    case "h2":
      Tag = "h2";
      classes = "font-serif text-3xl sm:text-h2 leading-heading";
      break;
    case "h3":
      Tag = "h3";
      classes = "font-sans text-2xl sm:text-h3 leading-heading";
      break;
    case "h4":
      Tag = "h4";
      classes = "font-sans text-h4 leading-heading";
      break;
    case "h5":
      Tag = "h5";
      classes = "font-sans text-h5 leading-heading";
      break;
    case "h6":
      Tag = "h6";
      classes = "font-serif text-xl sm:text-h6 leading-heading";
      break;
    case "h7":
      Tag = "h6";
      classes = "font-sans text-h7 leading-heading";
      break;
    case "xl":
      classes = "text-xl font-sans";
      break;
    case "lg":
      classes = "text-lg font-sans";
      break;
    case "body":
      classes = "text-body text-base leading-body font-serif";
      break;
    case "blockquote":
      classes = "text-base font-medium leading-body font-serif";
      break;
    case "base":
      classes = "text-base font-sans";
      break;
    case "sm":
      classes = "text-sm font-sans";
      break;
    case "xs":
      classes = "text-xs font-sans";
      break;
    case "xxs":
      classes = "text-xxs font-sans";
      break;
    case "label--large":
      classes =
        "text-label-lg leading-heading font-sans tracking-0.5 uppercase font-medium";
      break;
    case "label":
      classes =
        "text-label leading-heading font-sans tracking-0.5 uppercase font-medium";
      break;
    case "label--small":
      classes =
        "text-label-sm leading-heading font-sans tracking-0.5 uppercase font-medium";
      break;
    case "body--small":
      classes = "text-sm leading-body font-serif";
      break;
    case "body--xs":
      classes = "text-xs leading-body font-serif";
      break;
    case "body--large":
      classes = "text-lg sm:text-xl font-serif";
      break;
    case "body--large-tight":
      classes = "text-lg sm:text-xl leading-heading font-serif";
      break;
    case "body--xl-tight":
      classes = "text-xl sm:text-1-5xl leading-heading font-serif";
      break;
    case "body--2xl-tight":
      classes = "text-xl sm:text-2xl leading-heading font-serif";
      break;
    case "body--small-tight":
      classes = "text-sm leading-heading font-sans";
      break;
    case "caption":
      classes = "mt-4 text-sm leading-heading italic text-gray";
      break;
    default:
    // do nothing
  }
  return React.Children.toArray(children).map((child, i) => {
    // const components = [RichText, Phone];
    // if children are not components (html or string), then render the html
    if (typeof child.type === "undefined") {
      return (
        <Tag
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={`
            ${classes} 
            ${_className} 
            ${!richText ? "" : "rich-text"} 
            ${quotes ? "quotes" : ""} 
            `}
          dangerouslySetInnerHTML={{
            __html: child,
          }}
        />
      );
    }
    // otherwise return children
    return (
      <Tag
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        className={`
          ${classes} 
          ${_className} 
          ${!richText ? "" : "rich-text"} 
          ${quotes ? "quotes" : ""} 
        `}
      >
        {child}
      </Tag>
    );
  });
};

Text.defaultProps = {
  tag: "div",
  className: null,
  variant: null,
  richText: true,
  quotes: false,
  children: null,
};

export default Text;
