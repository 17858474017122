import React from "react";

import clsx from "clsx";
import { HeadingWCopy, CardProject, Carousel } from "@molecules";
import { Container } from "@atoms";

const CurrentProjects = ({ heading, descriptor, projects }) => {
  return (
    <>
      <Container variant="xs">
        <HeadingWCopy
          heading={heading}
          copy={descriptor}
          inverse
          small
          centered
        />
      </Container>
      <div className="my-6">
        <Container>
          {projects.length > 3 && (
            <div className={clsx("-mx-3", {})}>
              <Carousel showMultiple maxVisible={3}>
                {projects.map((p, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={i} className="px-3">
                    <CardProject {...p} />
                  </div>
                ))}
              </Carousel>
            </div>
          )}
          {projects.length <= 3 && (
            <div className="my-6 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
              {projects.map((p, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <CardProject key={i} {...p} />
              ))}
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

CurrentProjects.defaultProps = {};

export default CurrentProjects;
