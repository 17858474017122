import React from "react";

import {
  CbBlockQuote,
  CbCopy,
  CbHeading,
  CbImage,
  CbLeadText,
  CbLink,
  CbList,
  CbSubheading,
  CbVideo,
  CbButton,
  CbCodeEmbed,
  PbFeaturedEntryCarousel,
  PbFeaturedArticles,
  PbPreDonateForm,
  PbSponsorCarousel,
  PbImageWCopy,
  PbBrandStatement,
  PbStats,
  PbTeamCarousel,
  PbPeopleList,
  PbSponsorGrid,
  PbCardCarousel,
  PbCta,
} from "@molecules";
import { Container } from "@atoms";

const PageBuilder = ({ blocks }) => {
  let listCount = 0;
  return (
    <div className="content-builder">
      {blocks &&
        blocks?.map((block, i) => {
          if (block && block.type) {
            const { uid, type } = block;

            switch (type) {
              case "blockQuote":
                return (
                  <Container
                    key={uid}
                    className="my-12 text-black"
                    variant="sm"
                  >
                    <CbBlockQuote {...block} />
                  </Container>
                );
              case "copy":
                return (
                  <Container
                    key={uid}
                    className="my-12 text-black"
                    variant="sm"
                  >
                    <CbCopy {...block} />
                  </Container>
                );
              case "heading":
                return (
                  <Container
                    key={uid}
                    className="my-12 text-black"
                    variant="sm"
                  >
                    <CbHeading {...block} />
                  </Container>
                );
              case "image":
                return (
                  <Container
                    key={uid}
                    className="my-12 text-black"
                    variant="sm"
                  >
                    <CbImage {...block} />
                  </Container>
                );
              case "leadText":
                return (
                  <Container
                    key={uid}
                    className="my-12 text-black"
                    variant="sm"
                  >
                    <CbLeadText {...block} />
                  </Container>
                );
              case "link":
                return (
                  <Container
                    key={uid}
                    className="my-12 text-black"
                    variant="sm"
                  >
                    <CbLink {...block} />
                  </Container>
                );
              case "button":
                return (
                  <Container
                    key={uid}
                    className="my-12 text-black"
                    variant="sm"
                  >
                    <CbButton {...block} />
                  </Container>
                );
              case "codeEmbed":
                return (
                  <Container
                    key={uid}
                    className="my-12 text-black"
                    variant="sm"
                  >
                    <CbCodeEmbed {...block} />
                  </Container>
                );
              case "list":
                if (block.restarCount) {
                  listCount = 0;
                }
                if (!block.unordered) {
                  listCount += 1;
                }
                return (
                  <Container
                    key={uid}
                    className="my-12 text-black"
                    variant="sm"
                  >
                    <CbList {...block} count={listCount} />
                  </Container>
                );
              case "subheading":
                return (
                  <Container
                    key={uid}
                    className="my-12 text-black"
                    variant="sm"
                  >
                    <CbSubheading {...block} />
                  </Container>
                );
              case "video":
                return (
                  <Container
                    key={uid}
                    className="my-12 text-black"
                    variant="sm"
                  >
                    <CbVideo {...block} />
                  </Container>
                );
              case "featuredEntryCarousel":
                return <PbFeaturedEntryCarousel key={uid} {...block} />;
              case "featuredArticles":
                return <PbFeaturedArticles key={uid} {...block} />;
              case "preDonateForm":
                return (
                  <PbPreDonateForm
                    key={uid}
                    {...block}
                    lastBlock={i === blocks.length - 1}
                  />
                );
              case "sponsorCarousel":
                return <PbSponsorCarousel key={uid} {...block} />;
              case "imageWCopy":
                return <PbImageWCopy key={uid} {...block} />;
              case "brandStatement":
                return <PbBrandStatement key={uid} {...block} />;
              case "stats":
                return <PbStats key={uid} {...block} />;
              case "teamCarousel":
                return <PbTeamCarousel key={uid} {...block} />;
              case "peopleList":
                return <PbPeopleList key={uid} {...block} />;
              case "sponsorGrid":
                return <PbSponsorGrid key={uid} {...block} />;
              case "cardCarousel":
                return <PbCardCarousel key={uid} {...block} />;
              case "cta":
                return <PbCta key={uid} {...block} />;
              default:
                return (
                  <div
                    key={uid}
                    className="border-b-2 border-white bg-red p-6 text-white"
                  >
                    {block.type}
                  </div>
                );
            }
          }
          return null;
        })}
    </div>
  );
};

PageBuilder.defaultProps = {};

export default PageBuilder;
