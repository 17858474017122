import React from "react";
import { Image, Text } from "@atoms";

const CardTeamMember = ({ title, image, name, bio }) => {
  return (
    <div className="flex flex-col items-center justify-start text-center">
      <div className="relative w-full" style={{ paddingBottom: "100%" }}>
        <div className="absolute inset-0">
          <Image
            {...image}
            params={{ fit: "facearea", facepad: 10 }}
            aspectratio="1:1"
            fill
          />
        </div>
      </div>
      <Text variant="lg" className="mt-4 font-sans uppercase">
        {name}
      </Text>
      <Text variant="label" className="my-2 text-orange">
        {title}
      </Text>
      <Text variant="body--small">{bio}</Text>
    </div>
  );
};

CardTeamMember.defaultProps = {};

export default CardTeamMember;
