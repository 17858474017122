import React, { useState } from "react";

import clsx from "clsx";
import { Container, Button, Text } from "@atoms";

const PbPreDonateForm = ({
  heading,
  descriptor,
  amounts,
  cta,
  url,
  showOtherAmount,
  lastBlock,
}) => {
  const [otherAmount, setOtherAmount] = useState(0);
  return (
    <div className={clsx("relative z-20 -mt-6", { "pb-12": lastBlock })}>
      <Container variant="xs">
        <div className="bg-green p-6">
          <Text variant="h4" className="text-center">
            {heading}
          </Text>
          <Text variant="body" className="mx-auto my-6 max-w-sm text-center">
            {descriptor}
          </Text>
          <div className="mx-auto flex w-full items-center justify-center">
            <ul className="mx-auto mt-6 flex max-w-sm flex-wrap justify-center sm:-mx-3">
              {amounts?.map((amount, i) => (
                <li
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  className="mb-6 w-full px-3 sm:w-1/2 md:w-1/3 lg:w-1/4"
                >
                  <Button color="white" to={`${url}?am=${amount}`} fullWidth>
                    ${amount}
                  </Button>
                </li>
              ))}
              {showOtherAmount && (
                <li className="relative mb-6 w-full px-3 sm:w-1/2 md:w-1/3 lg:w-1/4">
                  <span className="absolute bottom-0 left-0 top-0 flex items-center pl-2 font-sans text-sm font-medium text-green">
                    $
                  </span>
                  <input
                    className="h-full w-full rounded-full bg-white px-3 pl-5 pr-2 font-sans text-sm font-medium text-green placeholder-gray"
                    placeholder="0.00"
                    type="number"
                    onChange={e => {
                      setOtherAmount(parseFloat(e.target.value).toFixed(2));
                    }}
                  />
                </li>
              )}
            </ul>
          </div>
          <div className="flex items-center justify-center">
            <Button to={`${url}?am=${otherAmount}`}>{cta}</Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

PbPreDonateForm.defaultProps = {};

export default PbPreDonateForm;
