import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview, resolveImage } from "@dataResolvers";

const query = graphql`
  query DefaultsQuery {
    craft {
      globalSet(handle: "defaults") {
        ... on Craft_defaults_GlobalSet {
          heading0
          heading1
          heading2
          copy0
          copy1
          metaTitle
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          backgroundImage0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          backgroundImage1 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          upNext {
            ... on Craft_upNext_navItem_BlockType {
              uid
              entry(site: "*") {
                url
                title
              }
              image {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              descriptor
              heading
            }
            ... on Craft_upNext_navItemExternal_BlockType {
              uid
              image {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              descriptor
              heading
              linkUrl {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const previewQuery = ``;

const dataResolver = ({ craft }) => {
  const {
    heading0,
    url,
    copy0,
    heading1,
    copy1,
    heading2,
    metaTitle,
    metaImage,
    // actionDefaultCtaText,
    backgroundImage0,
    backgroundImage1,
  } = craft.globalSet;

  return {
    url,
    action: {
      thankYouHeading: heading0,
      thankYouCopy: copy0,
      shareCta: heading2,
      thankYouBackgroundImage: resolveImage(backgroundImage0),
      thankYouContentImage: resolveImage(backgroundImage1),
      upNextHeading: heading1,
      upNextDescriptor: copy1,
    },
    meta: {
      image: resolveImage(metaImage),
      siteTitle: metaTitle,
    },
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
