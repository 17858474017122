import React, { useEffect, useState } from "react";

import { Icon, Text, Button } from "@atoms";
import { useAppState } from "@state";

const Share = ({
  small,
  cta,
  formid,
  trackingId: _trackingId,
  shareUrlOverride,
  url: _url,
}) => {
  const [{ ea }, dispatch] = useAppState();
  const { shareData: _shareData } = ea;
  const shareData = _shareData?.shareData?.[formid];

  // setup component states
  const [trackingId, setTrackingId] = useState(
    _trackingId || shareData?.trackingId || false
  );
  const [twitterText, setTwitterText] = useState(
    shareData?.twitterText || false
  );
  const appid = process.env.GATSBY_FACEBOOK_APP_ID
    ? `app_id=${process.env.GATSBY_FACEBOOK_APP_ID}&`
    : "";
  const [url] = useState(shareUrlOverride || _url);
  const [urls, setUrls] = useState({
    Twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      `${url}${trackingId ? `?ms=tw_share&snw=2&ref=${trackingId}` : ""}`
    )}`,
    Facebook: `https://www.facebook.com/dialog/feed?${appid}link=${encodeURIComponent(
      `${url}${trackingId ? `?ms=fb_share&snw=1&ref=${trackingId}` : ""}`
    )}`,
  });

  const handleClick = (e, social) => {
    if (_trackingId || trackingId) {
      // e.preventDefault();
      fetch(`https://actions.everyaction.com/v1/RecruiterActivity/${formid}`, {
        method: "POST",
        body: JSON.stringify({
          SocialNetwork: social,
          SubmissionTrackingId: _trackingId || trackingId,
        }),
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
        return true;
      });
    }
  };

  // when tracking id changes, load new trackingid + twitter text
  useEffect(() => {
    const timeout = setTimeout(() => {
      // get new data if any
      const twitterShare = document.getElementById("twShareBtn");

      // if there is already data from the app state, use it
      if (twitterText && trackingId) {
        // set tracking id, twitter text, and url from state
        setUrls({
          Twitter: `https://twitter.com/intent/tweet?text=${twitterText}&url=${encodeURIComponent(
            `${url}?ms=tw_share&snw=2&ref=${trackingId}`
          )}`,
          Facebook: `https://www.facebook.com/dialog/feed?${appid}link=${encodeURIComponent(
            `${url}?ms=fb_share&snw=1&ref=${trackingId}`
          )}`,
        });
        return;
      }

      // if there is new data
      if (twitterShare && _trackingId) {
        // get + set twitter text, tracking id
        const _twitterText = twitterShare.getAttribute("data-text");
        setTwitterText(_twitterText);
        setTrackingId(_trackingId);
        dispatch({
          type: "addShareData",
          formid,
          data: {
            twitterText: _twitterText,
            trackingId: _trackingId,
          },
        });
        setUrls({
          Twitter: `https://twitter.com/intent/tweet?text=${_twitterText}&url=${encodeURIComponent(
            `${url}?ms=tw_share&snw=2&ref=${_trackingId}`
          )}`,
          Facebook: `https://www.facebook.com/dialog/feed?${appid}&link=${encodeURIComponent(
            `${url}?ms=fb_share&snw=1&ref=${_trackingId}`
          )}`,
        });
      }
      // else just share the url
      else {
        // just shre the urls if no text or tracking id to share
        setUrls({
          Twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            url
          )}`,
          Facebook: `https://www.facebook.com/dialog/feed?${appid}&link=${encodeURIComponent(
            url
          )}`,
        });
      }
    }, 250);
    // cleanup
    return () => {
      clearTimeout(timeout);
    };
  }, [_trackingId, trackingId, twitterText]);

  // // if there's a formid and trackingId, track shares
  // useEffect(() => {
  //   if (formid && (_trackingId || trackingId)) {

  //   }
  // }, [formid, trackingId]);

  return (
    <>
      {cta && cta.length > 1 && (
        <div className="mb-3">
          <Text
            variant={small ? "body--tight" : "body--large-tight"}
            className="font-medium"
          >
            {cta}
          </Text>
        </div>
      )}
      <div className="-mx-2 flex items-center">
        <Text variant={small ? "h7" : "h3"} className="-mt-px px-2">
          {cta || "Share:"}
        </Text>
        <div className="px-1">
          <Button
            size="xxs"
            to={urls.Twitter}
            target="_blank"
            onClick={e => handleClick(e, "Twitter")}
          >
            <Icon
              name="twitter"
              className={small ? "h-3 w-3 p-px" : "h-6 w-6 p-1"}
              fitHeight
            />
          </Button>
        </div>
        <div className="px-1">
          <Button
            size="xxs"
            to={urls.Facebook}
            target="_blank"
            onClick={e => handleClick(e, "Facebook")}
          >
            <Icon
              name="facebook"
              className={small ? "h-3 w-3 p-px" : "h-6 w-6 p-1"}
              fitHeight
            />
          </Button>
        </div>
      </div>
    </>
  );
};

Share.defaultProps = {};

export default Share;
