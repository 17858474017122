import React, { useState, useRef, useEffect } from "react";

import gsap from "gsap";
import { arrow as Arrow } from "@svg";
// import { uid } from "react-uid";

const Dropdown = ({ model, options, placeholder, inverse, size, sans }) => {
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = model;
  const dropdown = useRef();
  const tl = useRef();
  const arrow = useRef();

  const handleClickOutside = event => {
    if (dropdown.current && !dropdown.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    setIsActive(false);
  }, [value]);

  useEffect(() => {
    tl.current = gsap
      .timeline()
      .fromTo(
        dropdown.current,
        { height: 0 },
        { height: "auto", duration: 0.25, ease: "power1.in" }
      )
      .fromTo(
        arrow.current,
        { rotate: 0, y: 0 },
        { rotate: 90, y: -2, duration: 0.25, ease: "power1.in" },
        "=-0.25"
      );
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      tl.current.kill();
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (tl.current) {
      if (isActive) {
        tl.current.play();
      } else {
        tl.current.reverse();
      }
    }
  }, [isActive]);

  // eslint-disable-next-line global-require

  let iconSize;

  switch (size) {
    case "xs":
      iconSize = "1";
      break;
    case "sm":
      iconSize = "2";
      break;
    case "md":
      iconSize = "3";
      break;
    case "lg":
      iconSize = "4";
      break;
    default:
      iconSize = "3";
      break;
  }

  let bgColor = "white";
  let textColor = "black";
  let dropDownColor = "orange";

  if (inverse) {
    bgColor = "black";
    textColor = "white";
    dropDownColor = "orange";
  }

  return (
    <div
      className={`relative ${
        sans ? "font-sans text-xs font-medium uppercase" : "font-serif"
      }`}
    >
      <button
        type="button"
        onClick={() => setIsActive(!isActive)}
        className={`flex items-center text-${size} bg-${bgColor} text-${textColor} justify-between ${
          size === "xs" ? "px-3 py-1" : "px-4 py-2"
        } w-full ${sans ? "" : "border"} 
          ${inverse ? "border-orange" : "border-black"}`}
      >
        <span className="whitespace-no-wrap mr-2 text-left">
          {value != null && value !== "" ? options[value] : placeholder}
        </span>
        {/* dropdown Icon */}
        <div
          ref={arrow}
          className={`icon w-${iconSize} h-${iconSize} pl-1 text-${dropDownColor}`}
        >
          <Arrow />
        </div>
      </button>
      <div className="relative -mt-px">
        <ul
          ref={dropdown}
          className={`absolute left-0 z-40 text-${size} bg-${bgColor} text-${textColor}
           ${
             inverse
               ? "border-b border-l border-r border-orange"
               : "border-b border-l border-r border-black"
           }
           right-0 h-0 overflow-hidden
           ${isActive ? "shadow-xl" : "shadow-none"}`}
        >
          {placeholder && placeholder.length > 1 && (
            <li>
              <button
                type="button"
                className={`w-full px-4 py-2 text-${size} text-left opacity-50`}
                disabled
              >
                {placeholder}
              </button>
            </li>
          )}
          {options.map((option, i) => {
            return (
              <li key={option?.uid ? option?.uid : option + String(i)}>
                <button
                  onClick={() => setValue(i)}
                  type="button"
                  className={`w-full px-4 py-2 text-left transition duration-200 hover:bg-orange ${
                    value === i ? "opacity-50" : ""
                  }`}
                >
                  {option}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

Dropdown.defaultProps = {
  placeholder: "Select One",
  inverse: false,
  size: "md",
  blue: false,
};

export default Dropdown;
