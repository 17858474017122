import React from "react";

import { HeadingWCopy, Stat } from "@molecules";
import { Button, Container } from "@atoms";

const Stats = ({ heading, copy, button, stats, inverse }) => {
  return (
    <div>
      <Container>
        <div className="mx-auto max-w-lg">
          <HeadingWCopy
            centered
            small
            green
            heading={heading}
            copy={copy}
            inverse={inverse}
          />
        </div>
        <div className="-mx-4 my-10 flex flex-wrap justify-center">
          {stats &&
            stats.map(stat => (
              <div
                key={stat.uid}
                className="my-4 w-full px-4 sm:w-1/2 md:w-1/3"
              >
                <Stat {...stat} />
              </div>
            ))}
        </div>
        <div className="-mt-6 flex items-center justify-center">
          <Button to={button.link}>{button.text}</Button>
        </div>
      </Container>
    </div>
  );
};

Stats.defaultProps = {};

export default Stats;
