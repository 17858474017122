/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useRef } from "react";

import { Image, Text, Icon, AnimateIn } from "@atoms";
import { useWindowSize } from "@utils";
import gsap from "gsap";

const CardArticle = ({ heading, copy, image }) => {
  const [minHeight, setMinHeight] = useState();
  const [hoverOpen, setHoverOpen] = useState(false);
  const [clickOpen, setClickOpen] = useState(false);
  const { innerWidth: windowSize } = useWindowSize();
  const cardInnerRef = useRef();
  const copyRef = useRef();
  const bgRef = useRef();
  const cardRef = useRef();
  const cardHeadingRef = useRef();
  const hasCopy = copy && copy.length > 1;

  useEffect(() => {
    let minHeightTimeout;
    if (cardRef.current) {
      minHeightTimeout = setTimeout(() => {
        if (copyRef.current) {
          gsap.set(cardHeadingRef.current, {
            y: copyRef.current.clientHeight,
          });
        }
        setMinHeight(
          (image.height / image.width) * cardRef.current.clientWidth
        );
      }, 500);
    }
    return () => {
      clearTimeout(minHeightTimeout);
    };
  }, [windowSize]);

  useEffect(() => {
    const ease = "power1.inOut";
    if (copyRef.current) {
      if (clickOpen || hoverOpen) {
        // animate heading
        gsap.to(cardHeadingRef.current, {
          y: "0%",
          ease,
          duration: 0.25,
        });
        // animate copy
        gsap.to(copyRef.current, { y: "0%", ease, duration: 0.25 });
        // animate background
        gsap.to(bgRef.current, {
          opacity: 0.33,
          // filter: "blur(5px)"
        });
      }
      if (!clickOpen && !hoverOpen) {
        // animate heading
        gsap.to(cardHeadingRef.current, {
          y: copyRef.current.clientHeight,
          ease,
          duration: 0.25,
        });
        // animate copy
        gsap.to(copyRef.current, { y: "100%", ease, duration: 0.25 });
        // animate background
        gsap.to(bgRef.current, {
          opacity: 1,
          // filter: "blur(0px)"
        });
      }
    }
  }, [hoverOpen, clickOpen]);

  return (
    <div
      ref={cardRef}
      className="relative flex flex-col overflow-hidden bg-black shadow-lg"
      style={{ minHeight: `${minHeight}px` }}
      onMouseEnter={() => {
        setHoverOpen(true);
      }}
      onMouseLeave={() => {
        setHoverOpen(false);
      }}
    >
      <div ref={bgRef} className="absolute inset-0 z-0">
        <div className="absolute inset-0 z-0">
          <div className="fade-to-black absolute bottom-0 left-0 right-0 z-10 h-25" />
          <div className="absolute inset-0 z-0">
            <Image {...image} fill />
          </div>
        </div>
        <div className="fade-to-black ease absolute inset-0 z-10 opacity-30 transition duration-700" />
      </div>
      <div className="z-10 mt-auto h-full p-3 lg:p-6">
        {/* open/close icon for mobile */}
        {hasCopy && (
          <div className="absolute right-0 top-0 flex h-12 w-12 items-center justify-center">
            <Icon
              name="close"
              className={`ease absolute h-4 w-4 cursor-pointer text-white transition duration-200
              ${
                clickOpen && !hoverOpen
                  ? "opacity-50 hover:opacity-100"
                  : "opacity-0"
              } `}
              onClick={() => {
                setClickOpen(false);
              }}
            />
            <Icon
              name="info"
              className={`ease absolute h-4 w-4 cursor-pointer text-white transition duration-200
              ${
                !clickOpen && !hoverOpen
                  ? "opacity-50 hover:opacity-100"
                  : "opacity-0"
              } `}
              onClick={() => {
                setClickOpen(true);
              }}
            />
          </div>
        )}
        {/* the actual content */}
        <div ref={cardInnerRef} className="pointer-events-none pt-48">
          <AnimateIn>
            <div
              ref={cardHeadingRef}
              className={`pr-12 ${hasCopy ? "pb-3 lg:pb-6" : ""}`}
            >
              <Text variant="h4" className="font-medium text-white">
                {heading}
              </Text>
            </div>
            {hasCopy && (
              <div
                ref={copyRef}
                className="-mb-3 translate-y-full transform pb-3 lg:-mb-6 lg:-mt-3 lg:pb-6"
              >
                <Text className="font-serif font-serif leading-heading text-white">
                  {copy}
                </Text>
              </div>
            )}
          </AnimateIn>
        </div>
      </div>
    </div>
  );
};

CardArticle.defaultProps = {};

export default CardArticle;
