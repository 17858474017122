import React from "react";
import { Helmet } from "react-helmet";

import { SiteHeader, SiteAcHeader } from "@organisms";
import { useAppState } from "@state";

const DefaultLayout = ({ children }) => {
  const [{ layout }] = useAppState();
  const { theme } = layout;
  // the layout
  return (
    <div
      id="app_layout"
      className={`transition duration-500 ${
        theme === "light" ? "bg-cream" : "bg-black"
      }`}
    >
      <Helmet>
        <link rel="preload" href={process.env.GATSBY_TYPEKIT} as="style" />
        <link rel="stylesheet" href={process.env.GATSBY_TYPEKIT} />
        {/* allow social embeds */}
        <script
          async
          defer
          crossOrigin="anonymous"
          src={`https://connect.facebook.net/en_US/sdk.js#xfbml=1&autoLogAppEvents=1&version=v7.0&appId=${process.env.GATSBY_FACEBOOK_APP_ID}`}
          nonce="12UgffVI"
        />
        <script async="" src="https://platform.twitter.com/widgets.js" />
        <script async src="https://www.instagram.com/embed.js" />
      </Helmet>
      <SiteHeader />
      {/* {process.env.GATSBY_CRAFT_SITE_HANDLE === "scmtsAc" && <SiteAcHeader />} */}
      {children}
      <div id="fb-root" />
    </div>
  );
};

export default DefaultLayout;
