import React, { useEffect, useState, useRef } from "react";

import { Text, AnimateIn } from "@atoms";
import { useWindowSize } from "@utils";

const StatCard = ({ stat, heading, description, color }) => {
  const [textTooBig, setTextTooBig] = useState();
  const { innerWidth: windowSize } = useWindowSize();
  const statRef = useRef();
  const statSubRef = useRef();
  const statContainerRef = useRef();

  const colors = {
    red: {
      heading: "text-brown",
      copy: "text-white",
      background: "bg-red",
    },
    white: {
      heading: "text-red",
      copy: "text-brown",
      background: "bg-white",
    },
    brown: {
      heading: "text-red",
      copy: "text-white",
      background: "bg-black",
    },
  };
  useEffect(() => {
    if (statRef.current && statSubRef.current && statContainerRef.current) {
      setTextTooBig(
        statRef.current.clientWidth + statSubRef.current.clientWidth >
          statContainerRef.current.clientWidth
      );
    }
  }, [windowSize]);
  return (
    <div
      className={`${colors[color].background} p-3 shadow-lg lg:p-6`}
      style={{ fontcariantNumeric: "tabular-nums" }}
    >
      <AnimateIn>
        <div ref={statContainerRef} className="w-full">
          <div
            className={`flex items-end justify-start ${
              textTooBig ? "flex-wrap" : ""
            }`}
          >
            <div ref={statRef} className="inline-flex items-end">
              <Text
                variant="stat"
                className={`${colors[color].heading} font-medium sm:-mt-1 md:-mt-2 lg:-mt-4`}
              >
                {stat}
              </Text>
            </div>
            <div ref={statSubRef} className="inline-flex flex-col lg:mb-1">
              <Text
                variant="statSub"
                className={`${colors[color].heading} mb-2 mt-auto py-1 pl-1 font-medium lg:py-3 lg:pl-2`}
              >
                {heading}
              </Text>
            </div>
          </div>
        </div>
        <Text
          className={`${colors[color].copy} max-w-md pr-6 font-serif text-2xl font-medium leading-heading text-white`}
        >
          {description}
        </Text>
      </AnimateIn>
    </div>
  );
};

StatCard.defaultProps = {
  color: "brown",
};

export default StatCard;
