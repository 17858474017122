import React from "react";
import clsx from "clsx";

const BrushBorder = ({ color, position, box }) => {
  return (
    <div
      style={color.includes("#") ? { background: color } : null}
      className={clsx("absolute transition duration-200", {
        "brush-border brush-border-x w-12":
          position === "left" || position === "right",
        "brush-border-y h-12": position === "top" || position === "bottom",
        "bg-green": color === "green",
        "bg-green-secondary": color === "green-secondary",
        "bg-brown": color === "brown",
        "bg-orange": color === "orange",
        "bg-blue": color === "blue",
        "bg-red": color === "red",
        "bg-black": color === "black",
        "brush-border-x-box":
          (position === "left" || position === "right") && box,
        "brush-border-y-box":
          (position === "top" || position === "bottom") && box,
        "left-0 -translate-x-1/2": position === "left",
        "top-0 -translate-y-1/2": position === "top",
        "right-0 translate-x-1/2 rotate-180": position === "right",
        "bottom-0 translate-y-1/2 rotate-180": position === "bottom",
      })}
    />
  );
};

BrushBorder.defaultProps = {
  box: false,
};

export default BrushBorder;
