import React from "react";
import clsx from "clsx";
import { AppLink } from "@defaults";
import Icon from "./Icon";
import Text from "./Text";

// todo: fix underline

const ButtonAlt = ({
  to,
  children,
  className: _className,
  reverse,
  noIcon,
  icon,
  color,
  onClick,
  size,
}) => {
  return (
    <AppLink
      to={to}
      onClick={onClick}
      className={`group inline-flex items-center font-sans font-medium ${_className}`}
    >
      {/* back arrow */}
      {!noIcon && reverse && (
        <span
          className={`scmts-icon h-3 text-${color} flex-shink-0 -mb-px mr-1 mt-px w-3 transform p-px pr-1 transition duration-200 group-hover:-translate-x-1 ${
            icon ? "" : "rotate-180 transform"
          }`}
        >
          <Icon name={icon || "arrow"} />
        </span>
      )}
      {/* label */}
      <span className="relative">
        <Text
          variant={size === "sm" ? "label--small" : "label"}
          className={clsx(
            "leading-normal uppercase leading-metric tracking-button",
            { "text-label-lg": size === "lg" }
          )}
        >
          {children}
        </Text>
        {color?.includes("#") && (
          <div
            className="absolute bottom-0 left-0 right-0 translate-y-1/2 transform border-b-2"
            style={{ borderColor: color }}
          />
        )}
      </span>
      {/* forward arrow */}
      {!noIcon && !reverse && (
        <span
          className={`scmts-icon h-3 text-${color} flex-shink-0 -mb-px ml-1 mt-px w-3 transform p-px pl-1 transition duration-200 group-hover:translate-x-1`}
        >
          <Icon name={icon || "arrow"} />
        </span>
      )}
    </AppLink>
  );
};

ButtonAlt.defaultProps = {
  reverse: false,
  noIcon: false,
  color: "orange",
  size: "md",
};

export default ButtonAlt;
