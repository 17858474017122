import pluralize from "pluralize";
import resolveImages from "./resolveImages";
import resolveArticle from "./resolveArticle";
import resolveImage from "./resolveImage";

const resolvePB = contentBuilder => {
  let prevBlock = "none";
  let previousBlock = "none";
  let prevBlockCount = 0;
  return contentBuilder?.map(block => {
    if (block) {
      const { type, uid } = block;
      switch (type) {
        case "heading":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            heading: block.heading,
          };
        case "subheading":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            subheading: block.subheading,
          };
        case "leadText":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            copy: block.leadText,
          };
        case "copy":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            copy: block.copy,
            indent: block.indent,
          };
        case "list":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            copy: block.listItem,
            unordered: block.unordered,
            restartCount: block.restartCount,
          };
        case "image":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            images: resolveImages(block.images),
            indent: block.indent,
          };
        case "video":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            indent: block.indent,
            videoUrl: block.videoUrl,
            caption: block.caption,
          };
        case "link":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            link: { link: block.linkLink?.url, text: block.linkText },
            indent: block.indent,
            margin: block.removeTopMargin ? "removeTop" : "default",
          };
        case "blockQuote":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            quote: block.quote,
            citation: block.citation,
          };
        case "button":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            link: block.linkLink?.url,
            text: block.text,
          };
        case "codeEmbed":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            code: block.embedCode,
          };
        case "featuredEntryCarousel":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            color: block.color,
            showUpcomingEvents: block.showUpcomingEvents,
            entries: block.selectEntriesToFeatured.map(entry => {
              return {
                uid: entry.uid,
                tag: entry.type,
                type: pluralize.singular(entry.type),
                heading: entry.metaTitle || entry.title,
                subheading: null, // TODO: resolve this on entry by entry basis
                descriptor: entry.metaDescription,
                image: resolveImage(entry.metaImage || entry.backgroundImage),
                url: entry.url,
                cta: entry.cta || "Read More",
              };
            }),
            button: block.button,
          };
        case "featuredArticles":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            heading: block.heading,
            descriptor: block.descriptor,
            articles: block.featuredArticles.map(a => resolveArticle(a)),
            button: block.readMoreLink,
          };
        case "preDonateForm":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            heading: block.heading,
            descriptor: block.descriptor,
            amounts: block.amounts
              ?.filter(a => a.amount?.length)
              ?.map(a => a.amount),
            cta: block.cta,
            url: block.donatePage?.[0]?.url,
            showOtherAmount: block.showOtherAmount,
          };
        case "sponsorCarousel":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            heading: block.heading,
            descriptor: block.descriptor,
            sponsors: block.sponsors.map(s => {
              return {
                uid,
                type,
                name: s.title,
                logo: resolveImage(s.backgroundImage0),
                url: s.shareUrlOverride,
              };
            }),
          };
        case "imageWCopy":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            heading: block.heading,
            copy: block.copy,
            image: resolveImage(block.image),
            button: block.button,
            altLink: block.altlink,
          };
        case "brandStatement":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            statement: block.statement,
            button: block.button,
            altLink: block.altLink,
          };
        case "stats":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            heading: block.heading,
            descriptor: block.descriptor,
            stats: block.stats,
          };
        case "cta":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            heading: block.heading,
            copy: block.descriptor,
            button: block.button,
            secondaryLink: block.altLink,
            backgroundImage: resolveImage(block.backgroundImage),
          };
        case "teamCarousel":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            heading: block.heading,
            descriptor: block.descriptor,
            teamMembers: block.teamMembers.map(m => {
              return {
                title: m.heading0,
                image: resolveImage(m.backgroundImage0),
                name: m.title,
                bio: m.copy0,
              };
            }),
            button: block.button,
          };
        case "peopleList":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            heading: block.heading,
            descriptor: block.descriptor,
            people: block.people,
          };
        case "sponsorGrid":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            heading: block.heading,
            descriptor: block.descriptor,
            sponsors: block.sponsors.map(s => {
              return {
                uid,
                type,
                name: s.title,
                logo: resolveImage(s.backgroundImage0),
                url: s.shareUrlOverride,
              };
            }),
          };
        case "cardCarousel":
          previousBlock = prevBlock;
          prevBlock = type;
          if (previousBlock === prevBlock) {
            prevBlockCount += 1;
          } else {
            prevBlockCount = 0;
          }
          return {
            prevBlock: previousBlock,
            prevBlockCount,
            uid,
            type,
            heading: block.heading,
            descriptor: block.descriptor,
            items: block.cards.map(card => {
              return {
                uid: card.uid,
                heading: card.heading,
                copy: card.copy,
                image: resolveImage(card.image),
              };
            }),
            button: block.button,
          };
        default:
          return null;
      }
    }
    return null;
  });
};

export default resolvePB;
