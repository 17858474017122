import React from "react";

import { Image, Container, Button, ButtonAlt } from "@atoms";
import { ActionShort, HeadingWCopy, Share } from "@molecules";

const Cta = ({
  heading,
  copy,
  backgroundImage,
  action,
  secondaryLink,
  button,
  url,
  showAction: _showAction,
}) => {
  const showAction = action?.actionType === "signUp" && _showAction;

  return (
    <div
      className={`relative text-white ${
        showAction ? "pt-8 sm:py-12" : "bg-black py-6"
      }`}
    >
      {showAction && process.env.GATSBY_AC && (
        <div className="absolute inset-0 bg-green opacity-90" />
      )}
      {showAction && !process.env.GATSBY_AC && (
        <div className="absolute inset-0 bg-blue opacity-90" />
      )}
      {!showAction && (
        <div className="absolute inset-0 z-0 bg-black opacity-60">
          {backgroundImage && (
            <Image
              {...backgroundImage}
              params={{ exp: "-10", con: 5, fit: "max" }}
              fill
              // fit="cover"
            />
          )}
        </div>
      )}
      <div className="relative">
        {/* <AnimateIn> */}
        <Container>
          <div className="-mx-3 flex flex-shrink-0 flex-wrap lg:flex-nowrap">
            <div
              className={`${
                showAction ? "w-full pr-6 md:w-1/2" : "w-full lg:w-1/2"
              } my-6 max-w-xl px-3`}
            >
              <HeadingWCopy
                heading={
                  action && action?.heading?.length
                    ? action.heading
                    : heading || heading
                }
                copy={
                  action?.meta?.description?.length || action?.copy?.length
                    ? action?.meta?.description || action.copy
                    : copy || copy
                }
                className="mb-4"
                inverse
                small
              />
            </div>
            <div
              className={`-mt-6 flex flex-col px-3 lg:my-0 lg:ml-auto lg:justify-center
                ${showAction ? "w-full md:w-1/2" : "w-full lg:w-2/5"}`}
            >
              {/* TODO: Does this div need to be a button? */}
              {showAction && action && (
                <div to={action.url} className="my-3 w-full">
                  <div className="md:-mx-6">
                    <div className="md:-mx-3">
                      <ActionShort {...action} url={action.url} />
                    </div>
                  </div>
                </div>
              )}
              {/* TODO: Does this div need to be a button? */}
              {showAction && !action && (
                <div className="my-3 w-full">
                  <Share url={url} />
                </div>
              )}
              <div className="flex flex-wrap items-center lg:ml-auto">
                {(button?.url || (!showAction && action !== null)) && (
                  <Button to={button?.url || action?.url} className="my-3">
                    {button?.text || action?.cta || "Take Action"}
                  </Button>
                )}
                {secondaryLink &&
                  (secondaryLink?.link || secondaryLink?.url) && (
                    <div className="m-3">
                      <ButtonAlt to={secondaryLink.link || secondaryLink?.url}>
                        {secondaryLink.text}
                      </ButtonAlt>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </Container>
        {/* </AnimateIn> */}
      </div>
    </div>
  );
};

Cta.defaultProps = {
  showAction: false,
  link: null,
  action: null,
};

export default Cta;
