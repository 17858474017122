import React from "react";

import { Icon, Text } from "@atoms";

const HeadingWCopy = ({
  icon,
  heading,
  copy,
  className: _className,
  small,
  subtitle,
  inverse,
  centered,
  green,
}) => {
  return (
    <div
      className={`relative font-sans ${_className}
      ${inverse ? "text-white" : "text-black"}
      ${centered ? "text-center" : ""}`}
    >
      {icon && (
        <Icon
          name={icon}
          className="text-red-light absolute left-0 top-0 z-0 -ml-12 -mt-8 h-20 w-20 opacity-70"
        />
      )}
      <div className="relative z-10">
        <Text
          variant={small ? "h3" : "h1"}
          className={green ? "text-green" : null}
        >
          {heading}
        </Text>
        {subtitle && (
          <Text variant="label" className="mt-4">
            {subtitle}
          </Text>
        )}
        {copy && (
          <Text variant="body" className="mt-4 font-normal">
            {copy}
          </Text>
        )}
      </div>
    </div>
  );
};

HeadingWCopy.defaultProps = {
  centered: false,
};

export default HeadingWCopy;
