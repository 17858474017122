import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview } from "@dataResolvers";

const query = graphql`
  query GdprQuery {
    craft {
      globalSet(handle: "gdpr") {
        ... on Craft_gdpr_GlobalSet {
          enableGdpr
          gdprOptInButton
          gdprOptInCopy
          gdprPlaceholder
        }
      }
    }
  }
`;

const previewQuery = `
  query GdprQuery {
    globalSet(handle: "gdpr", site: $site) {
      ... on gdpr_GlobalSet {
        enableGdpr
        gdprOptInButton
        gdprOptInCopy
        gdprPlaceholder
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { enableGdpr, gdprOptInButton, gdprOptInCopy, gdprPlaceholder } =
    craft.globalSet;
  return {
    enabled: enableGdpr,
    cta: gdprOptInButton,
    copy: gdprOptInCopy,
    placeholder: gdprPlaceholder,
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
