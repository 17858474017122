import React from "react";

import { Text, CbWrapper } from "@atoms";

const CbHeading = ({ heading, indent, type }) => {
  return (
    <CbWrapper type={type} indent={indent}>
      <Text variant="h4">{heading}</Text>
    </CbWrapper>
  );
};

CbHeading.defaultProps = {};

export default CbHeading;
