import React, { useEffect, useState, useRef } from "react";

import { Container, Text, Icon, Button, ButtonAlt } from "@atoms";
import MiniSearch from "minisearch";
import gsap from "gsap";

const SearchModal = ({
  placeholder,
  content,
  resultCard: ResultCard,
  openState,
  param,
  backCopy,
}) => {
  const [results, setResults] = useState([]);
  const [query, setQuery] = useState("");
  const [modalOpen, setModalOpen] = openState || useState(false);
  const [visibleResults, setVisibleResults] = useState(12);
  const searchInput = useRef();
  const tl = useRef();
  const modal = useRef();

  const miniSearch = new MiniSearch({
    fields: Object.keys(content[0]), // fields to index for full-text search
    // storeFields: ["title", "category"], // fields to return with search results
    searchOptions: {
      fuzzy: 0.2,
    },
  });

  miniSearch.addAll(content);

  useEffect(() => {
    if (query && query.length) {
      const _results = miniSearch.search(query);
      setResults(_results.map(r => content.find(c => c.id === r.id)));
    } else {
      setResults(content);
    }
  }, [query]);

  useEffect(() => {
    tl.current = gsap
      .timeline()
      .pause()
      .set(modal.current, { display: "none" })
      .set(modal.current, { display: "flex" })
      .fromTo(modal.current, { opacity: 0 }, { opacity: 1, duration: 0.2 })
      .fromTo(
        modal.current.children,
        { opacity: 0, y: 40 },
        { opacity: 1, duration: 0.2, stagger: 0.1, y: 0 }
      );
    if (window) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const q = urlParams.get(param);
      if (q) {
        setModalOpen(true);
        setQuery(q);
        searchInput.current.value = q;
      }
    }
    return () => {
      tl.current.kill();
    };
  }, []);

  useEffect(() => {
    if (modalOpen) {
      tl.current.play();
    } else {
      tl.current.reverse();
    }
  }, [modalOpen]);

  return (
    <div
      ref={modal}
      className="fixed inset-0 z-60 hidden items-start justify-center p-4 opacity-0 sm:p-6 md:p-10 lg:p-12 xl:p-24"
    >
      <button
        type="button"
        className="bg-black-alphalight absolute inset-0 z-0 block h-full w-full text-transparent"
        onClick={() => setModalOpen(false)}
      >
        close
      </button>
      <div className="absolute right-0 top-0 p-3 sm:hidden">
        <button
          type="button"
          className="text-gray"
          onClick={() => setModalOpen(false)}
        >
          <span className="sr-only">close</span>
          <Icon name="close" className="h-4 w-4" />
        </button>
      </div>
      <div className="relative z-10 mx-auto h-full w-full overflow-y-scroll opacity-0">
        <Container variant="copy">
          <div className="relative w-full bg-gray-light p-4 sm:p-6 md:p-10 lg:p-12">
            <div className="absolute right-0 top-0 hidden p-3 sm:block">
              <button
                type="button"
                className="text-gray"
                onClick={() => setModalOpen(false)}
              >
                <span className="sr-only">close</span>
                <Icon name="close" className="h-4 w-4" />
              </button>
            </div>
            <div className="relative">
              <input
                ref={searchInput}
                type="text"
                className="w-full bg-white p-2 font-serif font-medium text-black placeholder-gray"
                placeholder={placeholder}
                onChange={e => {
                  setQuery(e.target.value);
                }}
              />
              <div className="absolute bottom-0 right-0 top-0 flex items-center justify-center text-gray">
                <Icon name="search" className="h-8 w-8 p-2 " />
              </div>
            </div>
            <ul className="mt-6 w-full md:mt-12">
              {(!results || results.length === 0) && query.length >= 3 && (
                <Text variant="body--tight">
                  Your search return no results. Please try refining your query
                  and try again.
                </Text>
              )}
              {query.length === 0 && (
                <Text variant="body--tight">
                  Enter a search query above to filter results.
                </Text>
              )}
              {results &&
                // results.length &&
                results.slice(0, visibleResults).map(r => (
                  <li
                    key={`search-result-${r.id}`}
                    className="my-8 w-full text-left"
                  >
                    <ResultCard {...r} />
                  </li>
                ))}
              <div
                className={`mt-12 flex items-center justify-center ${
                  visibleResults >= results.length ? "hidden" : ""
                }`}
              >
                <Button
                  onClick={() => {
                    setVisibleResults(visibleResults + 6);
                  }}
                >
                  Load More
                </Button>
              </div>
              {backCopy && (
                <div className="mt-6 flex items-center justify-center">
                  <ButtonAlt
                    onClick={() => {
                      setModalOpen(false);
                    }}
                    reverse
                  >
                    {backCopy}
                  </ButtonAlt>
                </div>
              )}
            </ul>
          </div>
        </Container>
      </div>
    </div>
  );
};

SearchModal.defaultProps = {
  placeholder: "Search",
  param: "q",
};

export default SearchModal;
