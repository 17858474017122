import React from "react";

import { Text, CbWrapper, Video } from "@atoms";

const CbVideo = ({ videoUrl, caption, indent, type }) => {
  return (
    <CbWrapper type={type} indent={indent}>
      <div className="shadow-xl">
        <Video play={false} url={videoUrl} />
      </div>
      {caption && <Text variant="caption">{caption}</Text>}
    </CbWrapper>
  );
};

CbVideo.defaultProps = {};

export default CbVideo;
