/* eslint-disable prefer-destructuring */
import React from "react";

import { Link as GatsbyLink } from "gatsby";
import { useAppState } from "@state";
import psl from "psl";

const extractHostname = url => {
  let hostname;
  // find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf("//") > -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }

  // find & remove port number
  hostname = hostname.split(":")[0];
  // find & remove "?"
  hostname = hostname.split("?")[0];

  return hostname;
};

const AppLink = ({
  to: _url,
  title,
  className: _className,
  children,
  onClick,
  activeClassName,
  style,
}) => {
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useAppState();

  if (_url && typeof _url === "string") {
    const siteUrl = process.env.GATSBY_SITE_URL;
    let url = _url.replace(siteUrl, "");

    const handleClick = e => {
      if (onClick) {
        onClick(e);
      }
      // Trigger page transition
      dispatch({
        type: "startTransition",
        to: url,
      });
    };

    // handle internal links
    // usually internal router and/or transition
    if (
      url.includes(siteUrl) ||
      (!url.includes("https://") &&
        !url.includes("http://") &&
        !url.includes("mailto:") &&
        !url.includes("tel:"))
    ) {
      // Add a preceding slash to any relative urls
      if (url.indexOf("http") === -1 && url.charAt(0) !== "/") {
        url = `/${url}`;
      }
      return (
        <GatsbyLink
          className={`
            cursor-pointer 
            ${_className} 
            ${
              typeof window !== "undefined" && window.location.pathname === url
                ? "pointer-events-none"
                : ""
            }
          `}
          // activeStyle={{ cursor: "default" }}
          activeClassName={activeClassName}
          partiallyActive
          title={title}
          name={title}
          to={url}
          onClick={handleClick}
          style={style}
        >
          {children}
        </GatsbyLink>
      );
    }
    if (url.includes(psl.get(extractHostname(siteUrl)))) {
      return (
        <a
          href={url || ""}
          title={title}
          name={title}
          className={`${_className || ""} cursor-pointer`}
          onClick={onClick}
          style={style}
        >
          {children}
        </a>
      );
    }
    // handle external links
    // usually open in new tab, noreferer, noopener
    return (
      <a
        href={url || ""}
        title={title}
        name={title}
        target="_blank"
        rel="noopener noreferrer"
        className={`${_className || ""} cursor-pointer`}
        onClick={onClick}
      >
        {children}
      </a>
    );
  }
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${_className || ""} cursor-pointer`}
    >
      {children}
    </button>
  );
};

AppLink.defaultProps = {
  to: null,
  title: null,
  className: null,
  onClick: null,
  activeClassName: null,
};

export default AppLink;
