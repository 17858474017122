import React from "react";

import { Image, Square, Text, Icon, Button, AnimateIn } from "@atoms";

const Card = ({ heading, copy, image }) => {
  return (
    <Square className="ease bg-black text-white shadow-lg transition duration-700 group-hover:shadow-xl">
      <div className="absolute inset-0 z-0">
        <div className="absolute inset-0 z-0">
          <Image
            {...image}
            params={{ gam: 25, shad: 25, sat: 10, exp: -2, con: 25 }}
            fill
          />
        </div>
        <div className="fade-to-black ease absolute inset-0 z-10 opacity-60 transition duration-700 group-hover:opacity-100" />
      </div>
      <div className="relative z-10 flex h-full flex-col p-3 lg:p-4">
        <div className="mt-auto">
          <AnimateIn>
            <div className="-mx-1 flex items-end justify-between">
              <div className="px-1">
                <Text variant="h4" className="font-serif font-medium ">
                  {heading}
                </Text>
                {copy && (
                  <Text className=" mt-2 font-serif text-lg leading-heading">
                    {copy}
                  </Text>
                )}
              </div>
              <div className="ml-auto px-1">
                <Button size="xxs">
                  <Icon
                    name="arrow"
                    className="-my-1 h-4 w-4 transform transition duration-500 ease-in-out group-hover:scale-110"
                  />
                </Button>
              </div>
            </div>
          </AnimateIn>
        </div>
      </div>
      <div />
    </Square>
  );
};

Card.defaultProps = {};

export default Card;
