import React, { useState, useEffect } from "react";

import { DropDown, BrushBorder, Text, Button, Image } from "@atoms";
import { AppLink } from "@defaults";
import HeadingWCopy from "../molecules/HeadingWCopy";

const VolunteerGrid = ({ categories, actions }) => {
  const [currentCat, setCurrentCat] = useState(categories[0] || null);
  const [catTitle, seCatTitle] = useState(0);
  useEffect(() => {
    setCurrentCat(categories[catTitle]);
  }, [catTitle]);
  return (
    <div className="p-4">
      <div
        className="relative bg-green p-2 transition duration-200 md:p-3"
        style={
          currentCat?.color ? { backgroundColor: currentCat?.color } : null
        }
      >
        <BrushBorder position="top" color={currentCat?.color || "green"} box />
        <BrushBorder position="left" color={currentCat?.color || "green"} box />
        <div className="relative z-20 -mx-4 flex flex-wrap items-center">
          <div className="w-full px-4 md:w-1/2 lg:w-2/3">
            <HeadingWCopy
              heading={currentCat.heading || currentCat.title}
              copy={currentCat.copy}
              small
              inverse
            />
          </div>
          <div className="mt-4 w-full px-4 md:mt-0 md:w-1/2 lg:w-1/3">
            <Text variant="label" className="mb-2">
              Types of Volunteer Opportunities
            </Text>
            <DropDown
              size="sm"
              placeholder={false}
              model={[catTitle, seCatTitle]}
              options={categories.map(c => c.title)}
            />
          </div>
          <div className="w-full">
            <div className="mt-6 flex flex-wrap justify-center px-1">
              {actions
                .filter(a =>
                  a.categories?.map(c => c.uid).includes(currentCat.uid)
                )
                .map((a, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={i} className="mb-6 w-full px-3 sm:w-1/3">
                    <AppLink
                      to={a.shareUrlOverride || a.url}
                      className=" group relative z-0 -m-px flex h-full flex-col items-center bg-white text-center text-black"
                    >
                      <div className="w-full">
                        <Image
                          {...(a.image || a.metaImage)}
                          aspectratio="8:5"
                        />
                      </div>
                      <div className="w-full flex-grow border-2 border-gray-light p-3 transition duration-200 group-hover:border-orange">
                        <div className="my-2">
                          <Text variant="h7" className="text-orange">
                            {a.heading || a.title}
                          </Text>
                        </div>
                        <div>
                          <Text variant="body--small">
                            {(a.descriptor || a.metaDescription).length > 90
                              ? `${(
                                  a.descriptor || a.metaDescription
                                ).substring(0, 90)}...`
                              : a.descriptor || a.metaDescription}
                          </Text>
                        </div>
                        {/* <div className="mt-2">
                                    <ButtonAlt size="sm" color="green">
                                      Read More
                                    </ButtonAlt>
                                  </div> */}
                      </div>
                    </AppLink>
                  </div>
                ))}
            </div>
          </div>
          {currentCat?.button?.link && (
            <div className="mb-6 w-full">
              <div className="mt-2 flex flex-wrap justify-center px-2">
                <Button to={currentCat.button.link}>
                  {currentCat.button.text}
                </Button>
              </div>
            </div>
          )}
        </div>
        <BrushBorder
          position="bottom"
          color={currentCat?.color || "green"}
          box
        />
        <BrushBorder
          position="right"
          color={currentCat?.color || "green"}
          box
        />
      </div>
    </div>
  );
};

VolunteerGrid.defaultProps = {};

export default VolunteerGrid;
