import React, { useState, useEffect } from "react";

import { Text, Icon, DropDown, BrushBorder } from "@atoms";
import gsap from "gsap";

const MembershipLevelsOptions = ({ levels, activeState }) => {
  const [activeLevel, setActiveLevel] = activeState || useState(1);
  useEffect(() => {
    // highlight selection
    gsap.to(`.level-${String(activeLevel)} .level__info`, {
      // x: "4rem",
      duration: 0.2,
    });
    gsap.to(`.level-${String(activeLevel)} .level__details`, {
      // delay: 0.3,
      height: "auto",
      duration: 0.2,
      onComplete() {
        // eslint-disable-next-line react/no-this-in-sfc
        this?.target?.scrollIntoView({ behavior: "smooth" });
      },
    });
    setTimeout(() => {
      // deselect unhighlighted amounts
      levels.forEach((l, i) => {
        if (i !== activeLevel) {
          gsap.to(`.level-${String(i)} .level__info`, {
            x: 0,
            duration: 0.2,
          });
          gsap.to(`.level-${String(i)} .level__details`, {
            height: 0,
            duration: 0.1,
          });
        }
      });
    }, 200);
  }, [activeLevel]);
  return (
    <>
      <div className="relative z-40 -mx-6 bg-green px-8 pb-6 pt-2 md:hidden">
        <div className="relative z-40 -my-3 pt-2">
          <DropDown
            options={levels.map(l => l.name)}
            model={[activeLevel, setActiveLevel]}
            inverse
          />
        </div>
        <BrushBorder position="bottom" color="green" box />
      </div>
      <div className="relative z-30 my-6 flex-grow flex-col text-left text-white md:mb-12 md:mt-16 md:flex">
        {levels.map((level, i) => (
          <button
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            type="button"
            className={`level level-${String(i)} ${
              i === activeLevel
                ? "flex cursor-default bg-black"
                : "hidden bg-green"
            } relative w-full p-1 py-4 text-white transition duration-200 sm:p-3 md:flex md:pb-10`}
            style={level.color ? { background: level.color } : null}
            onClick={() => {
              setActiveLevel(i);
            }}
          >
            <BrushBorder
              position="top"
              color={level.color || (activeLevel === i ? "black" : "green")}
              box
            />
            <BrushBorder
              position="left"
              color={level.color || (activeLevel === i ? "black" : "green")}
              box
            />
            <div className="level__info relative z-30 flex w-full flex-col items-center duration-200 md:flex-row md:items-start">
              <div className="flex flex-grow">
                <div className="-mt-px mr-2 flex h-8 flex-shrink-0 items-center">
                  <span
                    className={`-mt-px flex h-3 w-3 items-center justify-center rounded-full border-3 border-white transition duration-200 
                ${activeLevel === i ? "bg-orange" : ""}`}
                  >
                    {/* <Icon
                      name="checkMark"
                      className={`text-white w-2 h-2 transition duration-200 
                    ${activeLevel === i ? "opacity-100" : "opacity-0"}`}
                    /> */}
                  </span>
                </div>
                <div className="flex-grow">
                  <div className="-mx-1 flex flex-wrap items-center">
                    <div className="relative flex">
                      <div className="relative">
                        <Text variant="h5" className="text-left text-white">
                          {level.name}
                        </Text>
                        <div
                          className={`absolute bottom-0 left-0 ${
                            activeLevel === i ? "w-full" : "w-0"
                          } h-1 translate-y-full transform border-b-2 border-white opacity-100 transition-all duration-200`}
                        />
                      </div>
                    </div>
                    <div className="ml-auto px-2">
                      <Text
                        variant="body"
                        className="rich-text text-left font-sans font-medium"
                      >
                        {String(`$${level.minMonthly}+/mo`)}
                      </Text>
                    </div>
                    <div className="relative ml-2 flex items-center justify-center p-1 transition duration-200">
                      <Icon
                        name="arrow"
                        className={`absolute right-0 top-0 z-20 h-2 w-2 transform text-white transition duration-200
                ${activeLevel !== i ? "rotate-90" : "-rotate-90 opacity-50"}`}
                      />
                    </div>
                  </div>
                  <div className="level__details h-0 overflow-hidden">
                    <div className="my-3">
                      <Text variant="body" className="rich-text text-left">
                        {level.description}
                      </Text>
                      <Text
                        variant="body--small"
                        className="mt-3 text-left font-medium"
                      >
                        {level.benefitsTitle}
                      </Text>
                    </div>
                    <ul className="my-3 flex w-full flex-col items-start">
                      {level.benefits.map((b, ii) => {
                        return (
                          <li
                            key={{ ii }}
                            className="mb-1 flex w-full items-center justify-start"
                          >
                            <span className="flex h-4 w-4 flex-shrink-0 items-center justify-center">
                              <span className="block h-1 w-1 rounded-full bg-orange" />
                            </span>
                            <Text
                              variant="body--small"
                              className="rich-text text-left"
                            >
                              {b}
                            </Text>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <BrushBorder
              position="bottom"
              color={level.color || (activeLevel === i ? "black" : "green")}
              box
            />
            <BrushBorder
              position="right"
              color={level.color || (activeLevel === i ? "black" : "green")}
              box
            />
          </button>
        ))}
      </div>
    </>
  );
};

MembershipLevelsOptions.defaultProps = {};

export default MembershipLevelsOptions;
