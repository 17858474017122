import React, { useState } from "react";

import { AppLink } from "@defaults";
import { SiteNav, PrimaryNav, Sublinks } from "@molecules";
import { Icon, Container, Button, BrushBorder } from "@atoms";
import { useAppState } from "@state";
import { useHeaderData, useBrandData } from "@staticQueries";
import { splotch as Splotch } from "@svg";
import { AnimatePresence, motion } from "framer-motion";

const SiteHeader = () => {
  const [activeNavItem, setActiveNavItem] = useState(null);
  const data = useHeaderData();
  const { logoLight } = useBrandData();
  const { button, primaryNav, secondaryNav } = data;
  const [navOpen, setNavOpen] = useState(false);
  const [{ layout }] = useAppState();
  const {
    theme,
    // hideNav
  } = layout;
  const hideNav = false;
  const colors = {
    dark: {
      text: "text-white",
    },
    light: {
      text: "text-black",
    },
  };
  return (
    <>
      <SiteNav data={data} open={navOpen} openState={[navOpen, setNavOpen]} />
      <motion.header
        onHoverEnd={() => setActiveNavItem(null)}
        className={`w-full ${colors[theme].text} absolute left-0 right-0 top-0 z-40 bg-black text-sm tracking-button`}
      >
        <div className="absolute bottom-0 left-0 right-0 -translate-y-1/2">
          <BrushBorder position="bottom" color="black" />
        </div>
        <Container>
          <div className="relative z-20 -mb-6 -mr-12 hidden h-6 justify-end pt-2 lg:flex">
            <div className="absolute right-0 top-0 py-1">
              <div className="absolute inset-0 z-0 fill-brown px-6">
                <Splotch />
              </div>
              <div className="relative z-10 flex h-full items-start px-12 text-xs font-medium">
                <ul className="flex">
                  {secondaryNav &&
                    secondaryNav.map(item => {
                      return (
                        <li key={item.uid} className="px-2">
                          <AppLink to={item.link}>{item.text}</AppLink>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
          <div className="relative z-10 -mx-3 -mb-6 flex items-start lg:-mx-6">
            <div className="-mt-6 mr-auto px-3 py-4 lg:px-6">
              <AppLink
                className="relative  flex h-20 w-40 items-center py-2 font-medium"
                to={
                  process.env.GATSBY_PRIMARY_URL ||
                  "https://santacruztrails.org/"
                }
              >
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: logoLight }}
                  className="scmts-icon py-2 text-white"
                />
              </AppLink>
            </div>

            <div className="-mr-6 ml-6 hidden flex-grow flex-col pb-6 pt-8 lg:flex">
              <PrimaryNav
                links={primaryNav}
                activeNavItemState={[activeNavItem, setActiveNavItem]}
                inverse
              />
            </div>

            <div
              className={`flex items-center px-3 uppercase transition duration-200 lg:px-6 ${
                hideNav ? "pointer-events-none opacity-0" : "opacity-100"
              }`}
            >
              {button && (
                <div className="mx-4 hidden lg:block">
                  <Button to={button.link}>{button.text}</Button>
                </div>
              )}
              <div className="ml-4 lg:hidden">
                <button
                  type="button"
                  className="inline-flex items-center font-sans font-medium uppercase"
                  onClick={() => {
                    setNavOpen(!navOpen);
                  }}
                >
                  <span className="hidden lg:block">Menu</span>{" "}
                  <Icon name="bars" className="ml-2 h-8 w-8" />
                </button>
              </div>
            </div>
            {/* <div className="px-3 lg:px-6 hidden">mobile nav button</div> */}
          </div>
          <AnimatePresence>
            {activeNavItem && (
              <Sublinks
                key={activeNavItem}
                links={
                  primaryNav.find(({ uid }) => uid === activeNavItem).sublinks
                }
              />
            )}
          </AnimatePresence>
        </Container>
      </motion.header>
    </>
  );
};

SiteHeader.defaultProps = {};

export default SiteHeader;
