import React from "react";
import { Container, Text } from "@atoms";
import { Carousel } from "@molecules";
import HeadingWCopy from "./HeadingWCopy";

const PbPeopleList = ({ heading, descriptor, people }) => {
  return (
    <div className="py-12 text-black">
      <Container variant="xs" className="mb-6">
        <HeadingWCopy
          heading={heading}
          copy={descriptor}
          small
          green
          centered
        />
      </Container>
      <Container>
        <Carousel showMultiple maxVisible={3} dark>
          {people.map((p, i) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              className="mb-6 flex w-full flex-col items-center justify-start px-3 text-center lg:px-6"
            >
              <Text variant="large" className="font-sans">
                {p.name}
              </Text>
              <Text variant="label" className="mt-2 font-sans text-orange">
                {p.title}
              </Text>
              <Text variant="body--small" className="mt-2 text-left">
                {p.descriptor}
              </Text>
            </div>
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

PbPeopleList.defaultProps = {};

export default PbPeopleList;
