import React, { useRef, useEffect, useState, useCallback } from "react";

import { useWindowSize, isTouchDevice } from "@utils";
import gsap from "gsap";
import { Icon } from "@atoms";
import tailwindConfig from "@tailwind";

import InView from "@base/InView";

// todo: replace with CarouselNew

const Carousel = ({
  children,
  showMultiple,
  prevButton: PrevButtonComp,
  nextButton: NextButtonComp,
  maxVisible,
  className: _className,
  buttonPosition,
  centerItems,
  showIndicators,
  dark,
  startingSlide,
  gradient,
  color,
  adjustIndicators,
  currentSlideState,
  altLayout,
  altPosition,
  forceButtons,
}) => {
  // * set static vars
  // get screen sizes
  const { screens } = tailwindConfig.theme;

  // * touch config
  const isTouch = useRef(false);
  // determine if the device supports touch
  if (typeof window !== "undefined") {
    isTouch.current = isTouchDevice;
  } else {
    isTouch.current = false;
  }

  let options;
  // gsap animation options
  if (isTouch.current) {
    options = { ...options, duration: 0.2, ease: "expo.out" };
  } else {
    options = { duration: 0.33, ease: "power1.out" };
  }

  // * set up refs
  const prevButtonRef = useRef();
  const nextButtonRef = useRef();
  const carouselContainer = useRef();
  const carousel = useRef();
  const slideContainer = useRef();
  const componentContainer = useRef();

  // * convert children into array
  const items = React.Children.toArray(children);
  const itemIDs = React.Children.toArray(children).map((child, i) => {
    return `child-${i}`;
  });

  // * set up states
  // dimensions
  const { innerWidth: windowSize } = useWindowSize();
  const [isVisible, setIsVisible] = useState(false);
  const [currentSlide, setCurrentSlide] =
    currentSlideState || useState(startingSlide);
  const [slideWidth, setSlideWidth] = useState(0);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [loaded, setLoaded] = useState(false);

  // number of slides
  const totalSlides = React.Children.count(children);
  const initialVisible = maxVisible < totalSlides ? maxVisible : totalSlides;
  const [visibleSlides, setVisiblSlides] = useState(
    showMultiple ? initialVisible : 1
  );

  // * Carousel Layout Functions
  // calculate # of slides that are visible
  const calculateVisibleSlides = windowWidth => {
    if (showMultiple) {
      const screenNumbers = {};
      Object.keys(screens).map(screen => {
        if (typeof screens[screen] === "string") {
          screenNumbers[screen] = parseInt(
            screens[screen].replace("px", ""),
            10
          );
        }
        return true;
      });
      // configure number of slides based on screen size
      const noSlides = {
        // only use odd numbers (need a center slide)
        sm: maxVisible > 3 ? 1 : 1,
        md: maxVisible > 3 ? 3 : 3,
        lg: maxVisible > 3 ? maxVisible : 3,
        xl: maxVisible > 3 ? maxVisible : 3,
        xxl: maxVisible > 3 ? maxVisible : 3,
      };
      // match screen
      const matchedScreen = Object.keys(screenNumbers).find(screen => {
        return windowWidth < screenNumbers[screen];
      });
      // return match
      if (matchedScreen) {
        return noSlides[matchedScreen] <= maxVisible
          ? noSlides[matchedScreen]
          : maxVisible;
      }
      // else return 2
      return maxVisible;
    }
    return 1;
  };

  // calculate current carousel position
  const currentPosition = () => {
    return (
      (slideWidth * (totalSlides - visibleSlides)) / 2 +
      slideWidth * -currentSlide
    );
  };

  // * Carousel UI Functions

  // handle changing of slide
  const changeSlide = useCallback(
    (dir, slidePos) => {
      if (
        dir === "next" &&
        ((visibleSlides > 1 && slidePos < totalSlides - visibleSlides) ||
          (visibleSlides === 1 && slidePos < totalSlides - 1))
      ) {
        setCurrentSlide(slidePos + 1);
        return;
      }
      if (dir === "prev" && slidePos > 0) {
        setCurrentSlide(slidePos - 1);
      }
      // gsap.to(carousel.current, { x: currentPosition(), ...options });
    },
    [visibleSlides]
  );

  // determinie whether or not to hide buttons, then do it
  const handleChangeSlide = () => {
    const btl = gsap.timeline();
    if (
      (visibleSlides > 1 && currentSlide >= totalSlides - visibleSlides) ||
      (visibleSlides === 1 && currentSlide >= totalSlides - 1)
    ) {
      btl
        .set(nextButtonRef.current, { pointerEvents: "none" })
        .to(nextButtonRef.current, {
          duration: 0.25,
          opacity: 0,
          ease: "power1.out",
        });
      // .set(nextButtonRef.current, { display: "none" });
    } else {
      btl
        .set(nextButtonRef.current, {
          display: "block",
          pointerEvents: "auto",
        })
        .to(nextButtonRef.current, {
          duration: 0.25,
          opacity: 1,
          ease: "power1.in",
        });
    }
    if (currentSlide <= 0) {
      btl
        .set(prevButtonRef.current, { pointerEvents: "none" })
        .to(prevButtonRef.current, {
          duration: 0.25,
          opacity: 0,
          ease: "power1.out",
        });
      // .set(prevButtonRef.current, { display: "none" });
    } else {
      btl
        .set(prevButtonRef.current, {
          display: "block",
          pointerEvents: "auto",
        })
        .to(prevButtonRef.current, {
          duration: 0.25,
          opacity: 1,
          ease: "power1.in",
        });
    }
    return null;
  };

  // * Effects

  // set slide width on screen resize
  useEffect(() => {
    const newSlides = calculateVisibleSlides(windowSize);
    if (newSlides !== visibleSlides) {
      setVisiblSlides(newSlides);
    } else if (
      carouselContainer.current.clientWidth / visibleSlides !==
      slideWidth
    ) {
      setCarouselWidth(carouselContainer.current.clientWidth);
      setSlideWidth(carouselContainer.current.clientWidth / visibleSlides);
    }
  }, [windowSize, loaded, isVisible]);

  // after determining number of slides, determine width
  useEffect(() => {
    if (carouselContainer.current.clientWidth / visibleSlides !== slideWidth) {
      setCarouselWidth(carouselContainer.current.clientWidth);
      setSlideWidth(carouselContainer.current.clientWidth / visibleSlides);
    }
  }, [visibleSlides, isVisible]);

  // change carousel position after slide change
  useEffect(() => {
    // should button show or hide?
    if (loaded) {
      handleChangeSlide();
    }
    // calculate carousel position
    // calculate carousel position
    if (loaded) {
      gsap.to(carousel.current, { x: currentPosition(), ...options });
    } else {
      gsap.set(carousel.current, { x: currentPosition(), ...options });
      setLoaded(true);
    }
    // touch stuff
    const pos = { x: 0, y: 0 };
    const delta = { x: 0, y: 0 };
    const cp = currentPosition();

    const touchStart = event => {
      pos.x = event.touches[0].pageX;
      pos.y = event.touches[0].pageY;
      delta.x = 0;
      delta.y = 0;
    };

    const touchMove = event => {
      delta.x = pos.x - event.touches[0].pageX;
      delta.y = pos.y - event.touches[0].pageY;
      if (Math.abs(delta.y) < Math.abs(delta.x) * 1.1) {
        event.preventDefault();
        gsap.set(carousel.current, {
          x: -delta.x + cp,
        });
      }
      return delta;
    };
    const touchEnd = () => {
      // next slide if it moved far enough and is not last slide
      if (Math.abs(delta.y) < Math.abs(delta.x)) {
        if (
          delta.x > windowSize / 4 && // horsz swipe distance is greater than 1/3 card width
          currentSlide < totalSlides - 1 // carousel is not currently on the last slide
        ) {
          changeSlide("next", currentSlide);
          return;
        }
        // prev slide if it moved far enough and is not first slide
        if (
          delta.x < windowSize / -4 && // horz swipe distance is greater than 1/3 card width
          currentSlide > 0 // carousel is not currently on the first slide
        ) {
          changeSlide("prev", currentSlide);
          return;
        }
      }
      gsap.to(carousel.current, { x: cp, ...options });
    };
    if (typeof window !== "undefined" && isTouch.current) {
      carouselContainer.current.addEventListener(
        "touchstart",
        touchStart,
        false
      );
      carouselContainer.current.addEventListener("touchmove", touchMove, false);
      carouselContainer.current.addEventListener("touchend", touchEnd, false);
    }
    return () => {
      if (carouselContainer.current) {
        carouselContainer.current.removeEventListener("touchstart", touchStart);
        carouselContainer.current.removeEventListener("touchmove", touchMove);
        carouselContainer.current.removeEventListener("touchend", touchEnd);
      }
    };
  }, [currentSlide, slideWidth, loaded, isVisible]);

  // * Markup

  // define previous buttno
  // eslint-disable-next-line react/no-unstable-nested-components, react/display-name
  const PrevButton = React.memo(() => {
    // from prop
    if (PrevButtonComp) {
      return <PrevButtonComp />;
    }
    // default
    return (
      <div
        className="relative flex h-6 w-6 items-center justify-center overflow-hidden rounded-full bg-orange"
        style={color ? { background: color } : null}
      >
        <Icon
          name="arrow"
          className="relative z-10 flex h-2 w-2 -translate-x-px rotate-180 transform items-center justify-center text-white transition duration-200 hover:opacity-70"
        />
      </div>
    );
  });

  // define next button
  // eslint-disable-next-line react/no-unstable-nested-components, react/display-name
  const NextButton = React.memo(() => {
    // from prop
    if (NextButtonComp) {
      return <NextButtonComp />;
    }
    // default
    return (
      <div
        className="relative  flex h-6 w-6 items-center justify-center overflow-hidden rounded-full bg-orange"
        style={color ? { background: color } : null}
      >
        <Icon
          name="arrow"
          className="relative z-10 flex h-2 w-2 translate-x-px transform items-center justify-center text-white transition duration-200 hover:opacity-70"
        />
      </div>
    );
  });

  return (
    <InView onEnter={() => setIsVisible(true)} unobserveAfterEntry>
      <div
        ref={componentContainer}
        className={`relative transition duration-500
        ${_className}
        ${
          gradient
            ? "mx-0 px-4 sm:-mx-8 sm:px-8 md:-mx-16 md:px-16 lg:-mx-36 lg:px-36"
            : "-mx-2 px-2 xxs:mx-0 lg:-mx-4 lg:px-4 xl:-mx-12 xl:px-12"
        }`}
      >
        {/* prev button */}
        {(!altLayout || altPosition) && (
          <div
            className={`absolute ${
              forceButtons ? "flex" : "hidden"
            } bottom-0 left-0 top-0 flex-col items-center justify-center pl-4 sm:flex ${
              gradient ? "sm:pl-6 md:pl-12 lg:pl-24" : "xl:pl-12"
            } z-10`}
          >
            <button
              ref={prevButtonRef}
              type="button"
              className="text-blue-offwhite hidden cursor-pointer rounded-full pr-px opacity-0"
              onClick={() => changeSlide("prev", currentSlide)}
              style={{
                transform: `translateX(${
                  buttonPosition.includes("-")
                    ? buttonPosition.replace("-", "")
                    : `-${buttonPosition}`
                })`,
              }}
            >
              <span className="sr-only">previous</span>
              <PrevButton />
            </button>
            {altPosition && (
              <button
                ref={nextButtonRef}
                type="button"
                className="text-blue-offwhite mt-2 cursor-pointer rounded-full pr-px opacity-0"
                onClick={() => {
                  changeSlide("next", currentSlide);
                }}
                style={{
                  transform: `translateX(${
                    buttonPosition.includes("-")
                      ? buttonPosition.replace("-", "")
                      : `-${buttonPosition}`
                  })`,
                }}
              >
                <span className="sr-only">previous</span>
                <NextButton />
              </button>
            )}
          </div>
        )}
        {/* the actual carousel */}
        <div
          ref={carouselContainer}
          className={`card-carousel__container relative z-0 py-px
          ${showMultiple ? "overflow-visible" : "overflow-hidden"}
          `}
        >
          {gradient && (
            <div
              className={`fade-to-${gradient}-horz pointer-events-none absolute bottom-0 left-0 top-0 z-10 -translate-x-full rotate-180 transform`}
              style={{
                width: ((windowSize || 0) - carouselWidth) / 2,
              }}
            />
          )}
          <div ref={carousel} className="carousel z-0">
            <div ref={slideContainer} className="flex w-full justify-center">
              {items.map((slide, i) => {
                return (
                  <div
                    key={itemIDs[i]}
                    style={{
                      width: `${(1 / visibleSlides) * 100}%`,
                    }}
                    className={`flex flex-shrink-0 flex-grow flex-col transition duration-200
                    ${
                      i > currentSlide - 1 && i < currentSlide + visibleSlides
                        ? "opacity-100"
                        : "pointer-events-none opacity-50"
                    }
                    ${
                      !gradient &&
                      !gradient?.length &&
                      i > currentSlide - 1 &&
                      i < currentSlide + visibleSlides
                        ? "opacity-0"
                        : ""
                    }`}
                  >
                    <div
                      className={`flex flex-grow flex-col
                      ${centerItems ? "justify-center" : "justify-start"} `}
                    >
                      {slide}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {gradient && (
            <div
              className={`fade-to-${gradient}-horz pointer-events-none absolute bottom-0 right-0 top-0 z-10 translate-x-full transform`}
              style={{
                width: ((windowSize || 0) - carouselWidth) / 2,
              }}
            />
          )}
        </div>
        {/* next button */}
        {!altPosition && (
          <div
            className={`absolute ${
              forceButtons ? "flex" : "hidden"
            } bottom-0 right-0 top-0 flex flex-col items-center justify-center pr-4 sm:flex ${
              gradient ? "sm:pr-6 md:pr-12 lg:pr-24" : "xl:pr-12"
            } z-10`}
          >
            {altLayout && (
              <button
                ref={prevButtonRef}
                type="button"
                className="text-blue-offwhite mb-2 cursor-pointer rounded-full pr-px opacity-0"
                onClick={() => changeSlide("prev", currentSlide)}
                style={{ transform: `translateX(${buttonPosition})` }}
              >
                <span className="sr-only">previous</span>

                <PrevButton />
              </button>
            )}
            <button
              ref={nextButtonRef}
              type="button"
              className="text-blue-offwhite cursor-pointer rounded-full pr-px opacity-0"
              onClick={() => {
                changeSlide("next", currentSlide);
              }}
              style={{ transform: `translateX(${buttonPosition})` }}
            >
              <span className="sr-only">next</span>
              <NextButton />
            </button>
          </div>
        )}
        <div
          className={`absolute bottom-0 left-0 right-0 z-20 flex items-center justify-center
          ${showIndicators && items.length > 1 ? "" : "sm:hidden"}
          ${adjustIndicators ? "pb-10" : ""}`}
        >
          {items.map((slide, i) => {
            return (
              <button
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                type="button"
                className={`h-2 w-2 flex-shrink-0 rounded-full ${
                  dark ? "bg-black" : "bg-white"
                } mr-1 sm:mx-1 ${
                  currentSlide === i ? "opacity-100" : "opacity-50"
                }`}
                onClick={() => {
                  setCurrentSlide(i);
                }}
              >
                <span className="sr-only">{i}</span>
              </button>
            );
          })}
        </div>
      </div>
    </InView>
  );
};

Carousel.defaultProps = {
  showMultiple: false,
  prevButton: null,
  nextButton: null,
  maxVisible: 5,
  showIndicators: false,
  buttonPosition: "-50%",
  className: "",
  centerItems: false,
  currentSlideState: null,
  startingSlide: 0,
  forceButtons: false,
};

export default Carousel;
