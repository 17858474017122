import moment from "moment";
import resolveImage from "./resolveImage";
import resolveCB from "./resolveCB";

const resolveArticle = a => {
  const article = { ...a };
  const {
    uid,
    url,
    title,
    metaDescription,
    metaImage,
    metaTitle,
    postDate,
    slug,
    heading0,
    copy0,
    backgroundImage0,
    contentBuilder,
    boolean0,
    upNext,
    linkList,
    allowIndexing,
    blogCategory,
  } = article;
  const categories = {};
  if (blogCategory && blogCategory?.length) {
    blogCategory.forEach(cat => {
      categories[cat.uid] = {
        title: cat.title,
        color: cat.color0,
        uid: cat.uid,
      };
    });
  }
  return {
    uid,
    url,
    date: moment(postDate).format("MMMM D, YYYY"),
    meta: {
      title,
      metaDescription,
      description: metaDescription,
      metaImage: resolveImage(metaImage || backgroundImage0),
      image: resolveImage(metaImage || backgroundImage0),
      metaTitle,
      slug,
      url,
      allowIndexing,
    },
    slug,
    title,
    categories,
    hero: {
      heading: heading0 || title,
      copy: copy0 || metaDescription,
      date: moment(postDate).format("MMMM D, YYYY"),
      image: resolveImage(backgroundImage0 || metaImage),
    },
    contentBuilder: resolveCB(contentBuilder),
    sidebar: {
      enabled: boolean0,
      // actions:
      //   articleActions && articleActions.length ? articleActions : blogActions,
      // links: articleLinks && articleLinks.length ?
      cards: upNext
        ?.filter(item => item.heading || item?.entry?.[0]?.title)
        .map(item => {
          return {
            image:
              resolveImage(item.image) ||
              resolveImage(item?.entry?.[0]?.metaImage),
            icon: resolveImage(item.icon),
            heading: item.heading || item?.entry?.[0]?.title,
            descriptor: item.descriptor,
            url: item?.entry?.[0]?.url || item.linkUrl?.url,
            cta: item.cta || "Read More",
          };
        }),
      links: linkList?.map(item => {
        return {
          link:
            item.action && item.action.length
              ? item.action[0].url
              : item.listLink,
          text: item.linkText,
        };
      }),
    },
  };
};

export default resolveArticle;
