import React, { useRef, useEffect } from "react";

import { useAppState } from "@state";
import { Container, ButtonAlt, Text } from "@atoms";
import { useGdprData } from "@staticQueries";
import { loadGTM } from "@utils";
import gsap from "gsap";

const GdprBanner = () => {
  const { cta, copy, enabled } = useGdprData();
  const banner = useRef();
  const [{ layout }, dispatch] = useAppState();
  const { gdpr } = layout;

  useEffect(() => {
    if (gdpr || !enabled) {
      loadGTM();
    }
  }, []);

  // handle acceptGdpr
  const acceptGdpr = () => {
    loadGTM();
    // hide banner after fadeout
    gsap.to(banner.current, {
      y: "25px",
      opacity: 0,
      onComplete: () => {
        dispatch({
          type: "acceptGdpr",
        });
      },
    });
  };
  if (enabled) {
    if (typeof window !== "undefined") {
      if (!layout.gdpr) {
        return (
          <div ref={banner} className="fixed bottom-0 left-0 right-0 z-50">
            <div className="bg-black-alpha py-6 text-white">
              <Container>
                <div className="flex flex-wrap items-center justify-between text-white sm:flex-nowrap">
                  <Text variant="body--large-tight">{copy}</Text>
                  <ButtonAlt
                    type="button"
                    className="mt-4 flex-shrink-0 text-black sm:ml-2 sm:mt-0"
                    onClick={acceptGdpr}
                  >
                    {cta}
                  </ButtonAlt>
                </div>
              </Container>
            </div>
          </div>
        );
      }
    }
  }
  return null;
};

export default GdprBanner;
