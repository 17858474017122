import React from "react";

import { Image, Text, ButtonAlt } from "@atoms";
import { AppLink } from "@defaults";

const CardExpanded = ({ image, heading, copy, link, centered }) => {
  if (link?.link?.length > 3) {
    return (
      <AppLink
        to={link.link}
        className="group flex h-full w-full flex-col bg-white shadow-lg transition duration-200 hover:shadow-xl"
      >
        <div className="w-full overflow-hidden">
          <div className="transform transition duration-200 group-hover:scale-105">
            <Image {...image} aspectratio="8:5" />
          </div>
        </div>
        <div
          className={`flex flex-grow flex-col p-3 lg:p-4
        ${
          centered
            ? "items-center justify-center text-center"
            : "items-start justify-start text-left"
        } `}
        >
          <Text
            variant="body--large-tight"
            className="my-1 font-medium text-red"
          >
            {heading}
          </Text>
          <Text variant="body--tight" className="my-1">
            {copy}
          </Text>
          <div className="-mt-2">
            <ButtonAlt size="sm" noIcon>
              {link.text}
            </ButtonAlt>
          </div>
        </div>
      </AppLink>
    );
  }
  return (
    <div className="flex h-full w-full flex-col bg-white shadow-lg transition duration-200">
      <div className="w-full overflow-hidden">
        <div className="transform transition duration-200 group-hover:scale-105">
          <Image {...image} aspectratio="8:5" />
        </div>
      </div>
      <div
        className={`flex flex-grow flex-col p-3 lg:p-4
        ${
          centered
            ? "items-center justify-center text-center"
            : "items-start justify-start text-left"
        } `}
      >
        <Text variant="body--large-tight" className="my-1 font-medium text-red">
          {heading}
        </Text>
        <Text variant="body--tight" className="my-1">
          {copy}
        </Text>
        <div className="-mt-2">
          <ButtonAlt size="sm" noIcon>
            {link.text}
          </ButtonAlt>
        </div>
      </div>
    </div>
  );
};

CardExpanded.defaultProps = {
  centered: false,
};

export default CardExpanded;
