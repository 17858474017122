import React from "react";

import { BrushBorder, Text, Image, Container, ButtonAlt } from "@atoms";
import { AppLink } from "@defaults";

const OtherWaysGrid = ({ cards }) => {
  return (
    <div className="bg-black pb-16 pt-10">
      <BrushBorder color="black" position="top" />
      <div className="relative z-10">
        <Container>
          <ul className="-mt-16 grid grid-cols-1 gap-6 sm:-mt-20 sm:grid-cols-2 sm:gap-8 md:grid-cols-3 md:gap-10 lg:gap-12">
            {cards &&
              cards.map(a => (
                <li key={a.uid}>
                  <AppLink
                    to={a.url}
                    className="group relative z-0 -m-px flex h-full h-full flex-col items-center bg-white text-center text-black shadow-xl"
                  >
                    <div className="w-full">
                      <Image {...(a.image || a.metaImage)} aspectratio="4:3" />
                    </div>
                    <div className="w-full flex-grow border-2 border-gray-light p-3 transition duration-200 group-hover:border-orange">
                      <div className="my-2">
                        <Text variant="h7" className="text-orange">
                          {a.heading || a.title}
                        </Text>
                      </div>
                      <div>
                        <Text variant="body--small">
                          {(a.descriptor || a.metaDescription).length > 90
                            ? `${(a.descriptor || a.metaDescription).substring(
                                0,
                                90
                              )}...`
                            : a.descriptor || a.metaDescription}
                        </Text>
                      </div>
                      <div className="mb-3 mt-2">
                        <ButtonAlt size="sm" color="orange">
                          {a.cta}
                        </ButtonAlt>
                      </div>
                    </div>
                  </AppLink>
                </li>
              ))}
          </ul>
        </Container>
      </div>
      <BrushBorder color="black" position="bottom" />
    </div>
  );
};

OtherWaysGrid.defaultProps = {};

export default OtherWaysGrid;
