import React, { useRef, useState, useEffect } from "react";

import gsap from "gsap";
import { Text, Image, Icon, ButtonAlt, Container, Square } from "@atoms";

const PersonModal = ({ name, title, bio, image }) => {
  const modal = useRef();
  const tl = useRef();
  const [modalOpen, setModalOpen] = useState(false);
  const modalContent = useRef();

  // setup tl
  useEffect(() => {
    tl.current = gsap
      .timeline()
      .pause()
      .set(modal.current, { display: "none", pointerEvents: "none" })
      .set(modal.current, { display: "flex", pointerEvents: "auto" })
      .fromTo(modal.current, { opacity: 0 }, { opacity: 1, duration: 0.2 })
      .fromTo(
        modalContent.current.children,
        { opacity: 0, y: 40 },
        { opacity: 1, y: 0, duration: 0.2, stagger: 0.1 }
      );
    return () => {
      tl.current.kill();
    };
  }, []);

  // handle modal
  useEffect(() => {
    if (modalOpen) {
      tl.current.play();
    } else {
      tl.current.reverse();
    }
  }, [modalOpen]);

  return (
    <>
      {/* card */}
      <button
        type="button"
        onClick={() => setModalOpen(true)}
        className="flex w-full flex-col items-start justify-start text-left"
      >
        {image && (
          <div className="mx-auto mb-3 w-full">
            <Image {...image} params={{ crop: "faces" }} aspectratio="1:1" />
          </div>
        )}
        <Text
          variant="body--small-tight"
          className="font-medium uppercase text-red"
        >
          {title}
        </Text>
        <Text variant="body--xl-tight" className="my-2 font-medium">
          {name}
        </Text>
        <ButtonAlt noIcon>View Profile</ButtonAlt>
      </button>
      {/* modal */}
      <div
        ref={modal}
        className="bg-cream fixed inset-0 z-60 hidden overflow-y-scroll p-6 opacity-0 sm:p-12"
      >
        <div className="fixed right-0 top-0 p-3">
          <button
            type="button"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            <span className="sr-only">close</span>
            <Icon name="close" className="h-8 w-8 text-red" />
          </button>
        </div>
        <div className="my-auto w-full">
          <Container>
            <div
              ref={modalContent}
              className="flex w-full flex-wrap items-start justify-center"
            >
              <div className="order-2 -mt-px flex w-full flex-col justify-start px-3 lg:w-3/5">
                <Text variant="label" className="-mt-px">
                  {title}
                </Text>
                <Text variant="body--2xl-tight" className="my-2 font-medium">
                  {name}
                </Text>
                <Text variant="body--large-tight" className="max-w-md">
                  {bio}
                </Text>
              </div>
              <div className="order-1 flex w-full flex-col justify-end px-3 lg:w-2/5">
                {image && (
                  <div className="square mb-3 w-full max-w-xs">
                    <Square>
                      <Image
                        {...image}
                        params={{ crop: "faces" }}
                        aspectratio="1:1"
                      />
                    </Square>
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

PersonModal.defaultProps = {};

export default PersonModal;
