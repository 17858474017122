import React from "react";

import { Text, Image } from "@atoms";
import moment from "moment";

const CardExcerpt = ({ date, copy, heading, subheading, logo }) => {
  return (
    <div className="flex h-full flex-col bg-white p-3 shadow-xl lg:p-6">
      <div className="mb-2 h-16">
        <Image {...logo} />
      </div>
      <div className="border-red-light mb-3 mt-2 border-t pt-2 text-center text-sm text-gray">
        Posted: {moment(date).format("MMMM DD, YYYY")}
      </div>
      <div>
        <Text quotes variant="h5" className="font-medium">
          {heading}
        </Text>
      </div>
      <div>
        <Text variant="body--large-tight" className="font-medium text-red">
          {subheading}
        </Text>
      </div>
      <div>
        <Text variant="body--tight" className="rich-text">
          {copy}
        </Text>
      </div>
    </div>
  );
};

CardExcerpt.defaultProps = {};

export default CardExcerpt;
