import React from "react";

import { Twitch, Text, Container } from "@atoms";
import { Share } from "@molecules";

const Stream = ({ channel, heading, copy, shareHeading, shareCopy, path }) => {
  return (
    <div className="py-24">
      <Container>
        <div className="mb-12 bg-white shadow-xl">
          <Twitch channel={channel} />
        </div>
        <div className="-mx-3 -mx-6 flex">
          <div className="w-full flex-grow px-3 px-6">
            <Text variant="h3">{heading}</Text>
            <Text className="mt-3 font-serif leading-heading">{copy}</Text>
          </div>
          <div className="w-full px-3 px-6" style={{ flexBasis: "30rem" }}>
            <Text variant="h4" className="font-medium">
              {shareHeading}
            </Text>
            <Text className="mt-3 font-serif leading-heading">{shareCopy}</Text>
            <Share path={path} />
          </div>
        </div>
      </Container>
    </div>
  );
};

Stream.defaultProps = {};

export default Stream;
