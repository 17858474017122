import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview } from "@dataResolvers";

const query = graphql`
  query HeaderNavQueryMh {
    craft {
      globalSet(handle: "headerMarketingHub", site: "scmts") {
        ... on Craft_headerMarketingHub_GlobalSet {
          primaryNav {
            ... on Craft_primaryNav_link_BlockType {
              uid
              linkLink {
                url
                text
              }
              linkStyle
              sublinks {
                ... on Craft_sublinks_BlockType {
                  uid
                  sublink {
                    url
                    text
                  }
                  emphasize
                  descriptor
                }
              }
            }
          }
          secondaryNav {
            ... on Craft_secondaryNav_navLink_BlockType {
              uid
              enabled
              navLink {
                url
              }
              linkText
            }
          }
        }
      }
    }
  }
`;

const previewQuery = `
query HeaderNavQuery {
  globalSet(handle: "header", site: $site) {
    ... on header_GlobalSet {
      name
      navHeading
      navCopy
      navCampaigns {
        ... on navCampaigns_campaign_BlockType {
          enabled
          campaign {
            ... on issue_issue_Entry {
              enabled
              heading0
              slug
            }
          }
        }
      }
      secondaryNav {
        ... on secondaryNav_navLink_BlockType {
          enabled
          navLink
          linkText 
        }
      }
    }
  }
}
`;

const dataResolver = ({ craft }) => {
  const { secondaryNav, primaryNav } = craft.globalSet;
  return {
    primaryNav: primaryNav.map(item => {
      return {
        uid: item.uid,
        link: item.linkLink.url,
        text: item.linkLink.text,
        type: item.linkStyle,
        sublinks: item?.sublinks?.map(
          ({ sublink: { text, url }, uid, ...rest }) => ({
            ...rest,
            link: url,
            text,
            uid,
          })
        ),
      };
    }),
    secondaryNav: secondaryNav.map(link => {
      return {
        uid: link.uid,
        link: link.navLink?.url,
        text: link.linkText,
      };
    }),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
