import React from "react";

import { Image, Text } from "@atoms";

const Supporter = ({ image, name, subtitle }) => {
  return (
    <div className="p-3">
      <div className="-mx-6 flex flex-col flex-wrap items-center sm:flex-nowrap">
        <div className="w-full flex-shrink-0 px-6 sm:w-auto">
          {image && (
            <div className="mx-auto mb-4 h-48 w-48 overflow-hidden rounded-full md:h-56 md:w-56 lg:h-64 lg:w-64">
              <Image
                {...image}
                aspectratio="1:1"
                params={{ fit: "facearea", facepad: 10 }}
                round
              />
            </div>
          )}
          {name && (
            <Text
              variant="body--2xl-tight"
              className="mb-2 mt-4 text-center font-serif font-medium"
              quotes
            >
              {name}
            </Text>
          )}
          {subtitle && (
            <Text variant="body--large-tight" className="mt-2 text-center">
              {subtitle}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

Supporter.defaultProps = {};

export default Supporter;
