import React from "react";

import { ButtonAlt, Text, Image } from "@atoms";

const CardListSimple = ({ cards }) => {
  if (cards) {
    return (
      <ul>
        {cards.map(c => (
          <li key={c.uid} className="mb-6 flex max-w-md bg-white shadow-xl">
            {c.image && (
              <div className="relative w-48 flex-shrink-0 sm:w-52 md:w-64">
                <Image {...c.image} fill />
              </div>
            )}
            <div className="w-full p-6">
              <Text
                variant="body--small-tight"
                className="mb-3 font-medium uppercase text-red"
              >
                {c.label}
              </Text>
              <div className="flex flex-wrap lg:flex-nowrap">
                <div className="w-full max-w-xs">
                  <Text>{c.copy}</Text>
                </div>
                <div className="w-auto">
                  <ButtonAlt noIcon to={c.link}>
                    {c.linkText}
                  </ButtonAlt>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  }
  return null;
};

CardListSimple.defaultProps = {};

export default CardListSimple;
